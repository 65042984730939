import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
// import menu from './menu/reducers'
import settings from './settings/reducers'
import team from './team/reducers'
import administrators from './administrators/reducers'
import config from './config/reducer'
import company from './company/reducers'
import dismissedtable from './dismissedTable/reducer'
import absences from './absences/reducers'
import branchoffices from './branchoffices/reducers'
import hierarchy from './hierarchy/reducers'
import notification from './notifications/reducers'
import workshift from './workshift/reducer'
import dashboard from './dashboard/reducer'
import profile from './profile/reducers'
import reports from './reports/reducer'
import task from './task/taskReducer'
import inventory from './inventory/inventoryReducer'
import fileSystem from './fileSystem/reducers'

import { WorkdayReducer as workday } from './workday'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    // menu,
    settings,
    team,
    workday,
    administrators,
    config,
    company,
    absences,
    dismissedtable,
    branchoffices,
    hierarchy,
    notification,
    workshift,
    dashboard,
    profile,
    reports,
    task,
    inventory,
    fileSystem,
  })
