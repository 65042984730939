import Format from 'base/Format'
import moment from 'moment'
import React from 'react'
import style from './styles/Footer/style.module.scss'

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <img
          src="resources/images/iso-fichap-footer.svg"
          // src="resources/images/Footter-old-logo.svg" logo viejo
          className="mr-3"
          alt="Fichap"
        />
        <div className="footer-column">
          <Format id="footer.title" />
          <span className="d-flex">
            <span style={{ marginRight: 5 }}>{moment().format('YYYY')}</span>
            <Format id="footer.subtitle" />
          </span>
        </div>
      </div>
    </div>
  )
}
// © 2020
export default Footer
