/* eslint-disable require-yield */
import { takeEvery, put, call, spawn, select } from 'redux-saga/effects'
import store from 'store'
import { history } from 'index'
import * as Queries from 'services-fichap/login'
import { updateProfileImage } from 'services-fichap/team'
import { GET_COMPANY_DATA } from 'redux-fichap/company/types'
import { GET_BRANCH_OFFICES_TREE } from 'redux-fichap/branchoffices/types'
import NotificationGenerator from 'base/NotificationGenerator'
import apiConnection from 'api/api-connection'
import { intl } from '../../locales/IntlGlobalProvider'
import {
  LOAD_CURRENT_ACCOUNT,
  LOGIN,
  LOGOUT,
  RESTART_STATE,
  MANTEINANCE,
  UPDATE_PROFILE_IMAGE,
  GET_ROLES_BY_VIEWS,
} from './types'

/** ********************************** */

/**
 * Login User
 * @param {*} action
 */
function* restartIndexApiCall() {
  Queries.logout()
}

function* restartIndexSaga() {
  yield takeEvery(RESTART_STATE, restartIndexApiCall)
}

/**
 * Login User
 * @param {*} action
 */
function* logoutIndexApiCall() {
  yield put({ type: RESTART_STATE })
}

function* logoutIndexSaga() {
  yield takeEvery(LOGOUT, logoutIndexApiCall)
}
/** ********************************** */

/** ********************************** */

/**
 * Loginz User
 * @param {*} action
 */
function* loadCurrentAccountIndexApiCall() {
  try {
    const response = yield call(Queries.currentAccount)
    yield put({ type: LOAD_CURRENT_ACCOUNT.SUCCESS, payload: response.data })
  } catch (e) {
    console.error('ERROR:', e)
    yield put({ type: LOAD_CURRENT_ACCOUNT.FAILURE, payload: e })
  }
}

function* loadCurrentAccountIndexSaga() {
  yield takeEvery(LOAD_CURRENT_ACCOUNT.REQUEST, loadCurrentAccountIndexApiCall)
}
/** ********************************** */

/** ********************************** */

/**
 * Login User
 * @param {*} action
 */

function loginIndexApiCall(payload) {
  try {
    const response = apiConnection.post('/auth/v1/login', payload)
    // const response = yield call(Queries.login, action.payload) payload

    if (response) {
      store.set('allowedRoles', response.data.roles)
      const { accessToken } = response.data
      if (accessToken) {
        store.set('accessToken', accessToken)
        apiConnection.post('/companies/self').then(({ data }) => {
          store.set('COMPANY_DATA', data.data)
        })
        apiConnection.get('/users/self').then(({ data }) => {
          store.set('USER_DATA', data.data.data || data.data)
          // HOT FIX, TODO: SACAR! ESTO ES PARA QUE RECARGUE LOS ROLES DEL USER
          setTimeout(() => {
            window.location.href = '/'
          }, 1000)
        })
        apiConnection.post('/branchoffices/getFiltered').then(response => {
          store.set('BRANCH_OFFICES', response.data.data.branchoffices)
        })
        // history.push('/')

        return
      } else {
        NotificationGenerator(`loginErrorNoToken`, intl, true)
        throw new Error('No token received')
      }
    }
  } catch (e) {
    NotificationGenerator(`${e.response.data.errorMessage}`, intl, true)
  }
}

function* loginIndexSaga() {
  yield takeEvery(LOGIN.REQUEST, loginIndexApiCall)
}

function* manteinanceApiCall() {
  try {
    const response = yield call(Queries.manteinance)
    yield put({ type: MANTEINANCE.SUCCESS, payload: response })
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({ type: MANTEINANCE.FAILURE, payload: e })
  }
}

function* updateImageApiCall() {
  try {
    const response = yield call(updateProfileImage)
    yield put({ type: UPDATE_PROFILE_IMAGE.SUCCESS, payload: response })
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({ type: UPDATE_PROFILE_IMAGE.FAILURE, payload: e })
  }
}

function* manteinanceIndexSaga() {
  yield takeEvery(MANTEINANCE.REQUEST, manteinanceApiCall)
}

function* updateImageIndexSaga() {
  yield takeEvery(UPDATE_PROFILE_IMAGE.REQUEST, updateImageApiCall)
}

function* rolesViewIndexApiCall(action) {
  try {
    const response = yield call(Queries.rolesView, 'Dashboard')
    yield put({ type: GET_ROLES_BY_VIEWS.SUCCESS, payload: response })
  } catch (e) {
    yield put({ type: GET_ROLES_BY_VIEWS.FAILURE, payload: e })
  }
}

function* rolesViewIndexSaga() {
  yield takeEvery(GET_ROLES_BY_VIEWS.REQUEST, rolesViewIndexApiCall)
}

/** ********************************** */

function* UserIndexSaga() {
  yield spawn(loginIndexSaga)
  yield spawn(loadCurrentAccountIndexSaga)
  yield spawn(logoutIndexSaga)
  yield spawn(restartIndexSaga)
  yield spawn(manteinanceIndexSaga)
  yield spawn(updateImageIndexSaga)
  yield spawn(rolesViewIndexSaga)
}

export default UserIndexSaga
