/* eslint-disable */
import { createSelector } from 'reselect'

export const getUser = state => state.user.user
export const getRolesPermissions = state => state.user.rolesPermissions

export const getViewPermission = createSelector([getRolesPermissions], permissions => {
  return permissions
  /* ? [...new Set(permissions.map(p => p.views).flatMap(r => (r ? [...r] : [])))] */
  /*     ? [...new Set(permissions.map(p => p.resources.$__parent.views.Dashboard).flatMap(r => (r ? [...r] : [])))]
    : [] */
})

export const userLoadings = state => state.user.loading
export const userErrors = state => state.user.errors
export const userIsAuthorized = state => state.user.authorized
export const isInMaintenance = state => state.user.manteinance
