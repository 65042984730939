import api from 'api/api-connection'
import moment from 'moment'

// /reports/dashboard/absences
const getAbsences = ({ token }) =>
  api
    .get(`/reports/dashboard/absences?date=${moment().format('DD-MM-YYYY')}`, {
      cancelToken: token,
    })
    .then(response => response.data)
    .catch(err => {
      throw new Error(err)
    })

const getPrecenses = ({ token }) =>
  api
    .get(`/reports/dashboard/presences`, { cancelToken: token })
    .then(response => response.data)
    .catch(err => {
      throw new Error(err)
    })

// /reports/dashboard/lateArrivals
const getLateArrivals = ({ token }) =>
  api
    .get('/reports/dashboard/lateArrivals', { cancelToken: token })
    .then(response => response.data)
    .catch(err => {
      throw new Error(err)
    })

const getCalendar = ({ token }) => {
  return api
    .get('/reports/dashboard/calendar', { cancelToken: token })
    .then(response => response.data)
    .catch(err => {
      throw new Error(err)
    })
}

const getGraphic = ({ token }) =>
  api
    .post('/reports/graphics/workdays', { cancelToken: token })
    .then(response => response)
    .catch(err => {
      throw new Error(err)
    })

const getMap = ({ token }) =>
  api
    .get('/reports/dashboard/map', { cancelToken: token })
    .then(response => response.data)
    .catch(err => {
      throw new Error(err)
    })

const getDetailedReport = payload =>
  api
    .post('/reports/workdays/detailed', payload)
    .then(response => response.data)
    .catch(err => {
      throw new Error(err)
    })

const getGeneralReport = payload =>
  api
    .post('/reports/workdays/general', payload)
    .then(response => response.data.data)
    .catch(err => {
      throw new Error(err)
    })

const getPayrollReport = payload =>
  api
    .post('/users/getUserList', payload)
    .then(response => response.data.data)
    .catch(err => {
      throw new Error(err)
    })

export default {
  getAbsences,
  getPrecenses,
  getCalendar,
  getLateArrivals,
  getGraphic,
  getMap,
  getDetailedReport,
  getGeneralReport,
  getPayrollReport,
}
