import { takeEvery, put, call } from 'redux-saga/effects'
import api from 'services-fichap/reports/index'
import {
  GET_ABSENCES,
  GET_CALENDAR,
  GET_GRAPHIC,
  GET_LATE_ARRIVALS,
  GET_PRECENSES,
  GET_MAP,
} from './types'

function* getAbsencesApiCall(action) {
  try {
    const response = yield call(api.getAbsences, action.payload)
    yield put({ type: GET_ABSENCES.SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: GET_ABSENCES.FAILURE, payload: err })
  }
}

function* getCalendarApiCall(action) {
  try {
    const response = yield call(api.getCalendar, action.payload)
    yield put({ type: GET_CALENDAR.SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: GET_CALENDAR.FAILURE, payload: err })
  }
}

function* getGraphicApiCall(action) {
  try {
    const response = yield call(api.getGraphic, action.payload)
    yield put({ type: GET_GRAPHIC.SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: GET_GRAPHIC.FAILURE, payload: err })
  }
}

function* getLateArrivalsApiCall(action) {
  try {
    const response = yield call(api.getLateArrivals, action.payload)
    yield put({ type: GET_LATE_ARRIVALS.SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: GET_LATE_ARRIVALS.FAILURE, payload: err })
  }
}

function* getPrecensesApiCall(action) {
  try {
    const response = yield call(api.getPrecenses, action.payload)
    yield put({ type: GET_PRECENSES.SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: GET_PRECENSES.FAILURE, payload: err })
  }
}

function* getMapApiCall(action) {
  try {
    const response = yield call(api.getMap, action.payload)
    yield put({ type: GET_MAP.SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: GET_MAP.FAILURE, payload: err })
  }
}

function* dashboardIndexSaga() {
  yield takeEvery(GET_ABSENCES.REQUEST, getAbsencesApiCall)
  yield takeEvery(GET_CALENDAR.REQUEST, getCalendarApiCall)
  yield takeEvery(GET_GRAPHIC.REQUEST, getGraphicApiCall)
  yield takeEvery(GET_LATE_ARRIVALS.REQUEST, getLateArrivalsApiCall)
  yield takeEvery(GET_PRECENSES.REQUEST, getPrecensesApiCall)
  yield takeEvery(GET_MAP.REQUEST, getMapApiCall)
}

export default dashboardIndexSaga
