import { companyData } from 'redux-fichap/company/selectors'
import { getUserSelected } from 'redux-fichap/team/selectors'
import { takeEvery, put, spawn, call, select } from 'redux-saga/effects'
import NotificationGenerator from 'base/NotificationGenerator'
import { workdayApi, updateReports } from 'services-fichap/workday'
import {
  DELETE_WORKDAY_BY_EMPLOYEE_ID,
  GET_REPORTS_COMPANY,
  GET_WORKDAYS_BY_EMPLOYEE_ID,
  MODIFIE_WORKDAY,
  UPDATE_REPORT,
} from './types'
import { intl } from '../../locales/IntlGlobalProvider'

/**
 * GET WorkDays by employee Id
 * @param {*} action
 */
function* deleteWorkdayByEmployeeIdIndexApiCall(action) {
  try {
    const response = yield call(workdayApi.deleteWorkdaybyEmployeeId, action.payload)

    const userId = yield select(getUserSelected)
    yield put({ type: DELETE_WORKDAY_BY_EMPLOYEE_ID.SUCCESS })
    yield put({ type: GET_WORKDAYS_BY_EMPLOYEE_ID.REQUEST, payload: { userId } })
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({ type: DELETE_WORKDAY_BY_EMPLOYEE_ID.FAILURE, payload: e })
  }
}

function* deleteWorkdayByEmployeeIdIndexSaga() {
  yield takeEvery(DELETE_WORKDAY_BY_EMPLOYEE_ID.REQUEST, deleteWorkdayByEmployeeIdIndexApiCall)
}

/**
 * GET Reports by company Id
 * @param {*} action
 */
function* getRepotsByCompanyIdIndexApiCall() {
  try {
    const response = yield call(workdayApi.getReportsByCompanyId)
    yield put({ type: GET_REPORTS_COMPANY.SUCCESS, payload: response.data.reports })
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({ type: GET_REPORTS_COMPANY.FAILURE, payload: e })
  }
}

function* getRepotsByCompanyIdIndexSaga() {
  yield takeEvery(GET_REPORTS_COMPANY.REQUEST, getRepotsByCompanyIdIndexApiCall)
}
/**
 * Mpdified Workday
 * @param {*} action
 */
function* ModifiedWorkdayIndexApiCall(action) {
  try {
    const userId = yield select(getUserSelected)
    const response = yield call(workdayApi.modifiedWorkday, action.payload)
    yield put({ type: MODIFIE_WORKDAY.SUCCESS, payload: response.data })
    yield put({ type: GET_WORKDAYS_BY_EMPLOYEE_ID.REQUEST, payload: { userId } })
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({ type: MODIFIE_WORKDAY.FAILURE, payload: e })
  }
}

function* ModifiedWorkdayIdIndexSaga() {
  yield takeEvery(MODIFIE_WORKDAY.REQUEST, ModifiedWorkdayIndexApiCall)
}

/** ********************************** */

/**
 * update Reports by company Id
 * @param {*} action
 */
function* updateReportsIndexApiCall(action) {
  try {
    const company = yield select(companyData)
    const response = yield call(updateReports, action.payload)
    yield put({ type: UPDATE_REPORT.SUCCESS, payload: response })
    yield put({ type: GET_REPORTS_COMPANY.REQUEST, payload: company._id })
    NotificationGenerator('MarkUpdatedSuccessfully', intl, false)
  } catch (e) {
    console.error('ERROR: ', e)
    NotificationGenerator('AnErrorHasOcurred', intl, true)
    yield put({ type: UPDATE_REPORT.FAILURE, payload: e })
  }
}

function* updateReportsyIdIndexSaga() {
  yield takeEvery(UPDATE_REPORT.REQUEST, updateReportsIndexApiCall)
}

/** ********************************** */

/**
 * GET WorkDays by employee Id
 * @param {*} action
 */
function* getWorkdayByEmployeeIdIndexApiCall(action) {
  try {
    const response = yield call(workdayApi.getWorkdaybyEmployeeId, action.payload)

    yield put({ type: GET_WORKDAYS_BY_EMPLOYEE_ID.SUCCESS, payload: response })
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({ type: GET_WORKDAYS_BY_EMPLOYEE_ID.FAILURE, payload: e })
  }
}

function* getWorkdayByEmployeeIdIndexSaga() {
  yield takeEvery(GET_WORKDAYS_BY_EMPLOYEE_ID.REQUEST, getWorkdayByEmployeeIdIndexApiCall)
}
/** ********************************** */

function* WorkdayIndexSaga() {
  yield spawn(getWorkdayByEmployeeIdIndexSaga)
  yield spawn(deleteWorkdayByEmployeeIdIndexSaga)
  yield spawn(getRepotsByCompanyIdIndexSaga)
  yield spawn(updateReportsyIdIndexSaga)
  yield spawn(ModifiedWorkdayIdIndexSaga)
}

export default WorkdayIndexSaga
