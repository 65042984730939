import React, { useMemo, useEffect, useState } from 'react'
import { Button, Input, Table, Select } from 'antd'
import { useReFetch } from 'api/useFetch'
import download from 'assets/svg/downloadBlue.svg'
import { useHistory, withRouter } from 'react-router-dom'
import moment from 'moment'
import { getCurrentUser } from 'entities/user'
import { downloadColumns } from 'utils/downloadColumns'
import Header from '../../components/Header'
import { columns } from './columns'
import { months } from '../../components/months'
import './infoColabOT.scss'

const InfoColabOT = ({ match }) => {
  const history = useHistory()
  const [monthSelect, setMonthSelect] = useState(moment().month())
  const [filtered, setFiltered] = useState({
    from: moment().startOf('month'),
    to: moment().endOf('month'),
  })
  const [filter, setFilter] = useState(false)
  const [numberOfFilters, setNumberOfFilters] = useState()
  const [filteredValues, setFilteredValues] = useState([])
  const [selected, setSelected] = useState([])

  const handleChangeMonth = value => {
    setMonthSelect(value)
    const newFrom = moment()
      .month(value)
      .startOf('month')
    const newTo = moment()
      .month(value)
      .endOf('month')
    setFiltered({ from: newFrom, to: newTo })
  }

  const currentUser = getCurrentUser()

  const { data: userData } = useReFetch(
    {
      url: `/users/${match?.params?.id}`,
      method: 'get',
      default: [],
      reset: true,
    },
    [match],
  )
  const user = userData?.data

  const { data: orders, loading } = useReFetch(
    {
      url: `/workdays/workorders/paginated?companyId=${
        currentUser?.companyId
      }&withWorkEntries=true&fromDate=${filtered?.from?.format(
        'YYYY-MM-DD',
      )}&toDate=${filtered?.to?.format('YYYY-MM-DD')}&assignedWorkersCSV=${match?.params?.id}`,
      method: 'get',
      default: [],
      reset: true,
    },
    [filtered],
    // [monthSelect],
  )

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRows)
    },
  }

  const dataSourse = useMemo(() => {
    const map = {}
    const daysInMonth = moment()
      .month(monthSelect)
      .daysInMonth()

    // eslint-disable-next-line no-unused-expressions
    Array.isArray(orders?.data) &&
      orders.data.forEach(order => {
        const orderKey = order?.externalId

        if (!map[orderKey]) {
          map[orderKey] = { otTitle: orderKey, total: 0 }
          for (let index = 1; index <= daysInMonth; index++) {
            map[orderKey][index] = 0
          }
        }

        // eslint-disable-next-line no-unused-expressions
        order?.workEntries?.forEach(entry => {
          const entryDate = moment(entry.start) // RECORDAR DE USAR START, NO DATE.
          if (entryDate.month() === monthSelect) {
            const day = entryDate.date()
            map[orderKey][day] += entry.duration
            map[orderKey].total += entry.duration
          }
        })
      })

    return Object.values(map)
  }, [filter, monthSelect, orders])

  const monthColumns = useMemo(() => {
    return columns(monthSelect)
  }, [monthSelect])

  const onDownload = () => {
    downloadColumns(
      dataSourse,
      monthColumns,
      `OTs ${user?.personalData?.firstName} ${user?.personalData?.lastName} ${months[monthSelect]?.month}`,
      15,
    )
  }

  return (
    <div className="InfoColabOT">
      <Header user={user} HandleLink={() => history.push('../../../ots-reports')} />
      <div className="card cardContent">
        <div className="contentBtn">
          <div className="left">
            <Input
              className="btn-search"
              type="search"
              placeholder="Buscar por OT"
              // onChange={e => filterOts(e)}
            />
            <Select
              style={{ width: 180, marginLeft: 20 }}
              onChange={handleChangeMonth}
              value={monthSelect}
            >
              {months.map((mes, index) => (
                <Select.Option key={index} value={index}>
                  {mes.month}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="right">
            <Button onClick={onDownload} className="btn-download">
              <img src={download} alt="" />
            </Button>
          </div>
        </div>
        <Table
          scroll={{ x: 800 }}
          // tableLayout="auto"
          className="table__content"
          dataSource={dataSourse}
          columns={monthColumns}
          loading={loading}
          numberOfFilters={numberOfFilters}
          setNumberOfFilters={setNumberOfFilters}
          rowSelection={rowSelection}
          setFilteredValues={setFilteredValues}
        />
      </div>
    </div>
  )
}

export default withRouter(InfoColabOT)
