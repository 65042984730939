import React from 'react'
import { FormattedMessage } from 'react-intl'
import propTypes from 'prop-types'

/**
 * El Componente Format genera una estructura comun para
 * la devolucion de etiquetas de tipo p
 * Simplifica la utilizacion de <FormattedMessage/>
 * TODO: ampliar rango de etiquetas
 * @param {string} id id utilzado en el FormatedMessage
 */
const Format = ({ id, size, className }) => {
  switch (size) {
    case 24:
      return (
        <div className={`font-size-24 mb-4 ${className}`}>
          <FormattedMessage id={id} />
        </div>
      )

    case 'p':
      return (
        <p className="mb-0">
          <FormattedMessage id={id} />
        </p>
      )

    case 'span':
      return (
        <span className="d-none d-md-inline">
          <FormattedMessage id={id} />
        </span>
      )

    case 'h5':
      return (
        <h5 style={{ margin: 0, lineHeight: '60px', padding: 0 }}>
          <strong>
            <FormattedMessage id={id} />
          </strong>
        </h5>
      )

    default:
      return (
        <p className="mb-0">
          <FormattedMessage id={id} />
        </p>
      )
  }
}

Format.propTypes = {
  id: propTypes.string.isRequired,
}

export default Format
