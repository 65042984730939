import { RESTART_STATE } from 'redux-fichap/user/types'
import { parseActionType } from 'utils'
import {
  GET_HIERARCHIES,
  GET_HIERARCHIE_BY_ID,
  DELETE_HIERARCHY,
  CREATE_HIERARCHY,
  UPDATE_HIERARCHY,
} from './types'

const INITIAL_STATE = {
  error: null,
  loading: {},
  hierarchies: [],
}

export default (state = INITIAL_STATE, action) => {
  const actionType = parseActionType(action.type)

  switch (action.type) {
    case GET_HIERARCHIES.REQUEST:
    case GET_HIERARCHIE_BY_ID.REQUEST:
    case DELETE_HIERARCHY.REQUEST:
    case CREATE_HIERARCHY.REQUEST:
    case UPDATE_HIERARCHY.REQUEST:
      return { ...state, loading: { [actionType]: true } }

    case GET_HIERARCHIES.SUCCESS:
      return {
        ...state,
        loading: { [actionType]: false },
        hierarchies: action.payload.hierarchies,
        types: action.payload.types,
      }

    case GET_HIERARCHIE_BY_ID.SUCCESS:
      return {
        ...state,
        loading: { [actionType]: false },
        hierarchy: action.payload,
      }

    case DELETE_HIERARCHY.SUCCESS:
    case CREATE_HIERARCHY.SUCCESS:
    case UPDATE_HIERARCHY.SUCCESS:
      return { ...state, loading: { [actionType]: false } }

    case GET_HIERARCHIES.FAILURE:
    case GET_HIERARCHIE_BY_ID.FAILURE:
    case DELETE_HIERARCHY.FAILURE:
    case CREATE_HIERARCHY.FAILURE:
    case UPDATE_HIERARCHY.FAILURE:
      return { ...state, loading: { [actionType]: false }, error: action.payload || true }
    case RESTART_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
