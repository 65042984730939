import { takeEvery, put, call } from 'redux-saga/effects'
import api from 'services-fichap/config/index'
import NotificationGenerator from 'base/NotificationGenerator'
import { fetchCompanyData } from 'services-fichap/company/index'
import {
  GET_CUSTOM_DATA,
  UPDATE_COVID,
  UPDATE_FOLDER_BY_ID,
  UPDATE_GENERAL_CONFIG,
  UPDATE_NOTIFICATIONS,
  UPDATE_WORKDAY_BY_ID,
  DELETE_FOLDER_BY_ID,
  DELETE_WORKDAY_BY_ID,
  UPDATE_CUSTOM_DATA,
  CREATE_WORKDAY,
  CREATE_FOLDER,
  GET_CUSTOM_DATA_BY_NAME,
} from './types'
import { intl } from '../../locales/IntlGlobalProvider'

function* getCustomDataApiCall() {
  try {
    const response = yield call(fetchCompanyData)
    yield put({ type: GET_CUSTOM_DATA.SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: GET_CUSTOM_DATA.FAILURE, payload: err })
  }
}

function* getCustomFieldByNameApiCall(action) {
  try {
    const response = yield call(api.getCustomFieldByName, action.payload)
    yield put({ type: GET_CUSTOM_DATA_BY_NAME.SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: GET_CUSTOM_DATA_BY_NAME.FAILURE, payload: err })
  }
}

function* updateCovidApiCall(action) {
  try {
    const response = yield call(api.updateCovid, action.payload)
    yield put({ type: UPDATE_COVID.SUCCESS, payload: response })
  } catch (err) {
    console.error(err)
    yield put({ type: UPDATE_COVID.FAILURE, payload: err })
  }
}

function* updateFolderByIdApiCall(action) {
  try {
    yield call(api.updateFolderById, action.payload)
    yield put({ type: UPDATE_FOLDER_BY_ID.SUCCESS })
  } catch (err) {
    console.error(err)
    yield put({ type: UPDATE_FOLDER_BY_ID.FAILURE, payload: err })
  }
}

function* deleteFolderByIdApiCall(action) {
  try {
    yield call(api.deleteFolderById, action.payload)
    yield put({ type: DELETE_FOLDER_BY_ID.SUCCESS })
  } catch (err) {
    console.error(err)
    yield put({ type: DELETE_FOLDER_BY_ID.FAILURE, payload: err })
  }
}

function* updateGeneralConfigApiCall(action) {
  try {
    yield call(api.updateGeneralConfig, action.payload)
    yield put({ type: UPDATE_GENERAL_CONFIG.SUCCESS })
  } catch (err) {
    console.error('ERROR', err)
    yield put({ type: UPDATE_GENERAL_CONFIG.FAILURE, payload: err })
  }
}

function* updateNotificationsApiCall(action) {
  try {
    yield call(api.updateNotifications, action.payload)
    yield put({ type: UPDATE_NOTIFICATIONS.SUCCESS })
  } catch (err) {
    console.error(err)
    yield put({ type: UPDATE_NOTIFICATIONS.FAILURE, payload: err })
  }
}

function* updateWorkdayByIdApiCall(action) {
  try {
    yield call(api.updateWorkdayById, action.payload)
    yield put({ type: UPDATE_WORKDAY_BY_ID.SUCCESS })
  } catch (err) {
    console.error(err)
    yield put({ type: UPDATE_WORKDAY_BY_ID.FAILURE, payload: err })
  }
}

function* deleteWorkdayByIdApiCall(action) {
  try {
    yield call(api.deleteWorkdayById, action.payload)
    yield put({ type: DELETE_WORKDAY_BY_ID.SUCCESS })
  } catch (err) {
    console.error(err)
    yield put({ type: DELETE_WORKDAY_BY_ID.FAILURE, payload: err })
  }
}

function* updateCustomDataApiCall(action) {
  try {
    yield call(api.updateCustomFields, action.payload)
    yield put({ type: UPDATE_CUSTOM_DATA.SUCCESS })
  } catch (err) {
    console.error(err)
    yield put({ type: UPDATE_CUSTOM_DATA.FAILURE, payload: err })
  }
}

function* createWorkdayApiCall(action) {
  try {
    yield call(api.createWorkday, action.payload)
    yield put({ type: CREATE_WORKDAY.SUCCESS })
  } catch (err) {
    console.error(err)
    yield put({ type: CREATE_WORKDAY.FAILURE, payload: err })
  }
}

function* createFolderApiCall(action) {
  try {
    yield call(api.createFolder, action.payload)
    yield put({ type: CREATE_FOLDER.SUCCESS })
    NotificationGenerator('FolderCreated', intl, false)
  } catch (err) {
    console.error(err)
    yield put({ type: CREATE_FOLDER.FAILURE, payload: err })
  }
}

function* configIndexSaga() {
  // UPDATES
  yield takeEvery(UPDATE_COVID.REQUEST, updateCovidApiCall)
  yield takeEvery(UPDATE_FOLDER_BY_ID.REQUEST, updateFolderByIdApiCall)
  yield takeEvery(UPDATE_GENERAL_CONFIG.REQUEST, updateGeneralConfigApiCall)
  yield takeEvery(UPDATE_NOTIFICATIONS.REQUEST, updateNotificationsApiCall)
  yield takeEvery(UPDATE_WORKDAY_BY_ID.REQUEST, updateWorkdayByIdApiCall)
  yield takeEvery(UPDATE_CUSTOM_DATA.REQUEST, updateCustomDataApiCall)
  // DELETES
  yield takeEvery(DELETE_FOLDER_BY_ID.REQUEST, deleteFolderByIdApiCall)
  yield takeEvery(DELETE_WORKDAY_BY_ID.REQUEST, deleteWorkdayByIdApiCall)
  yield takeEvery(GET_CUSTOM_DATA.REQUEST, getCustomDataApiCall)
  yield takeEvery(GET_CUSTOM_DATA_BY_NAME.REQUEST, getCustomFieldByNameApiCall)
  yield takeEvery(CREATE_WORKDAY.REQUEST, createWorkdayApiCall)
  yield takeEvery(CREATE_FOLDER.REQUEST, createFolderApiCall)
}

export default configIndexSaga
