import { getCurrentUser } from 'entities/user'
import { COMPANIES } from 'entities/company'
import { history } from 'index'

export const redirectLogin = (redirect = true) => {
  try {
    const user = getCurrentUser()
    // Quick fix, esto redirige a un login custom
    // const hash = window.location.hash.replace('#', '')
    // if (hash != '/auth/login' && hash != '/auth/login/4people') localStorage.setItem('path', hash)

    if (user?.companyId === COMPANIES.SPORTLINE) {
      history.push('/auth/login/4people')
    } else {
      if (redirect) history.push('/auth/login')
    }
  } catch (error) {
    if (redirect) history.push('/auth/login')
  }
}
