import api from 'api/api-connection'
import NotificationGenerator from 'base/NotificationGenerator'
import cleanDeep from 'clean-deep'
import { intl } from '../../locales/IntlGlobalProvider'

const getWorkshift = payload => {
  return api
    .post('/workshift/shiftTemplate/getFiltered', payload)
    .then(response => response.data.data)
    .catch(e => {
      throw new Error(e)
    })
}

const createWorkshift = workshift =>
  api
    .post('/workshift/shiftTemplate', workshift)
    .then(response => response)
    .catch(e => {
      throw new Error(e)
    })

const updateWorkshift = workshift =>
  api
    .patch(`/workshift/shiftTemplate/${workshift._id}`, workshift)
    .then(response => response.data.data)
    .catch(e => {
      throw new Error(e)
    })

const deleteWorkshift = id =>
  api
    .delete(`/workshift/shiftTemplate/${id}`)
    .then(() => {})
    .catch(e => {
      throw new Error(e)
    })

const createEvent = event =>
  api
    .post('/workshift/shift', event)
    .then(response => response)
    .catch(e => {
      NotificationGenerator(`${e.response.data.message}`, intl, true)
      throw new Error(e)
    })

const updateEvent = (event, type) => {
  if (event.id) {
    return api.patch(`/workshift/shift/${event.id}`, event)
  } else {
    return api.patch(`/workshift/shift/VIRTUAL`, event)
  }
  // }
}
const deleteEvent = id => api.delete(`/workshift/shift/${id}`)

export default {
  getWorkshift,
  createWorkshift,
  updateWorkshift,
  deleteWorkshift,
  createEvent,
  updateEvent,
  deleteEvent,
}
