/* eslint-disable react/display-name */
/* eslint-disable no-unused-expressions */
import React from 'react'
import { Input, DatePicker, Form } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { translate } from 'locales/IntlGlobalProvider'
import moment from 'moment'
import keyAdapter from './keyAdapter'

const { RangePicker } = DatePicker

function roundToTwo(num) {
  const val = +(Math.round(num + 'e+2') + 'e-2')
  if (Number.isNaN(val)) return 0
  return val || 0
}

const parseColumn = ({
  title,
  key,
  default: _default,
  dataIndex,
  width = 150,
  render,
  sort: _sort,
  onFilter,
  arrayKey,
  type: _type,
  ...record
}) => {
  let parsedRender
  const type = _type || _sort
  let typeParsed

  if (_default !== undefined) {
    //
  }
  if (typeof render === 'function') {
    parsedRender = (_, item) => {
      return render(keyAdapter(item, key, _default), item)
    }
  } else if (type === 'number' && render !== 'function') {
    parsedRender = (_, item) => {
      return roundToTwo(keyAdapter(item, key, _default))
    }
  } else if (type === 'date' && typeof render !== 'function') {
    parsedRender = (_, item) =>
      keyAdapter(item, key, undefined)
        ? moment(keyAdapter(item, key, _default)).format('DD-MM-YYYY')
        : keyAdapter(item, key, undefined)
  } else if (type === 'date' && typeof render === 'function') {
    parsedRender = (_, item) => {
      return render(keyAdapter(item, key, undefined))
    }
  } else if (type === 'array') {
    parsedRender = (_, item) => {
      return `${keyAdapter(item, key, _default)
        ?.map(el => {
          if (arrayKey) return keyAdapter(el, arrayKey, '')
          return el
        })
        .join(', ') || ''}`
    }
  } else {
    parsedRender = (_, item) => {
      return keyAdapter(item, key, _default)
    }
  }

  if (type && _sort !== false) {
    if (type === 'string') {
      typeParsed = (a, b) => parsedRender('', a)?.localeCompare(parsedRender('', b))
    }
    if (type === 'number') {
      typeParsed = (a, b) => parsedRender('', a) - parsedRender('', b)
    }
    if (type === 'date') {
      typeParsed = (a, b) => new Date(parsedRender('', b)) - new Date(parsedRender('', a))
    }
  }

  return {
    ...record,
    sort: _sort,
    title: translate(title) || '  ',
    stringTitle: title,
    dataIndex: dataIndex || key,
    key,
    width,
    sorter: typeParsed,
    render: parsedRender,
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    filterDropdown: onFilter
      ? () => (
          <div style={{ padding: 8 }}>
            {(type === 'string' || type === 'array' || !type) && (
              <Form.Item name="string" noStyle>
                <Input
                  onPressEnter={e => {
                    onFilter({ key, value: e.target.value, type, getValue: parsedRender })
                  }}
                  style={{ width: 188, display: 'block' }}
                />
              </Form.Item>
            )}
            {type === 'date' && (
              <Form.Item name="date" noStyle>
                <RangePicker
                  onChange={values =>
                    onFilter({ key, value: values, type, getValue: parsedRender })
                  }
                />
              </Form.Item>
            )}
          </div>
        )
      : null,
  }
}

export default parseColumn
