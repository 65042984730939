import { takeEvery, put, call } from 'redux-saga/effects'
import api from 'services-fichap/profile'
import NotificationGenerator from 'base/NotificationGenerator'
import { intl } from '../../locales/IntlGlobalProvider'
import {
  GET_PROFILE_DATA,
  UPDATE_PROFILE_DATA,
  UPDATE_PROFILE_DATA_FINISHED,
  CLEAR_ERROR,
  CHANGE_PASSWORD,
} from './types'

function* getProfileApiCall() {
  try {
    const response = yield call(api.getProfile)
    yield put({ type: GET_PROFILE_DATA.SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: GET_PROFILE_DATA.FAILURE, payload: err })
  }
}

function* updateProfileApiCall(action) {
  try {
    yield call(api.updateProfile, action.payload)
    yield put({ type: UPDATE_PROFILE_DATA.SUCCESS })
    yield put({ type: UPDATE_PROFILE_DATA_FINISHED.SUCCESS })
    NotificationGenerator('employeeUpdateSuccess', intl, false)
  } catch (err) {
    yield put({ type: UPDATE_PROFILE_DATA.FAILURE, payload: err })
    NotificationGenerator('employeeUpdateFail', intl, true)
  }
}

function* clearError(action) {
  try {
    yield put({ type: CLEAR_ERROR.SUCCESS, payload: action.payload })
  } catch (error) {
    yield put({ type: CLEAR_ERROR.FAILURE, payload: error })
  }
}

function* changePassword(action) {
  try {
    const response = yield call(api.changePassword, action.payload)
    yield put({ type: CHANGE_PASSWORD.SUCCESS, payload: response })
    NotificationGenerator('PasswordUpdated', intl, false)
  } catch (error) {
    yield put({ type: CHANGE_PASSWORD.FAILURE, payload: error })
    NotificationGenerator('employeeUpdateFail', intl, true)
  }
}

function* profileIndexSaga() {
  yield takeEvery(GET_PROFILE_DATA.REQUEST, getProfileApiCall)
  yield takeEvery(UPDATE_PROFILE_DATA.REQUEST, updateProfileApiCall)
  yield takeEvery(CLEAR_ERROR.REQUEST, clearError)
  yield takeEvery(CHANGE_PASSWORD.REQUEST, changePassword)
}

export default profileIndexSaga
