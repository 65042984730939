import api from 'api/api-connection'

const updateCovid = data => {
  api
    .patch('/companies/config/covid', data)
    .then(() => 'ok')
    .catch(err => {
      console.error(`ERROR: ${err}`)
      throw new Error(err)
    })
}

const createFolder = folder =>
  api
    .post('/companies/typesOfFolder', folder)
    .then(({ response }) => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const updateFolderById = folder =>
  api
    .patch(`/companies/typesOfFolder/${folder._id}`, {
      title: folder.title,
      description: folder.description,
      color: folder.color,
    })
    .then(({ response }) => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const deleteFolderById = id =>
  api
    .delete(`/companies/typesOfFolder/${id}`)
    .then(({ response }) => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const updateGeneralConfig = data =>
  api
    .patch('/companies/config/general', data)
    .then(() => 'ok')
    .catch(err => {
      console.error(`ERROR: ${err}`)
      throw new Error(err)
    })

const updateCustomFields = data =>
  api
    .patch('/companies/customFields', data)
    .then(() => 'ok')
    .catch(err => {
      console.error(`ERROR: ${err}`)
      throw new Error(err)
    })

const getCustomFieldByName = customField =>
  api
    .post('/companies/customFields/getCustomFields', customField)
    .then(response => response.data)
    .catch(err => {
      console.error(`ERROR: ${err}`)
      throw new Error(err)
    })

const updateNotifications = data =>
  api
    .patch('/companies/config/notifications', data)
    .then(() => 'ok')
    .catch(err => {
      console.error(`ERROR: ${err}`)
      throw new Error(err)
    })

/* eslint-disable */
const updateWorkdayById = workday =>
  api
    .patch(`/companies/typesOfWorkshift/${workday._id}`, {
      title: workday.title,
      description: workday.description,
      color: workday.color,
      externalId: workday.externalId,
    })
    .then(() => 'updated')
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const deleteWorkdayById = id =>
  api
    .delete(`/companies/typesOfWorkshift/${id}`)
    .then(() => 'ok')
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const createWorkday = workday =>
  api
    .post('/companies/typesOfWorkshift', workday)
    .then(() => 'created')
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

/* eslint-enable */
export default {
  createFolder,
  updateFolderById,
  deleteFolderById,
  updateNotifications,
  updateGeneralConfig,
  updateWorkdayById,
  deleteWorkdayById,
  updateCovid,
  updateCustomFields,
  createWorkday,
  getCustomFieldByName,
}
