import api from 'services-fichap/notifications/index'
import { takeEvery, put, call } from 'redux-saga/effects'
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_BY_ID,
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from './types'

function* getNotificationsApiCall() {
  try {
    const response = yield call(api.getNotifications)
    yield put({ type: GET_NOTIFICATIONS.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_NOTIFICATIONS.FAILURE, payload: error })
  }
}

function* getNotificationByIdApiCall() {
  try {
    const response = yield call(api.getNotificationById)
    yield put({ type: GET_NOTIFICATION_BY_ID.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_NOTIFICATION_BY_ID.FAILURE, payload: error })
  }
}

function* updateNotificationApiCall(action) {
  try {
    const response = yield call(api.updateNotification, action.payload)
    yield put({ type: UPDATE_NOTIFICATION.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: UPDATE_NOTIFICATION.FAILURE, payload: error })
  }
}

function* createNotificationApiCall(action) {
  try {
    const response = yield call(api.createNotification, action.payload)
    yield put({ type: CREATE_NOTIFICATION.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: CREATE_NOTIFICATION.FAILURE, payload: error })
  }
}

function* deleteNotificationApiCall(action) {
  try {
    const response = yield call(api.deleteNotification, action.payload)
    yield put({ type: DELETE_NOTIFICATION.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: DELETE_NOTIFICATION.FAILURE, payload: error })
  }
}

function* IndexSaga() {
  yield takeEvery(GET_NOTIFICATIONS.REQUEST, getNotificationsApiCall)
  yield takeEvery(GET_NOTIFICATION_BY_ID.REQUEST, getNotificationByIdApiCall)
  yield takeEvery(UPDATE_NOTIFICATION.REQUEST, updateNotificationApiCall)
  yield takeEvery(CREATE_NOTIFICATION.REQUEST, createNotificationApiCall)
  yield takeEvery(DELETE_NOTIFICATION.REQUEST, deleteNotificationApiCall)
}

export default IndexSaga
