import { history } from 'index'
import { takeEvery, put, call } from 'redux-saga/effects'
import NotificationGenerator from 'base/NotificationGenerator'
import {
  fetchCompanyData,
  updateCompany,
  fetchSpecialDays,
  deleteSpecialDays,
  createSpecialDays,
  updateSpecialDays,
} from 'services-fichap/company'
import {
  GET_COMPANY_DATA,
  GET_COMPANY_PAYMENTS,
  GET_COMPANY_PAYMENT_BY_ID,
  GET_COMPANY_PLAN,
  GET_SPECIAL_DAYS,
  UPDATE_COMPANY_DATA,
  UPDATE_SPECIAL_DAYS,
  DELETE_SPECIAL_DAYS,
  CREATE_SPECIAL_DAYS,
} from './types'
import { intl } from '../../locales/IntlGlobalProvider'

function* getCompanyDataApiCall() {
  try {
    const response = yield call(fetchCompanyData)
    yield put({ type: GET_COMPANY_DATA.SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: GET_COMPANY_DATA.FAILURE, payload: err })
  }
}

function* getCompanyPaymentsApiCall() {
  try {
    const response = yield call(fetchCompanyData)
    yield put({ type: GET_COMPANY_PAYMENTS.SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: GET_COMPANY_PAYMENTS.FAILURE, payload: err })
  }
}

function* getCompanyPaymentByIdApiCall(action) {
  try {
    const response = yield call(fetchCompanyData, action.payload)
    yield put({ type: GET_COMPANY_PAYMENT_BY_ID.SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: GET_COMPANY_PAYMENT_BY_ID.FAILURE, payload: err })
  }
}

function* getCompanyPlanApiCall() {
  try {
    const response = yield call(fetchCompanyData)
    yield put({ type: GET_COMPANY_PLAN.SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: GET_COMPANY_PLAN.FAILURE, payload: err })
  }
}

function* getSpecialDaysApiCall() {
  try {
    const response = yield call(fetchSpecialDays)
    yield put({ type: GET_SPECIAL_DAYS.SUCCESS, payload: response })
  } catch (err) {
    yield put({ type: GET_SPECIAL_DAYS.FAILURE, payload: err })
  }
}

function* updateCompanyDataApiCall(action) {
  try {
    yield call(updateCompany, action.payload)
    history.push('/company')
    yield put({ type: UPDATE_COMPANY_DATA.SUCCESS })
    yield put({ type: GET_COMPANY_DATA })
  } catch (err) {
    yield put({ type: UPDATE_COMPANY_DATA.FAILURE, payload: err })
  }
}

function* updateSpecialDaysApiCall(action) {
  try {
    yield call(updateSpecialDays, action.payload)
    yield put({ type: UPDATE_SPECIAL_DAYS.SUCCESS })
    NotificationGenerator('SpecialDayUpdated', intl, false)
    // getCompanyDataApiCall()
  } catch (err) {
    yield put({ type: UPDATE_SPECIAL_DAYS.FAILURE, payload: err })
  }
}

function* deleteSpecialDaysApiCall(action) {
  try {
    yield call(deleteSpecialDays, action.payload)
    yield put({ type: DELETE_SPECIAL_DAYS.SUCCESS })
    NotificationGenerator('SpecialDayDeleted', intl, false)
  } catch (err) {
    yield put({ type: DELETE_SPECIAL_DAYS.FAILURE, payload: err })
  }
}

function* createSpecialDaysApiCall(action) {
  try {
    yield call(createSpecialDays, action.payload)
    yield put({ type: CREATE_SPECIAL_DAYS.SUCCESS })
    NotificationGenerator('SpecialDayCreated', intl, false)
  } catch (err) {
    yield put({ type: CREATE_SPECIAL_DAYS.FAILURE, payload: err })
    NotificationGenerator('SpecialDayaErrorCreate', intl, true)
  }
}

function* companyIndexSaga() {
  yield takeEvery(GET_COMPANY_DATA.REQUEST, getCompanyDataApiCall)
  yield takeEvery(GET_COMPANY_PAYMENTS.REQUEST, getCompanyPaymentsApiCall)
  yield takeEvery(GET_COMPANY_PAYMENT_BY_ID.REQUEST, getCompanyPaymentByIdApiCall)
  yield takeEvery(GET_COMPANY_PLAN.REQUEST, getCompanyPlanApiCall)
  yield takeEvery(GET_SPECIAL_DAYS.REQUEST, getSpecialDaysApiCall)
  yield takeEvery(UPDATE_COMPANY_DATA.REQUEST, updateCompanyDataApiCall)
  yield takeEvery(UPDATE_SPECIAL_DAYS.REQUEST, updateSpecialDaysApiCall)
  yield takeEvery(DELETE_SPECIAL_DAYS.REQUEST, deleteSpecialDaysApiCall)
  yield takeEvery(CREATE_SPECIAL_DAYS.REQUEST, createSpecialDaysApiCall)
}

export default companyIndexSaga
