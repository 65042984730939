import api from 'api/api-connection'
import store from 'store'

const getHierarchies = () =>
  api
    .get('/companies/hierarchies/HierarchiesToTable/get')
    .then(response => response.data.data)
    .catch(err => {
      throw new Error(err)
    })

const getHierarchieById = id =>
  api
    .get(`/companies/hierarchies/${id}`)
    .then(response => response.data.data)
    .catch(err => {
      throw new Error(err)
    })

const createHierarchy = hierarchy =>
  api
    .post('/companies/hierarchies', hierarchy)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw new Error(err)
    })

const updateHierarchy = hierarchy =>
  api
    .patch(`/companies/hierarchies/${hierarchy._id}`, hierarchy)
    .then(response => response.data)
    .catch(err => {
      throw new Error(err)
    })

const deleteHierarchy = id =>
  api
    .delete(`/companies/hierarchies/${id}`)
    .then(response => response.data)
    .catch(err => {
      throw new Error(err)
    })

const getTypes = () =>
  new Promise((resolve, reject) => {
    const company = store.get('COMPANY_DATA', null)
    if (company) {
      resolve(company?.customFields?.hierarchies)
    } else {
      api
        .get('/companies/byAuthUserCompany')
        .then(response => {
          store.set('COMPANY_DATA', response.data?.data)
          resolve(response.data?.data?.customFields?.hierarchies)
        })
        .catch(err => reject(new Error(err)))
    }
  })

export default {
  getHierarchies,
  getHierarchieById,
  createHierarchy,
  deleteHierarchy,
  updateHierarchy,
  getTypes,
}
