import { GET_DISMISSED_TABLE, DELETE_EMPLOYEE } from './types'

export const getDismissedTable = dispatch =>
  dispatch({
    type: GET_DISMISSED_TABLE.REQUEST,
  })

export const deleteEmployeeAction = dispatch =>
  dispatch({
    type: DELETE_EMPLOYEE.REQUEST,
  })
