/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import store from 'store'

const PortalButton = () => {
  const moveToPortal = () => {
    const accessToken = store.get('accessToken')
    window.open(`https://portal.fichap.com/auth/login?token=${accessToken}`, '_blank').focus()
  }
  return (
    <a
      style={{
        display: 'flex',
      }}
      className="PortalButton NavbarButton"
      onClick={moveToPortal}
    >
      <img style={{ marginRight: 9, height: 19 }} src="svg/portal.svg" alt="" />
      <span style={{ color: '#695EE8' }}> Portal del colaborador </span>
    </a>
  )
}

export default PortalButton
