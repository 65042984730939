import { del, download, get, post, uploadFile, patch } from 'api/api-connection'
import naturalResources from 'entities/naturalResources'

const base = `documents/fileSystem`

const fileSystemApi = {
  getMedia: payload => {
    naturalResources.print(1)
    return download('get', `${base}/media/${payload.id}`)
  },
  getFolder: payload =>
    get(`${base}/folder/${payload.companyId}/${payload.userId}/${payload.folderId}`),
  getFileSystem: payload =>
    get(
      `${base}/folders/${payload.companyId}${payload.folderId ? `/${payload.folderId}` : ''}/${
        payload.userId
      } `,
    ),
  copy: payload => post(`${base}/copy`, {}, payload),
  move: payload => post(`${base}/move`, {}, payload),
  delete: payload => del(`${base}/delete/${payload.id}`, {}, payload),
  upload: (payload, config) => uploadFile('post', `${base}/upload`, payload, config),
  createFolder: payload => post(`${base}/createFolder`, {}, payload),
  updateFolder: payload => patch(`${base}/updateFolder`, {}, payload),
  updateFile: payload => patch(`${base}/updateFile`, {}, payload),
}

export default fileSystemApi
