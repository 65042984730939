export const objectKeyExists = (object, key1, key2, key3, key4, key5, key6, key7) => {
  if (!object) return false
  if (key1 && !object[key1]) return false
  if (key1 && key2 && !object[key1][key2]) return false
  if (key1 && key2 && key3 && !object[key1][key2][key3]) return false
  if (key1 && key2 && key3 && key4 && !object[key1][key2][key3][key4]) return false
  if (key1 && key2 && key3 && key4 && key5 && !object[key1][key2][key3][key4][key5]) return false
  if (key1 && key2 && key3 && key4 && key5 && key6 && !object[key1][key2][key3][key4][key5][key6])
    return false
  if (
    key1 &&
    key2 &&
    key3 &&
    key4 &&
    key5 &&
    key6 &&
    key7 &&
    !object[key1][key2][key3][key4][key5][key6][key7]
  )
    return false
  return true
}

// export const objectKeyExists = (object, ...keys) => {
//   let current = object
//   for (let key of keys) {
//     if (key && !current[key]) return false
//     current = current[key]
//   }
//   return true
// }

const keyAdapter = (object, key, _default = ' ') => {
  if (key?.includes('.')) {
    const [key1, key2, key3, key4, key5, key6, key7] = key.split('.')
    if (key1 && key2 && key3 && key4 && key5 && key6 && key7) {
      if (objectKeyExists(object, key1, key2, key3, key4, key5, key6))
        return object[key1][key2][key3][key4][key5][key6][key7]
      else return _default
    }
    if (key1 && key2 && key3 && key4 && key5 && key6) {
      if (objectKeyExists(object, key1, key2, key3, key4, key5))
        return object[key1][key2][key3][key4][key5][key6]
      else return _default
    }
    if (key1 && key2 && key3 && key4 && key5) {
      if (objectKeyExists(object, key1, key2, key3, key4))
        return object[key1][key2][key3][key4][key5]
      else return _default
    }
    if (key1 && key2 && key3 && key4) {
      if (objectKeyExists(object, key1, key2, key3)) return object[key1][key2][key3][key4]
      else return _default
    }
    if (key1 && key2 && key3) {
      if (objectKeyExists(object, key1, key2)) return object[key1][key2][key3]
      else return _default
    }
    if (key1 && key2) {
      if (objectKeyExists(object, key1)) return object[key1][key2]
      else return _default
    }
  }
  if (object) return object[key]
  return _default
}

// const keyAdapter = (object, key, _default = ' ') => {
//   if (!object || typeof key !== 'string') return _default

//   const keys = key.split('.')
//   let current = object

//   for (const key of keys) {
//     if (key in current) {
//       current = current[key]
//     } else {
//       return _default
//     }
//   }

//   return current
// }

export default keyAdapter
