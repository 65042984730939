import { createSelector } from 'reselect'

export const getUserSelected = state => state.team.employeeProfileId
export const getEmployees = state => state.team.employeeProfileData
export const getEmployee = state => state.team.employee
export const loadingTeam = state => state.team.loading
export const getCount = state => state.team.count
export const getTeamError = state => state.team.error
export const getWorkshifts = state => state.workshifts
export const getRestartStatus = state => state
export const getErrorCode = state => state.team.errorCode

export const getEmployeeSelector = createSelector(
  [getUserSelected, getEmployees],
  (selected, items) => {
    return items.find(i => i._id === selected)
  },
)
