import { RESTART_STATE } from 'redux-fichap/user/types'
import { parseActionType } from 'utils'
import {
  GET_DETAILED_REPORT,
  GET_GENERAL_REPORT,
  GET_PAYROLL_REPORT,
  CLEAN_PAYROLL_REPORT,
} from './types'

const INITIAL_STATE = {
  error: null,
  loading: {},
  payrollReport: [],
}

export default (state = INITIAL_STATE, action) => {
  const actionType = parseActionType(action.type)

  switch (action.type) {
    case GET_DETAILED_REPORT.REQUEST:
    case GET_GENERAL_REPORT.REQUEST:
    case GET_PAYROLL_REPORT.REQUEST:
    case CLEAN_PAYROLL_REPORT.REQUEST:
      return { ...state, loading: { [actionType]: true } }

    case GET_DETAILED_REPORT.SUCCESS:
      return { ...state, loading: { [actionType]: false }, detailedReport: action.payload }

    case GET_GENERAL_REPORT.SUCCESS:
      return { ...state, loading: { [actionType]: false }, generalReport: action.payload }

    case GET_PAYROLL_REPORT.SUCCESS:
      return { ...state, loading: { [actionType]: false }, payrollReport: action.payload }

    case CLEAN_PAYROLL_REPORT.SUCCESS:
      return { ...state, loading: { [actionType]: false }, payrollReport: [] }

    case GET_DETAILED_REPORT.FAILURE:
    case GET_GENERAL_REPORT.FAILURE:
    case GET_PAYROLL_REPORT.FAILURE:
    case CLEAN_PAYROLL_REPORT.FAILURE:
      return { ...state, loading: { [actionType]: false }, error: action.payload }

    case RESTART_STATE:
      return state

    default:
      return state
  }
}
