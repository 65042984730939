/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'

const ShowErrors = ({ errors, message }) => {
  const [show, setShow] = useState(false)
  return (
    <div className="ShowErrors">
      {message}
      {!show ? (
        <a style={{ display: 'block', color: '#695ee8' }} onClick={() => setShow(true)}>
          {' '}
          Ver
        </a>
      ) : (
        <div className="scrollable" style={{ maxHeight: 400, overflowY: 'auto', marginTop: 12 }}>
          {' '}
          {errors.map((el, i) => (
            <p className="mt-3" key={i}>
              {' '}
              {el}{' '}
            </p>
          ))}{' '}
        </div>
      )}
    </div>
  )
}

export default ShowErrors
