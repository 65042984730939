import { createRequestTypes } from '../../utils'

const namespace = 'team'

export const GET_EMPLOYEES_BY_COMPANY_ID = createRequestTypes(
  `${namespace}/GET_EMPLOYEES_BY_COMPANY_ID`,
)
export const CREATE_EMPLOYEE = createRequestTypes(`${namespace}/CREATE_EMPLOYEE`)
export const ENABLED_DISABLED_EMPLOYEE = createRequestTypes(
  `${namespace}/ENABLED_DISABLED_EMPLOYEE`,
)
export const UPDATE_EMPLOYEE = createRequestTypes(`${namespace}/UPDATE_EMPLOYEE`)
export const GET_EMPLOYEE_BY_ID = createRequestTypes(`${namespace}/GET_EMPLOYEE_BY_ID`)
export const SELECTED_EMPLOYEE = `${namespace}/SELECTED_EMPLOYEE`

export const GET_CALENDAR_BY_ID = createRequestTypes(`${namespace}/GET_CALENDAR_BY_ID`)
export const PUSH_CALENDAR_UPDATE = createRequestTypes(`${namespace}/PUSH_CALENDAR_UPDATE`)

export const UPDATE_CALENDAR = createRequestTypes(`${namespace}/UPDATE_CALENDAR`)

export const CLEAR_ERROR_CODE = createRequestTypes(`${namespace}/CLEAR_ERROR_CODE`)
