import { translate } from 'locales/IntlGlobalProvider'
import excel from './excel'
import NotificationGenerator from '../base/NotificationGenerator'
import keyAdapter from './keyAdapter'
import parseColumn from './column'

export const downloadColumns = (data, columns, title = '', width, includeDate) => {
  if (!data?.length) return NotificationGenerator('No hay datos', undefined, true)
  const value = data.map(el => {
    const obj = {}
    columns.forEach(column => {
      if (column) {
        const col = parseColumn(column)
        if (column.download === undefined || column.download) {
          if (typeof column.download === 'function') {
            obj[translate(col.title)] = col.download(keyAdapter(el, col.key), el)
          } else {
            obj[translate(col.title)] = col.render(keyAdapter(el, col.key), el)
          }
        }
      }
    })
    return obj
  })

  // "includeDate" quita la fecha del titulo del archivo
  excel(value, title, width || 50, includeDate)
}

export default downloadColumns
