/* eslint-disable no-case-declarations */
import _ from 'lodash'
import { RESTART_STATE } from 'redux-fichap/user/types'
import { parseActionType } from 'utils'
import {
  GET_MEDIA,
  GET_FOLDER,
  GET_FILESYSTEM,
  COPY,
  MOVE,
  DELETE,
  UPLOAD,
  CREATE_FOLDER,
  UPDATE_FOLDER,
  SELECT_FOLDER,
  UPDATE_FILE,
  CLEAR,
} from './types'
// import mock from './mock.json'

const INITIAL_STATE = {
  error: null,
  loading: {},
  folder: null,
  selected: null,
  folders: [],
}

export default (state = INITIAL_STATE, action) => {
  const actionType = parseActionType(action.type)

  switch (action.type) {
    case GET_MEDIA.REQUEST:
    case GET_FOLDER.REQUEST:
    case GET_FILESYSTEM.REQUEST:
    case COPY.REQUEST:
    case MOVE.REQUEST:
    case DELETE.REQUEST:
    case UPLOAD.REQUEST:
    case CREATE_FOLDER.REQUEST:
    case UPDATE_FILE.REQUEST:
    case UPDATE_FOLDER.REQUEST:
      return { ...state, loading: { [actionType]: true } }
    case GET_MEDIA.FAILURE:
    case GET_FOLDER.FAILURE:
    case GET_FILESYSTEM.FAILURE:
    case COPY.FAILURE:
    case MOVE.FAILURE:
    case DELETE.FAILURE:
    case UPLOAD.FAILURE:
    case CREATE_FOLDER.FAILURE:
    case UPDATE_FOLDER.FAILURE:
    case UPDATE_FILE.FAILURE:
      return { ...state, loading: { [actionType]: false }, error: action.payload || true }
    case GET_FOLDER.SUCCESS:
      return { ...state, loading: { [actionType]: false }, folder: action.payload }
    case GET_FILESYSTEM.SUCCESS:
      const merged = state.folders.map(f =>
        action.payload.find(t => t.id === f.id) ? action.payload.find(t => t.id === f.id) : f,
      )
      const values = _.unionWith(merged, action.payload, 'id')
      return { ...state, loading: { [actionType]: false }, folders: values }
    case DELETE.SUCCESS:
      if (action.payload.isFolder)
        return {
          ...state,
          loading: { [actionType]: false },
          error: false,
          folders: _.remove(state.folders, f => f.id !== action.payload.id),
        }
      return { ...state, loading: { [actionType]: false }, error: false }
    case COPY.SUCCESS:
    case MOVE.SUCCESS:
    case UPLOAD.SUCCESS:
    case CREATE_FOLDER.SUCCESS:
    case UPDATE_FOLDER.SUCCESS:
    case UPDATE_FILE.SUCCESS:
      return { ...state, loading: { [actionType]: false }, error: false }

    case SELECT_FOLDER:
      return { ...state, selected: action.payload }

    case RESTART_STATE:
    case CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}
