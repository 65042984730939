import { call, put, takeEvery } from 'redux-saga/effects'
import api from 'services-fichap/reports'
import {
  GET_DETAILED_REPORT,
  GET_GENERAL_REPORT,
  GET_PAYROLL_REPORT,
  CLEAN_PAYROLL_REPORT,
} from './types'

function* getDetailedReportApiCall(action) {
  try {
    const response = yield call(api.getDetailedReport, action.payload)
    yield put({ type: GET_DETAILED_REPORT.SUCCESS, payload: response })
  } catch (e) {
    yield put({ type: GET_DETAILED_REPORT.FAILURE, payload: e })
  }
}

function* getGeneralReportApiCall(action) {
  try {
    const response = yield call(api.getGeneralReport, action.payload)
    yield put({ type: GET_GENERAL_REPORT.SUCCESS, payload: response })
  } catch (e) {
    yield put({ type: GET_GENERAL_REPORT.FAILURE, payload: e })
  }
}

function* getPayrollReportApiCall(action) {
  try {
    const response = yield call(api.getPayrollReport, action.payload)
    yield put({ type: GET_PAYROLL_REPORT.SUCCESS, payload: response })
  } catch (e) {
    yield put({ type: GET_PAYROLL_REPORT.FAILURE, payload: e })
  }
}

function* cleanReport(action) {
  try {
    yield put({ type: CLEAN_PAYROLL_REPORT.SUCCESS, payload: action.payload })
  } catch (error) {
    yield put({ type: CLEAN_PAYROLL_REPORT.FAILURE, payload: error })
  }
}

function* reportsIndexSaga() {
  yield takeEvery(GET_DETAILED_REPORT.REQUEST, getDetailedReportApiCall)
  yield takeEvery(GET_GENERAL_REPORT.REQUEST, getGeneralReportApiCall)
  yield takeEvery(GET_PAYROLL_REPORT.REQUEST, getPayrollReportApiCall)
  yield takeEvery(CLEAN_PAYROLL_REPORT.REQUEST, cleanReport)
}

export default reportsIndexSaga
