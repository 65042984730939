import { RESTART_STATE } from 'redux-fichap/user/types'

import {
  GET_INVENTORY,
  CREATE_INVENTORY_ITEM,
  UPDATE_INVENTORY_ITEM,
  DELETE_INVENTORY_ITEM,
  GET_INVENTORY_TYPE_LIST,
  CREATE_INVENTORY_TYPE,
  UPDATE_INVENTORY_TYPE,
  DELETE_INVENTORY_TYPE,
  FIND_INVENTORY_ITEM,
  FIND_INVENTORY_TYPE,
  GET_INVENTORY_USER_LIST,
  CREATE_INVENTORY_USER,
  UPDATE_INVENTORY_USER,
  DELETE_INVENTORY_USER,
  FIND_INVENTORY_USER,
} from './InventoryTypes'

export const defaultInventarySearch = {
  asigned: null,
  type: null,
  title: null,
  id: null,
  status: null,
}

export const defaultInventaryTypeSearch = {
  title: null,
}

const INITIAL_STATE = {
  inventory: {
    data: [],
    error: null,
    loading: null,
    lastUpdated: null,
    searching: false,
    search: {},
  },
  inventoryUser: {
    data: [],
    error: null,
    loading: null,
    lastUpdated: null,
    searching: false,
    search: defaultInventarySearch,
  },
  inventoryType: {
    data: [],
    error: null,
    loading: null,
    lastUpdated: null,
    search: defaultInventaryTypeSearch,
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INVENTORY.REQUEST:
    case CREATE_INVENTORY_ITEM.REQUEST:
    case UPDATE_INVENTORY_ITEM.REQUEST:
    case DELETE_INVENTORY_ITEM.REQUEST:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          data: [],
          error: null,
          loading: true,
        },
      }

    case GET_INVENTORY_USER_LIST.REQUEST:
    case CREATE_INVENTORY_USER.REQUEST:
    case UPDATE_INVENTORY_USER.REQUEST:
    case DELETE_INVENTORY_USER.REQUEST:
      return {
        ...state,
        inventoryUser: {
          ...state.inventoryUser,
          data: [],
          error: null,
          loading: true,
        },
      }

    case GET_INVENTORY_TYPE_LIST.REQUEST:
    case CREATE_INVENTORY_TYPE.REQUEST:
    case UPDATE_INVENTORY_TYPE.REQUEST:
    case DELETE_INVENTORY_TYPE.REQUEST:
      return {
        ...state,
        inventoryType: {
          ...state.inventoryType,
          data: [],
          error: null,
          loading: true,
        },
      }

    case GET_INVENTORY.SUCCESS:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          data: action.data,
          loading: false,
          error: false,
        },
      }
    case CREATE_INVENTORY_ITEM.SUCCESS:
    case UPDATE_INVENTORY_ITEM.SUCCESS:
    case DELETE_INVENTORY_ITEM.SUCCESS:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          loading: false,
          error: false,
          lastUpdated: action.data,
        },
      }

    case GET_INVENTORY_TYPE_LIST.SUCCESS:
      return {
        ...state,
        inventoryType: {
          ...state.inventoryType,
          data: action.data,
          loading: false,
          error: false,
        },
      }

    case CREATE_INVENTORY_TYPE.SUCCESS:
    case UPDATE_INVENTORY_TYPE.SUCCESS:
    case DELETE_INVENTORY_TYPE.SUCCESS:
      return {
        ...state,
        inventoryType: {
          ...state.inventoryType,
          loading: false,
          error: false,
          lastUpdated: action.data,
        },
      }

    case GET_INVENTORY_USER_LIST.SUCCESS:
      return {
        ...state,
        inventoryUser: {
          ...state.inventoryUser,
          data: action.data,
          loading: false,
          error: false,
        },
      }

    case CREATE_INVENTORY_USER.SUCCESS:
    case UPDATE_INVENTORY_USER.SUCCESS:
    case DELETE_INVENTORY_USER.SUCCESS:
      return {
        ...state,
        inventoryUser: {
          ...state.inventoryUser,
          loading: false,
          error: false,
          lastUpdated: action.data,
        },
      }

    case GET_INVENTORY.FAILURE:
    case CREATE_INVENTORY_ITEM.FAILURE:
    case UPDATE_INVENTORY_ITEM.FAILURE:
    case DELETE_INVENTORY_ITEM.FAILURE:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          data: [],
          loading: false,
          error: true,
        },
      }

    case GET_INVENTORY_TYPE_LIST.FAILURE:
    case CREATE_INVENTORY_TYPE.FAILURE:
    case UPDATE_INVENTORY_TYPE.FAILURE:
    case DELETE_INVENTORY_TYPE.FAILURE:
      return {
        ...state,
        inventoryType: {
          ...state.inventoryType,
          data: [],
          loading: false,
          error: true,
        },
      }

    case FIND_INVENTORY_ITEM.SUCCESS:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          searching: true,
          search: action.data,
        },
      }

    case FIND_INVENTORY_TYPE.SUCCESS:
      return {
        ...state,
        inventoryType: {
          ...state.inventoryType,
          search: action.data,
        },
      }

    case FIND_INVENTORY_USER.SUCCESS:
      return {
        ...state,
        inventoryUser: {
          ...state.inventoryUser,
          search: action.data,
        },
      }

    case RESTART_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
