import { createRequestTypes } from '../../utils'

const namespace = 'workshift'

export const GET_WORKSHIFTS = createRequestTypes(`${namespace}/GET_WORKSHIFTS`)

export const UPDATE_WORKSHIFT = createRequestTypes(`${namespace}/UPDATE_WORKSHIFT`)

export const CREATE_WORKSHIFT = createRequestTypes(`${namespace}/CREATE_WORKSHIFT`)

export const DELETE_WORKSHIFT = createRequestTypes(`${namespace}/DELETE_WORKSHIFT`)
