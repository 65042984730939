import React from 'react'
import { onlyOwner, onlyAdmin, onlyManagerAndOwner, onlyViewer } from 'utils/userRoles'
import { nomilineaActivated } from 'entities/company'
import { IS_PRODUCTION } from 'api/api-connection'
import { MODULES_FICHAP } from '../../contexts/auth/auth.consts'
import equipo from './assets/icon-equipo.svg'
import admin from './assets/icon-admin.svg'
import ausentismo from './assets/icon-ausentismo.svg'
import ayuda from './assets/icon-ayuda.svg'
import config from './assets/icon-config.svg'
import dashboard from './assets/icon-dashboard.svg'
import bajas from './assets/icon-bajas.svg'
import cargos from './assets/icon-cargos.svg'
import empresas from './assets/icon-empresa.svg'
import errorfaceid from './assets/icon-errorfaceid.svg'
import bookmark from './assets/bookmark.svg'
import organizacion from './assets/icon-organizacion.svg'
import employeeRecord from './assets/employeeRecord.svg'
import turnos from './assets/icon-turnos.svg'
import miembros from './assets/icon-miembros.svg'
import communication from './assets/icon-communication.svg'
import datosEmpresa from './assets/icon-datos-empresa.svg'
import informe from './assets/icon-informe.svg'
import inventory from './assets/icon-inventario.svg'
import Organigrama from './assets/organigrama.svg'
import Salary from './assets/dollar-sign.svg'
import Newspaper from './assets/Newspaper.svg'
import { COMPANIES } from '../../entities/company'
import { getCurrentUser } from '../../entities/user'

export function getMenuData(hasMenuPermission) {
  const user = getCurrentUser()

  const canSeeNovelties =
    user?.companyId === COMPANIES.SIGMA || user?.companyId === COMPANIES.GESTION_HR

  const menu = [
    {
      title: 'Dashboard',
      key: 'dashboards',
      icon: dashboard,
      url: '/dashboard',
      authorization: false,
    },
    {
      title: 'Team',
      key: 'team',
      icon: equipo,
      children: [
        hasMenuPermission(
          {
            title: 'Collaborators',
            key: 'teamMembers',
            url: '/team',
            icon: miembros,
            authorization: true,
            module: MODULES_FICHAP.USERS,
          },
          onlyViewer,
        ),
        hasMenuPermission(
          {
            title: 'ShiftTemplates',
            key: 'shifts',
            url: '/team/shifts',
            icon: turnos,
            authorization: true,
            module: MODULES_FICHAP.SHIFTS,
          },
          onlyViewer,
        ),
        hasMenuPermission(
          {
            title: 'MarksReview',
            key: 'MarksReview',
            url: '/team/marks-review',
            icon: errorfaceid,
            authorization: true,
            module: MODULES_FICHAP.WORKDAYS_REVIEWS,
          },
          onlyViewer,
        ),
        hasMenuPermission(
          !IS_PRODUCTION ? (
            {
              title: 'ReviewAndApprovals',
              key: 'ReviewApprovals',
              url: '/Reviews-Approvals',
              icon: errorfaceid,
              authorization: true,
              module: MODULES_FICHAP.REVIEWS_APPROVALS,
            }
          ) : (
            <></>
          ),
          onlyAdmin,
        ),
        hasMenuPermission(
          {
            title: 'casualtylist',
            key: 'casualty-list',
            url: '/team/casualtyList',
            icon: bajas,
            authorization: true,
            module: MODULES_FICHAP.UNACTIVE_USERS,
          },
          onlyAdmin,
        ),
        hasMenuPermission(
          {
            title: 'Licenses',
            key: 'absences',
            icon: ausentismo,
            collapsed: true,
            url: '/requests',
            className: 'AbsencesSubMenu',
            // authorization: true,
            module: MODULES_FICHAP.DAYOFFS,
          },
          onlyViewer,
        ),
        {
          title: 'Reports',
          key: 'reports',
          icon: informe,
          url: '/reports',
        },
        canSeeNovelties && {
          title: 'Novedades',
          key: 'news',
          icon: Newspaper,
          url: '/news',
        },
      ],
    },
    {
      title: 'Company',
      key: 'companies',
      icon: empresas,
      children: [
        hasMenuPermission(
          {
            title: 'CompanyData',
            key: 'company',
            url: '/company',
            icon: datosEmpresa,
            authorization: true,
            module: MODULES_FICHAP.COMPANY_PROFILE,
          },
          onlyViewer,
        ),
        hasMenuPermission(
          {
            title: 'BranchOffices',
            key: 'branch-offices',
            icon: organizacion,
            url: '/companies',
            authorization: true,
            module: MODULES_FICHAP.BRANCHES,
          },
          onlyViewer,
        ),
        nomilineaActivated() ? (
          {
            title: 'employerRecords',
            key: 'employee-records',
            icon: employeeRecord,
            url: '/employee-records',
            iconStyles: {
              width: 21,
              // paddingTop: '3px',
            },
          }
        ) : (
          <></>
        ),
        hasMenuPermission(
          {
            title: 'Hirearchies',
            key: 'hirearchies',
            url: '/levels',
            icon: cargos,
            authorization: true,
            module: MODULES_FICHAP.HIERARCHIES,
          },
          onlyViewer,
        ),
        hasMenuPermission(
          {
            title: 'SpecialDays',
            key: 'specialdays',
            icon: bookmark,
            url: '/specialdays',
            authorization: true,
            module: MODULES_FICHAP.SPECIAL_DAYS,
            iconStyles: {
              width: 16,
              paddingTop: '3px',
            },
          },
          onlyViewer,
        ),
        hasMenuPermission(
          {
            title: 'OrganizationChart',
            key: 'OrganizationChart',
            icon: Organigrama,
            url: '/team/organization-chart',
            authorization: true,
            module: MODULES_FICHAP.ORGANIZATION_CHART,
          },
          onlyViewer,
        ),
        hasMenuPermission(
          {
            title: 'Inventory',
            key: 'inventory',
            icon: inventory,
            url: '/inventory',
            authorization: true,
            module: MODULES_FICHAP.INVENTORY,
          },
          onlyViewer,
        ),
        hasMenuPermission(
          {
            title: 'Salary',
            key: 'salary',
            icon: Salary,
            url: '/company/salary',
            authorization: true,
            module: MODULES_FICHAP.SALARY,
          },
          onlyAdmin,
        ),
      ],
    },
    hasMenuPermission(
      {
        title: 'Communication',
        key: 'communication',
        icon: communication,
        url: '/communication/billboard',
        authorization: true,
        module: MODULES_FICHAP.COMUNICATION,
      },
      onlyManagerAndOwner,
    ),
    onlyOwner({
      title: 'Users',
      key: 'admins',
      icon: admin,
      url: '/user',
    }),
    {
      title: 'Help',
      key: 'help',
      icon: ayuda,
      target: '_blank',
      url: 'https://help.fichap.com/',
    },
    hasMenuPermission(
      {
        title: 'Settings',
        key: 'settings',
        icon: config,
        url: '/settings',
        authorization: true,
        module: MODULES_FICHAP.COMPANY,
      },
      onlyOwner,
    ),
  ]

  const filteredMenu = menu.filter(item => item.key !== null)

  return filteredMenu
}
