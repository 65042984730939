import { parseActionType } from 'utils'
import {
  GET_PROFILE_DATA,
  UPDATE_PROFILE_DATA,
  UPDATE_PROFILE_DATA_FINISHED,
  CLEAR_ERROR,
  CHANGE_PASSWORD,
} from './types'

const INITIAL_STATE = {
  error: null,
  loading: {},
}

export default (state = INITIAL_STATE, action) => {
  const actionType = parseActionType(action.type)

  switch (action.type) {
    case CHANGE_PASSWORD.REQUEST:
    case GET_PROFILE_DATA.REQUEST:
    case UPDATE_PROFILE_DATA.REQUEST:
      return { ...state, loading: { [actionType]: true } }

    case GET_PROFILE_DATA.SUCCESS:
      return { ...state, loading: { [actionType]: false }, profile: action.payload }

    case CHANGE_PASSWORD.SUCCESS:
    case UPDATE_PROFILE_DATA.SUCCESS:
      return { ...state, loading: { [actionType]: false } }

    case UPDATE_PROFILE_DATA_FINISHED.SUCCESS:
      return { ...state, loading: { [actionType]: false } }

    case CHANGE_PASSWORD.FAILURE:
    case GET_PROFILE_DATA.FAILURE:
    case UPDATE_PROFILE_DATA.FAILURE:
      return { ...state, loading: { [actionType]: false }, error: action.payload || true }

    case CLEAR_ERROR.REQUEST:
      return { ...state, loading: { [actionType]: true } }
    case CLEAR_ERROR.SUCCESS:
      return { ...state, loading: { [actionType]: false }, error: null }
    case CLEAR_ERROR.FAILURE:
      return {
        ...state,
        loading: { [actionType]: false },
        error: action.payload,
      }

    default:
      return state
  }
}
