import { createRequestTypes } from '../../utils'

const namespace = 'fileSystem'

export const GET_MEDIA = createRequestTypes(`${namespace}/GET_MEDIA`)
export const GET_FOLDER = createRequestTypes(`${namespace}/GET_FOLDER`)
export const GET_FILESYSTEM = createRequestTypes(`${namespace}/GET_FILESYSTEM`)
export const COPY = createRequestTypes(`${namespace}/COPY`)
export const MOVE = createRequestTypes(`${namespace}/MOVE`)
export const DELETE = createRequestTypes(`${namespace}/DELETE`)
export const UPLOAD = createRequestTypes(`${namespace}/UPLOAD`)
export const CREATE_FOLDER = createRequestTypes(`${namespace}/CREATE_FOLDER`)
export const UPDATE_FOLDER = createRequestTypes(`${namespace}/UPDATE_FOLDER`)
export const UPDATE_FILE = createRequestTypes(`${namespace}/UPDATE_FILE`)

export const SELECT_FOLDER = `${namespace}/SELECT_FOLDER`
export const CLEAR = `${namespace}/CLEAR`
