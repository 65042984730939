import { createRequestTypes } from '../../utils'

const namespace = 'company'

export const GET_COMPANY_DATA = createRequestTypes(`${namespace}/GET_COMPANY_DATA`)

export const UPDATE_COMPANY_DATA = createRequestTypes(`${namespace}/UPDATE_COMPANY_DATA`)

export const GET_COMPANY_PAYMENTS = createRequestTypes(`${namespace}/GET_COMPANY_PAYMENTS`)

export const GET_COMPANY_PAYMENT_BY_ID = createRequestTypes(
  `${namespace}/GET_COMPANY_PAYMENT_BY_ID`,
)

export const GET_COMPANY_PLAN = createRequestTypes(`${namespace}/GET_COMPANY_PLAN`)

export const GET_SPECIAL_DAYS = createRequestTypes(`${namespace}/GET_SPECIAL_DAYS`)

export const UPDATE_SPECIAL_DAYS = createRequestTypes(`${namespace}/UPDATE_SPECIAL_DAYS`)

export const DELETE_SPECIAL_DAYS = createRequestTypes(`${namespace}/DELETE_SPECIAL_DAYS`)

export const CREATE_SPECIAL_DAYS = createRequestTypes(`${namespace}/CREATE_SPECIAL_DAYS`)
