import React from 'react'
import NotificationGenerator from 'base/NotificationGenerator'
import ShowErrors from 'base/ShowErrors'

export const INCAPACIDADES = {
  ENFERMEDAD_GENERAL: {
    value: 'Enfermedad General',
    children: [
      {
        value: 'No Aplica',
        children: [
          {
            value: 'No aplica',
            children: [{ value: 'Única', children: [] }],
          },
          {
            value: 'Inicial',
            children: [
              { value: 'Inicial', children: [] },
              { value: 'Subsecuente', children: [] },
              { value: 'Alta médica o ST-2', children: [] },
            ],
          },
          {
            value: 'Defunción',
            children: [{ value: 'ST-3', children: [] }],
          },
        ],
      },
    ],
  },
  RIESGO_TRABAJO: {
    value: 'Riesgo de Trabajo',
    children: [
      {
        value: 'Accidente de trabajo',
        children: [
          {
            value: 'No aplica',
            children: [{ value: 'Única', children: [] }],
          },
          {
            value: 'Inicial',
            children: [
              { value: 'Única', children: [] },
              { value: 'Inicial', children: [] },
              { value: 'Subsecuente', children: [] },
              { value: 'Alta médica o ST-2', children: [] },
            ],
          },
          {
            value: 'Recaída',
            children: [
              { value: 'Única', children: [] },
              { value: 'Inicial', children: [] },
              { value: 'Subsecuente', children: [] },
              { value: 'Alta médica o ST-2', children: [] },
            ],
          },
          {
            value: 'Valuación',
            children: [
              { value: 'Provisional ST-3', children: [] },
              { value: 'Definitiva ST-3', children: [] },
            ],
          },
          {
            value: 'Revaluación',
            children: [
              { value: 'Provisional ST-3', children: [] },
              { value: 'Definitiva ST-3', children: [] },
            ],
          },
          {
            value: 'Defunción',
            children: [{ value: 'ST-3', children: [] }],
          },
        ],
      },
      {
        value: 'Accidente en Trayecto',
        children: [
          {
            value: 'No aplica',
            children: [{ value: 'Única', children: [] }],
          },
          {
            value: 'Inicial',
            children: [
              { value: 'Única', children: [] },
              { value: 'Inicial', children: [] },
              { value: 'Subsecuente', children: [] },
              { value: 'Alta médica o ST-2', children: [] },
            ],
          },
          {
            value: 'Recaída',
            children: [
              { value: 'Única', children: [] },
              { value: 'Inicial', children: [] },
              { value: 'Subsecuente', children: [] },
              { value: 'Alta médica o ST-2', children: [] },
            ],
          },
          {
            value: 'Valuación',
            children: [
              { value: 'Provisional ST-3', children: [] },
              { value: 'Definitiva ST-3', children: [] },
            ],
          },
          {
            value: 'Revaluación',
            children: [
              { value: 'Provisional ST-3', children: [] },
              { value: 'Definitiva ST-3', children: [] },
            ],
          },
          {
            value: 'Defunción',
            children: [{ value: 'ST-3', children: [] }],
          },
        ],
      },
      {
        value: 'Enfermedad de Trabajo',
        children: [
          {
            value: 'No aplica',
            children: [{ value: 'Única', children: [] }],
          },
          {
            value: 'Inicial',
            children: [
              { value: 'Única', children: [] },
              { value: 'Inicial', children: [] },
              { value: 'Subsecuente', children: [] },
              { value: 'Alta médica o ST-2', children: [] },
            ],
          },
          {
            value: 'Recaída',
            children: [
              { value: 'Única', children: [] },
              { value: 'Inicial', children: [] },
              { value: 'Subsecuente', children: [] },
              { value: 'Alta médica o ST-2', children: [] },
            ],
          },
          {
            value: 'Valuación',
            children: [
              { value: 'Provisional o ST-2', children: [] },
              { value: 'Definitiva ST-3', children: [] },
            ],
          },
          {
            value: 'Revaluación',
            children: [
              { value: 'Provisional ST-3', children: [] },
              { value: 'Definitiva ST-3', children: [] },
            ],
          },
          {
            value: 'Defunción',
            children: [{ value: 'ST-3', children: [] }],
          },
        ],
      },
    ],
  },
  MATERNIDAD: {
    value: 'Maternidad',
    children: [
      {
        value: 'Pre',
        children: [
          {
            value: 'No aplica',
            children: [{ value: 'Única', children: [] }],
          },
        ],
      },
      {
        value: 'Enlace',
        children: [
          {
            value: 'No aplica',
            children: [{ value: 'Única', children: [] }],
          },
        ],
      },
      {
        value: 'Post',
        children: [
          {
            value: 'No aplica',
            children: [{ value: 'Única', children: [] }],
          },
        ],
      },
    ],
  },
  LICENCIA_LAB_140_BIS: {
    value: 'Licencia Lab. 140 Bis',
    children: [
      {
        value: 'No aplica',
        children: [
          {
            value: 'No aplica',
            children: [{ value: 'Única', children: [] }],
          },
        ],
      },
    ],
  },
}

export const nomilineaErrors = messages => {
  if (messages?.length)
    NotificationGenerator(undefined, undefined, true, {
      children: (
        <ShowErrors
          message="Errores/observaciones al actualizar usuario en nomilinea"
          errors={messages}
        />
      ),
    })
}
