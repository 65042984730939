/* eslint-disable class-methods-use-this */
import api from 'api/api-connection'

export class NaturalResources {
  /*
  1000kg_papel = 17arboles
  1arbol = 12.000hojas
  10litros = 1hoja
  1kg_papel = 1.8kgCO2
  1kg_papel = 200hojas
  1_kg_papel = 3kg C02
*/

  async print(_sheets = 1) {
    try {
      const res = await api.post('/companies/print', { sheets: _sheets })
      // console.log('res:', res)
    } catch (error) {
      console.error(error)
    }
  }
}

const naturalResources = new NaturalResources()

export default naturalResources
