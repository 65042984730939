import { RESTART_STATE } from 'redux-fichap/user/types'
import { parseActionType } from 'utils'

import {
  CLEAR_WORKDAYS,
  DELETE_WORKDAY_BY_EMPLOYEE_ID,
  GET_REPORTS_COMPANY,
  GET_WORKDAYS_BY_EMPLOYEE_ID,
  MODIFIE_WORKDAY,
  SELECTED_WORKDAY_DETAILE,
  UPDATE_REPORT,
} from './types'

const initialState = {
  employeeWorkday: null,
  selected: localStorage.getItem('SELECT_WORKDAY') || null,
  loading: {},
  error: null,
  reports: [],
}

export default function workdayReducer(state = initialState, action) {
  const actionType = parseActionType(action.type)

  switch (action.type) {
    case GET_WORKDAYS_BY_EMPLOYEE_ID.REQUEST:
    case DELETE_WORKDAY_BY_EMPLOYEE_ID.REQUEST:
    case GET_REPORTS_COMPANY.REQUEST:
    case MODIFIE_WORKDAY.REQUEST:
    case UPDATE_REPORT.REQUEST:
      return { ...state, loading: { [actionType]: true } }

    case GET_REPORTS_COMPANY.SUCCESS:
      return { ...state, loading: { [actionType]: false }, reports: action.payload }

    case GET_WORKDAYS_BY_EMPLOYEE_ID.SUCCESS:
      return { ...state, loading: { [actionType]: false }, employeeWorkday: action.payload }
    case DELETE_WORKDAY_BY_EMPLOYEE_ID.SUCCESS:
      return { ...state, loading: { [actionType]: false } }
    case MODIFIE_WORKDAY.SUCCESS:
    case UPDATE_REPORT.SUCCESS:
      return { ...state, loading: { [actionType]: false } }
    case MODIFIE_WORKDAY.FAILURE:
    case DELETE_WORKDAY_BY_EMPLOYEE_ID.FAILURE:
    case GET_REPORTS_COMPANY.FAILURE:
    case UPDATE_REPORT.FAILURE:
    case GET_WORKDAYS_BY_EMPLOYEE_ID.FAILURE:
      return {
        ...state,
        loading: { [actionType]: false },
        error: action.payload,
      }

    case SELECTED_WORKDAY_DETAILE:
      localStorage.setItem('SELECT_WORKDAY', action.payload)
      return {
        ...state,
        selected: action.payload,
      }
    case CLEAR_WORKDAYS:
      return {
        ...state,
        loading: {},
        employeeWorkday: null,
      }
    case RESTART_STATE:
      return initialState
    default:
      return state
  }
}
