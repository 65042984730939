import api from 'api/api-connection'

const getDismissedTable = () => {
  return api
    .post('/users/getDismissedTable', { limit: 100, offset: 0 })
    .then(response => response.data.data.users)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })
}

export default {
  getDismissedTable,
}
