const requestTypes = ['REQUEST', 'SUCCESS', 'FAILURE', 'CUSTOM']

export function createRequestTypes(base) {
  return requestTypes.reduce((acc, type) => {
    if (type === 'CUSTOM') acc[type] = `${base}`
    else acc[type] = `${base}_${type}`
    return acc
  }, {})
}

export function parseActionType(action) {
  const indx =
    action.indexOf('_REQUEST') !== -1
      ? action.indexOf('_REQUEST')
      : action.indexOf('_SUCCESS') !== -1
      ? action.indexOf('_SUCCESS')
      : action.indexOf('_FAILURE')
  return action.substring(0, indx)
}

export function mergerArrayObjects(arr1, arr2) {
  if (arr1 && arr2) {
    const temp = [...arr1, ...arr2]
    const res = []
    for (const t of temp) {
      const reps = temp.filter(f => f.key === t.key)
      if (!res.find(f => f.key === t.key)) {
        if (reps) {
          res.push(reps.reduce((p, n) => ({ ...p, ...n }), {}))
        } else {
          res.push(t)
        }
      }
    }
    return res
  }
  if (arr1 && !arr2) return arr1
  if (arr2 && !arr1) return arr2
}

export function arrayBufferToBase64(buffer) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export function notRepitedObjInArray(array) {
  return array.filter((element, index) => {
    return array.indexOf(element) === index
  })
}

export const titleCase = string => string.charAt(0).toUpperCase() + string.slice(1)

export function randomColor() {
  let letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export const replaceAll = (str, search, replace = '') => {
  if (typeof str === 'string') return str.replace(new RegExp(search, 'g'), replace)
  return str
}
