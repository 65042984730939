/* eslint-disable class-methods-use-this */
import store from 'store'

export const nomilineaActivated = () => store.get('COMPANY_DATA')?.nomilinea

export const COMPANIES = {
  FARMAPLUS: '6348c9f32b40040012bdb17d',
  TATA: '6056732f29daf4001044de2d',
  GESTION_HR: '611fa27a5be17a0011ef3c48',
  CROWDAR: '61af5987a954ca00112db33f',
  TSUYOI: '665a3b0c2b46d9d716c830cc',
  TENARIS: '6532b4e4a17e7800126a7f84',
  SPORTLINE: '653b3894a17e7800126a8041',
  GRUPOA2: '6532b77ba17e7800126a7f87',
  // SIGMA: '654bb125680cc80011392d80', comentado para subir a prod
  NEW_SOUTHBAY: '6717a4d5e42ea2d0ee4b82d2',
  MOREL: '64c869073da6e4001214c9a2',
  BRAND_TEAM: '624779464871fa001278354d',
}
// import { COMPANIES } from 'entities/company'

class CompanyDefault {}

const Company = new CompanyDefault()

export default Company
