/* eslint-disable react/react-in-jsx-scope */
import getOr from 'lodash/fp/getOr'
import { call, put, spawn, takeEvery } from 'redux-saga/effects'
import NotificationGenerator from 'base/NotificationGenerator'
import { nomilineaErrors } from 'entities/nomilinea'
import { intl } from '../../locales/IntlGlobalProvider'
import {
  createEmployee,
  getEmployees,
  enabledDisabledEmployee,
  updateEmployee,
  getEmployeeById,
  getCalendarById,
  updateCalendar,
} from '../../services-fichap/team'
import {
  CREATE_EMPLOYEE,
  GET_EMPLOYEES_BY_COMPANY_ID,
  ENABLED_DISABLED_EMPLOYEE,
  UPDATE_EMPLOYEE,
  GET_EMPLOYEE_BY_ID,
  GET_CALENDAR_BY_ID,
  PUSH_CALENDAR_UPDATE,
  UPDATE_CALENDAR,
  CLEAR_ERROR_CODE,
} from './types'

function* updateEmployeeIndexApiCall(action) {
  try {
    const response = yield call(
      updateEmployee,
      action.payload.id,
      action.payload.payload,
      action.payload.file,
    )
    nomilineaErrors(response.data.data?.messages)

    yield put({ type: UPDATE_EMPLOYEE.SUCCESS })
    yield put({
      type: GET_EMPLOYEE_BY_ID.REQUEST,
      payload: { id: action.payload.id, callback: () => {} },
    })
    NotificationGenerator('CollaboratorUpdated', intl, false)
    action.payload.callback()
  } catch (e) {
    yield put({ type: UPDATE_EMPLOYEE.FAILURE, payload: e })
    // NotificationGenerator(`Ha ocurrido un error al actualizar el usuario ${e}`, intl, false)
    console.error(
      `Hemos tenido un problema al procesar su solicitud. Código de error: ${getOr(
        'payloadError',
        'response.data.data.message',
      )}`,
      { e },
    )
  }
}

function* updateEmployeeIndexSaga() {
  yield takeEvery(UPDATE_EMPLOYEE.REQUEST, updateEmployeeIndexApiCall)
}

function* deleteEmployeeIndexApiCall(action) {
  try {
    yield call(enabledDisabledEmployee, action.payload.id, action.payload.payload)
    yield put({ type: ENABLED_DISABLED_EMPLOYEE.SUCCESS })
    action.payload.callback()
  } catch (e) {
    yield put({ type: ENABLED_DISABLED_EMPLOYEE.FAILURE, payload: e })
  }
}

function* deleteEmployeeIndexSaga() {
  yield takeEvery(ENABLED_DISABLED_EMPLOYEE.REQUEST, deleteEmployeeIndexApiCall)
}

function* setEmployeeIndexApiCall(action) {
  try {
    yield call(createEmployee, action.payload)
    yield put({ type: CREATE_EMPLOYEE.SUCCESS, payload: { restart: true } })
    // yield put({ type: GET_EMPLOYEES_BY_COMPANY_ID.REQUEST, payload: { skip: 0, limit: 100, sort: {}, filters: {} } })
    /*     notification.success({
      message: intl.formatMessage({ id: 'employeeCreationSuccess' }),
    })  */
    NotificationGenerator('employeeCreationSuccess', intl, false)
  } catch (e) {
    yield put({ type: CREATE_EMPLOYEE.FAILURE, payload: e })
    // notification.error({
    //   message: intl.formatMessage({ id: 'employeeCreationError' }),
    // })
  }
}

function* setEmployeeIndexSaga() {
  yield takeEvery(CREATE_EMPLOYEE.REQUEST, setEmployeeIndexApiCall)
}

function* getEmployeeByIdIndexApiCall(action) {
  try {
    const response = yield call(getEmployeeById, action.payload.id)
    yield put({ type: GET_EMPLOYEE_BY_ID.SUCCESS, payload: response.data })
    action.payload.callback()
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({ type: GET_EMPLOYEE_BY_ID.FAILURE, payload: e })
  }
}

function* getEmployeeByIdIndexSaga() {
  yield takeEvery(GET_EMPLOYEE_BY_ID.REQUEST, getEmployeeByIdIndexApiCall)
}

function* getCalendarByIdApiCall(action) {
  try {
    const response = yield call(getCalendarById, action.payload)
    yield put({ type: GET_CALENDAR_BY_ID.SUCCESS, payload: response })
  } catch (e) {
    yield put({ type: GET_CALENDAR_BY_ID.FAILURE, payload: e })
  }
}

function* getCalendarByIdIndexSaga() {
  yield takeEvery(GET_CALENDAR_BY_ID.REQUEST, getCalendarByIdApiCall)
}

function* getEmployeesByCompanyIdIndexApiCall(action) {
  try {
    const response = yield call(getEmployees, action.payload)
    yield put({
      type: GET_EMPLOYEES_BY_COMPANY_ID.SUCCESS,
      // eslint-disable-next-line
      payload: { ...response.data, restart: action.payload.restart ? true : false },
    })
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({ type: GET_EMPLOYEES_BY_COMPANY_ID.FAILURE, payload: e })
  }
}

function* getEmployeesByCompanyIdIndexSaga() {
  yield takeEvery(GET_EMPLOYEES_BY_COMPANY_ID.REQUEST, getEmployeesByCompanyIdIndexApiCall)
}

function* pushCalendarUpdate(action) {
  yield put({
    type: PUSH_CALENDAR_UPDATE.SUCCESS,
    payload: action.payload,
  })
}

function* pushCalendarUpdateIndexSaga() {
  yield takeEvery(PUSH_CALENDAR_UPDATE.REQUEST, pushCalendarUpdate)
}

function* updateCalendarApiCall(action) {
  try {
    yield call(updateCalendar, action.payload)
    yield put({
      type: UPDATE_CALENDAR.SUCCESS,
      payload: 'ok',
    })
  } catch (e) {
    yield put({
      type: UPDATE_CALENDAR.FAILURE,
      payload: 'ok',
    })
  }
}

function* updateCalendarIndexSaga() {
  yield takeEvery(UPDATE_CALENDAR.REQUEST, updateCalendarApiCall)
}

function* clearErrorCode(action) {
  try {
    yield put({ type: CLEAR_ERROR_CODE.SUCCESS, payload: action.payload })
  } catch (error) {
    yield put({ type: CLEAR_ERROR_CODE.FAILURE, payload: error })
  }
}
/** ********************************** */

function* TeamIndexSaga() {
  yield spawn(getEmployeesByCompanyIdIndexSaga)
  yield spawn(setEmployeeIndexSaga)
  yield spawn(deleteEmployeeIndexSaga)
  yield spawn(updateEmployeeIndexSaga)
  yield spawn(getEmployeeByIdIndexSaga)
  yield spawn(getCalendarByIdIndexSaga)
  yield spawn(pushCalendarUpdateIndexSaga)
  yield spawn(updateCalendarIndexSaga)
  yield spawn(clearErrorCode)
}

export default TeamIndexSaga
