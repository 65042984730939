import { createRequestTypes } from '../../utils'

const namespace = 'dashboard'

export const GET_ABSENCES = createRequestTypes(`${namespace}/GET_ABSENCES`)

export const GET_PRECENSES = createRequestTypes(`${namespace}/GET_PRECENSES`)

export const GET_LATE_ARRIVALS = createRequestTypes(`${namespace}/GET_LATE_ARRIVALS`)

export const GET_CALENDAR = createRequestTypes(`${namespace}/GET_CALENDAR`)

export const GET_GRAPHIC = createRequestTypes(`${namespace}/GET_GRAPHIC`)

export const GET_MAP = createRequestTypes(`${namespace}/GET_MAP`)
