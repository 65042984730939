import api from 'services-fichap/hierarchy/index'
import { takeEvery, put, call } from 'redux-saga/effects'
import {
  GET_HIERARCHIES,
  GET_HIERARCHIE_BY_ID,
  DELETE_HIERARCHY,
  CREATE_HIERARCHY,
  UPDATE_HIERARCHY,
} from './types'

function* getHierarchiesApiCall() {
  try {
    const hierarchies = yield call(api.getHierarchies)
    const types = yield call(api.getTypes)
    yield put({ type: GET_HIERARCHIES.SUCCESS, payload: { hierarchies, types } })
  } catch (error) {
    yield put({ type: GET_HIERARCHIES.FAILURE, payload: error })
  }
}

function* getHierarchieByIdApiCall(action) {
  try {
    const response = yield call(api.getHierarchieById, action.payload)
    yield put({ type: GET_HIERARCHIE_BY_ID.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_HIERARCHIE_BY_ID.FAILURE, payload: error })
  }
}

function* updateHierarchyApiCall(action) {
  try {
    const response = yield call(api.updateHierarchy, action.payload)
    yield put({ type: UPDATE_HIERARCHY.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: UPDATE_HIERARCHY.FAILURE, payload: error })
  }
}

function* createHierarchyApiCall(action) {
  try {
    const response = yield call(api.createHierarchy, action.payload)
    yield put({ type: CREATE_HIERARCHY.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: CREATE_HIERARCHY.FAILURE, payload: error })
  }
}

function* deleteHierarchyApiCall(action) {
  try {
    const response = yield call(api.deleteHierarchy, action.payload)
    yield put({ type: DELETE_HIERARCHY.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: DELETE_HIERARCHY.FAILURE, payload: error })
  }
}

function* IndexSaga() {
  yield takeEvery(GET_HIERARCHIES.REQUEST, getHierarchiesApiCall)
  yield takeEvery(GET_HIERARCHIE_BY_ID.REQUEST, getHierarchieByIdApiCall)
  yield takeEvery(UPDATE_HIERARCHY.REQUEST, updateHierarchyApiCall)
  yield takeEvery(CREATE_HIERARCHY.REQUEST, createHierarchyApiCall)
  yield takeEvery(DELETE_HIERARCHY.REQUEST, deleteHierarchyApiCall)
}

export default IndexSaga
