/* eslint-disable no-unused-expressions */
import { createSelector } from 'reselect'
import store from 'store'
import moment from 'moment'

const reports = state => state.workday.reports
export const getWorkdaysByEmployee = state => state.workday.employeeWorkday
export const workdayLoading = state => state.workday.loading
export const selectedWorkday = state => state.workday.selected

export const getSelectedWorkday = createSelector(
  [getWorkdaysByEmployee, selectedWorkday],
  items => {
    return items || {}
    // return items ? items.data.values.workdays.find(i => i._id === selected) : {}
  },
)

export const getReports = createSelector([reports], items => {
  return items && items.map(i => i.report)
})

const parseUtc = date => moment.utc(date).toDate()

export const getTimesWorkdaySelected = createSelector([getSelectedWorkday], item => {
  try {
    const res = []
    const clocks = store.get('WORKDAY_SELECTED_CLOCKS', [])
    let Workday = {}
    if (item) {
      item.values.workdays?.forEach(workday => {
        if (clocks[0] && workday.clocks.includes(clocks[0])) {
          Workday = workday
        }
      })
    }
    if (Workday) {
      Workday.historyHours?.forEach(hour => {
        const detail = []
        switch (hour.type) {
          case 'LATER_ARRIVAL':
            detail.push({
              start: moment(parseUtc(hour.end))
                .subtract(hour.time, 'minutes')
                .format('DD/MM/YYYY HH:mm:ss'),
              end: moment(parseUtc(hour.end)).format('DD/MM/YYYY HH:mm:ss'),
              status: 'late',
            })
            break
          case 'EXTRA_HOURS':
            detail.push({
              start: moment(parseUtc(hour.start)).format('DD/MM/YYYY HH:mm:ss'),
              end: hour.end ? moment(parseUtc(hour.end)).format('DD/MM/YYYY HH:mm:ss') : null,
              status: 'extra',
            })
            break
          case 'BREAK':
            detail.push({
              start: moment(parseUtc(hour.start)).format('DD/MM/YYYY HH:mm:ss'),
              status: 'break',
              end: hour.end ? moment(parseUtc(hour.end)).format('DD/MM/YYYY HH:mm:ss') : null,
            })
            break

          default:
            detail.push({
              start: moment(parseUtc(hour.start)).format('DD/MM/YYYY HH:mm:ss'),
              end: hour.end ? moment(parseUtc(hour.end)).format('DD/MM/YYYY HH:mm:ss') : null,
              status: 'worked',
            })
            break
        }
        res.push(detail)
      })
    }
    return res
  } catch (err) {
    console.error(err)
    return []
  }
})

export const getReportsAprobes = createSelector([getReports], items => {
  return items.filter(item => item.status === 'APPROVED')
})

export const getReportsReject = createSelector([getReports], items => {
  return items.filter(item => item.status === 'REJECTED')
})

export const getReportsPendings = createSelector([getReports], items => {
  return items.filter(item => item.status === 'PENDING')
})
