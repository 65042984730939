import api, { patch, uploadFile } from 'api/api-connection'
import cleanDeep from 'clean-deep'
import NotificationGenerator from 'base/NotificationGenerator'
import { intl } from '../../locales/IntlGlobalProvider'

const getAdministrators = () =>
  api
    .get('/users/admin/getAdminTable')
    .then(({ data }) => data.data)
    .catch(err => {
      console.error(`ERROR: ${err}`)
      throw new Error(err)
    })

const getAdministratorById = id =>
  api
    .get(`/users/${id}`)
    .then(({ data }) => data.data)
    .catch(err => {
      console.error(`ERROR: ${err}`)
      throw new Error(err)
    })

const createAdministrator = admin => {
  return api
    .post('/users/admin', admin)
    .then(response => {
      const filteredInput = cleanDeep(admin)
      const file = filteredInput.image
      const formData = new FormData()
      formData.append('image', new Blob([new Uint8Array(file.arrayBuffer)], { type: file.type }))
      uploadFile('patch', `/users/image/${response.data.data._id}`, formData)
        .then(data => {
          if (data?.response?.data?.data.message)
            NotificationGenerator(data?.response?.data?.data.message, intl, true)
          return response.response
        })
        .catch(data => {
          if (data?.response?.data?.data.message)
            NotificationGenerator(data?.response?.data?.data.message, intl, true)
          return response.response
        })
    })
    .catch(err => {
      const error = err.response?.data?.data
      throw new Error(WatchError(error))
    })
}

const updateAdministrator = admin => {
  const filteredInput = cleanDeep(admin)
  try {
    if (!filteredInput.image && filteredInput.hadImage && filteredInput.imgDeleted) {
      api.delete(`/users/image/${admin._id}`).then(data => {})
    }
    if (filteredInput.image) {
      const file = filteredInput.image
      const formData = new FormData()
      formData.append('image', new Blob([new Uint8Array(file.arrayBuffer)], { type: file.type }))
      let res
      uploadFile('patch', `/users/image/${admin._id}`, formData)
        .then(data => {
          if (data?.response?.data?.data.message)
            NotificationGenerator(data?.response?.data?.data.message, intl, true)
        })
        .catch(data => {
          if (data?.response?.data?.data.message)
            NotificationGenerator(data?.response?.data?.data.message, intl, true)
          res = patch(`/users/admin/${admin._id}`, {}, filteredInput)
        })
        .then(() => {
          res = patch(`/users/admin/${admin._id}`, {}, filteredInput)
        })
      return res
    } else {
      return patch(`/users/admin/${admin._id}`, {}, filteredInput)
    }
  } catch (e) {
    console.error(e)
  }
}

const deleteAdminstrator = id => {
  api
    .delete(`/users/admin/${id}`)
    .then(({ response }) => {
      NotificationGenerator('adminDeleted', intl, false)
      return response
    })
    .catch(err => {
      console.log(err.response)
      NotificationGenerator(err?.response?.data?.data?.message || 'NotDeleted', intl, true)
      return err
    })
}

export default {
  getAdministrators,
  getAdministratorById,
  createAdministrator,
  updateAdministrator,
  deleteAdminstrator,
}

export function WatchError(error) {
  let errorMessage = ''
  if (JSON.stringify(error).includes('J001')) errorMessage = 'J001'
  if (JSON.stringify(error).includes('J002')) errorMessage = 'J002'
  if (JSON.stringify(error).includes('J003')) errorMessage = 'J003'
  if (JSON.stringify(error).includes('J004')) errorMessage = 'J004'
  if (JSON.stringify(error).includes('J005')) errorMessage = 'J005'
  if (JSON.stringify(error).includes('J006')) errorMessage = 'J006'
  if (JSON.stringify(error).includes('J007')) errorMessage = 'J007'
  if (JSON.stringify(error).includes('J008')) errorMessage = 'J008'
  if (JSON.stringify(error).includes('J009')) errorMessage = 'J009'
  if (JSON.stringify(error).includes('J010')) errorMessage = 'J010'
  if (JSON.stringify(error).includes('J011')) errorMessage = 'J011'
  NotificationGenerator(`adminError${errorMessage}`, intl, true)
  return errorMessage
}
