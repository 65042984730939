import { createRequestTypes } from '../../utils'

const namespace = 'hierarchy'

export const GET_HIERARCHIES = createRequestTypes(`${namespace}/GET_HIERARCHIES`)

export const GET_HIERARCHIE_BY_ID = createRequestTypes(`${namespace}/GET_HIERARCHIE_BY_ID`)

export const CREATE_HIERARCHY = createRequestTypes(`${namespace}/CREATE_HIERARCHY`)

export const UPDATE_HIERARCHY = createRequestTypes(`${namespace}/UPDATE_HIERARCHY`)

export const DELETE_HIERARCHY = createRequestTypes(`${namespace}/DELETE_HIERARCHY`)
