import axios from 'axios'
import localstore from 'store'
import { notification } from 'antd'
import { restartAll } from 'redux-fichap/user/actions'
import getOr from 'lodash/fp/getOr'
import env from '@beam-australia/react-env'
import { logout } from 'services-fichap/login'
import { store as reduxStore } from '../index'

const api = env('API_BASE_URL')

const host = window.location.host
let baseURL = api
if (host === 'dashboard-dev.fichap.com') baseURL = 'https://api.fichap.com'
else if (host === 'dashboard-test.fichap.com') baseURL = 'https://api-test.fichap.com'
else if (host === 'dashboard.fichap.com') baseURL = 'https://api.fichap.com'

export const IS_PRODUCTION = baseURL === 'https://api.fichap.com' // Ver de hacer con los .env

// baseURL = 'https://api.fichap.com'
// baseURL = 'https://api.fichap.com'
// baseURL = 'https://api-test.fichap.com'
// baseURL = 'http://localhost'

export const BASE_URL = baseURL
export const API = baseURL

export const IS_TEST = baseURL === 'https://api-test.fichap.com'

const apiConnection = axios.create({ baseURL })

apiConnection.interceptors.request.use(req => {
  req.headers.SelectedLocale = localstore.get('app.settings.locale')
  const accessToken = localstore.get('accessToken')
  if (accessToken) {
    req.headers.Authorization = `Bearer ${accessToken}`
  }
  return req
})

apiConnection.interceptors.response.use(undefined, error => {
  // Errors handling
  const status = getOr(null, 'response.status', error)
  try {
    const { response } = error
    const { data } = response
    if (status === 400 && data.data?.err?.status === 409) {
      notification.error({ message: data.data.message })
    }
  } catch (err) {
    // console.error(err)
  }
  const { dispatch } = reduxStore
  if (status === 401) {
    logout()
    dispatch(restartAll())
  }
  return Promise.reject(error)
})

function request(method, url, params = {}, body = null, headers = null, options = {}) {
  const reqParams = {
    method,
    responseType: 'json',
    headers: headers || {
      Accept: 'application/json',
    },
    ...options,
  }

  const parsedParams = Object.keys(params)
    .map(p => `${p}=${params[p]}`)
    .join('&')

  if (method !== 'get' && method !== 'head' && body !== null) {
    if (!reqParams.headers['Content-Type']) {
      reqParams.headers['Content-Type'] = 'application/json'
    }
    reqParams.data = body
  }

  reqParams.url = parsedParams ? `${url}?${parsedParams}` : url

  return new Promise(async (resolve, reject) => {
    apiConnection(reqParams)
      .then(result => {
        return resolve(result ? result.data : null)
      })
      .catch(e => {
        return reject(e)
      })
  })
}

export function get(url, query, headers = null, options = {}) {
  return request('get', url, query, {}, headers, options)
}

export function post(url, query, body = {}, headers = null, options = {}) {
  return request('post', url, query, body, headers, options)
}

export function patch(url, query, body = {}, headers = null, options = {}) {
  return request('patch', url, query, body, headers, options)
}

export function del(url, query, body = {}, headers = null, options = {}) {
  return request('delete', url, {}, body, headers, options)
}

export function uploadFile(method, url, formData, config = {}) {
  return new Promise(async (resolve, reject) => {
    apiConnection[method](url, formData, config)
      .then(res => resolve(res))
      .catch(err => {
        console.error('error:', err)
        // if (err && !err.data?.err?.status)
        //   notification.error({ message: 'La imagen seleccionada no puede pesar más de 1mb' })
        reject(err)
      })
  })
}

export function download(method, url) {
  return new Promise(async (resolve, reject) => {
    apiConnection[method](url, {
      responseType: 'blob',
    })
      .then(result => {
        return resolve(result ? result.data : null)
      })
      .catch(e => {
        return reject(e)
      })
  })
}

export default apiConnection
