import {
  LOGIN,
  LOAD_CURRENT_ACCOUNT,
  LOGOUT,
  RESTART_STATE,
  MANTEINANCE,
  UPDATE_PROFILE_IMAGE,
  GET_ROLES_BY_VIEWS,
} from './types'

// const actions = {
//   SET_STATE: 'user/SET_STATE',
//   REGISTER: 'user/REGISTER',
//   LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
//   LOGOUT: 'user/LOGOUT',
// }

export const login = payload => ({
  type: LOGIN.REQUEST,
  payload,
})

export const getRolesView = () => ({
  type: GET_ROLES_BY_VIEWS.REQUEST,
})

export const logout = () => ({
  type: LOGOUT,
})

export const getUserLogin = () => ({
  type: LOAD_CURRENT_ACCOUNT.REQUEST,
})

export const restartAll = () => ({
  type: RESTART_STATE,
})

export const manteinance = payload => ({
  type: MANTEINANCE.REQUEST,
  payload,
})

export const updateProfileImage = payload => ({
  type: UPDATE_PROFILE_IMAGE.REQUEST,
  payload,
})

// export default actions
