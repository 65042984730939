import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import classNames from 'classnames'
import store from 'store'
import { COMPANIES } from 'entities/company'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ACTIONS } from 'contexts/auth/auth.consts'
import { getMenuData } from 'services-fichap/menu'
import { find } from 'lodash'
import logoOpen from 'assets/svg/fichap-logo.svg'
import byFichap from 'assets/svg/byFichap.svg'
import { useAuthContext } from '../../../../contexts/auth/auth.context'
import 'styles/base/tooltip.scss'
import style from '../styles/MenuLeft/style.module.scss'
import './MenuLeft.scss'
import IntegrationButtons from '../IntegrationButtons'
import { generateMenuItems } from './MenuItems'

const thislogo = (
  <div>
    <img id="logoclosed" width="120px" src={logoOpen} className="logo-closed mr-2" alt="Fichap" />
  </div>
)

const smalllogo = (
  <div>
    <img
      id="logoclosed"
      width="25px"
      src="resources/images/iso-fichap.svg"
      className="logo-closed mt-2 mr-2 ml-2"
      alt="Fichap"
    />
  </div>
)

const mapStateToProps = ({ settings, user }) => {
  return {
    isMenuCollapsed: settings.isMenuCollapsed,
    isMobileView: settings.isMobileView,
    isMenuUnfixed: settings.isMenuUnfixed,
    isMenuShadow: settings.isMenuShadow,
    leftMenuWidth: settings.leftMenuWidth,
    menuColor: settings.menuColor,
    logo: settings.logo,
    role: user.role,
    user,
  }
}

const MenuLeft = ({
  dispatch,
  location: { pathname },
  isMenuCollapsed,
  isMobileView,
  isMenuUnfixed,
  isMenuShadow,
  leftMenuWidth,
  menuColor,
  role,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])
  const { hasPermission, user } = useAuthContext()

  const company = store.get('COMPANY_DATA')
  const SportLineID = COMPANIES.SPORTLINE

  const menuData = useMemo(() => {
    const hasMenuPermission = (menuItem, oldValidation) => {
      // Para no romper lo previo
      if (oldValidation && oldValidation(true) === true) return oldValidation(menuItem)
      if (hasPermission(menuItem.module, ACTIONS.GET)) return menuItem
      return <></>
    }
    return getMenuData(hasMenuPermission)
  }, [hasPermission, user])

  useEffect(() => {
    localStorage.setItem('isMenuCollapsed', JSON.stringify(isMenuCollapsed))
  }, [isMenuCollapsed])

  useEffect(() => {
    try {
      applySelectedKeys()
    } catch (err) {
      console.error(err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
    setOpenedKeys([])
  }

  const onOpenChange = keys => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys)
  }

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }

  const menuSettings = isMobileView
    ? {
        width: leftMenuWidth,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: leftMenuWidth,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg',
      }

      const SportLineLogo = (
        <div className="d-flex">
          <img id="SportLineLogo" width="80px" src={company?.hugeLogoUrl} alt="logoSport" />
          <img
            id="byFichapLogo"
            width="120px"
            src={byFichap}
            className=""
            alt="byFichap"
            style={{ alignSelf: 'end', marginLeft: 16 }}
          />
        </div>
      )

  return (
    <Layout.Sider
      {...menuSettings}
      className={classNames(`${style.menu}`, {
        [style.white]: menuColor === 'white',
        [style.gray]: menuColor === 'gray',
        [style.dark]: menuColor === 'dark',
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
      })}
    >
      <div
        className={`${style.menuOuter} menuleft-dashboard`}
        style={{
          width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          height: isMobileView || isMenuUnfixed ? 'calc(100%)' : 'calc(100% - 110px)',
        }}
      >
        <Link to="/dashboard">
          <div className={company?._id === SportLineID ? style.logoSport : style.logoContainer}>
            <div className={style.logo}>
              {/*  <Logos /> {thislogo} */}
              {isMenuCollapsed ? (
                <div>{smalllogo}</div>
              ) : (
                <div> {company?._id === SportLineID ? <>{SportLineLogo}</> : <>{thislogo}</>} </div>
              )}
            </div>
          </div>
        </Link>
        {!isMenuCollapsed && <IntegrationButtons style={style} />}
        <PerfectScrollbar>
          <Menu
            id="closemenu"
            onClick={handleClick}
            selectedKeys={selectedKeys}
            defaultOpenKeys={['absences']}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className={style.navigation}
            inlineIndent="15"
          >
            {generateMenuItems({ isMenuCollapsed, menuData, role })}
          </Menu>
        </PerfectScrollbar>
      </div>
    </Layout.Sider>
  )
}

export default withRouter(connect(mapStateToProps)(MenuLeft))
