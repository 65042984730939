import { takeEvery, put, call } from 'redux-saga/effects'
import { history } from 'index'
import {
  GET_ADMINISTRATORS,
  GET_ADMINISTRATOR_BY_ID,
  DELETE_ADMINISTRATOR_BY_ID,
  UPDATE_ADMINISTRATOR,
  CREATE_ADMINISTRATOR,
  CLEAR_ERROR,
} from 'redux-fichap/administrators/types'
import NotificationGenerator from 'base/NotificationGenerator'
import api, { WatchError } from 'services-fichap/administrators/index'
import { intl } from '../../locales/IntlGlobalProvider'

function* getAdministratorsApiCall() {
  try {
    const response = yield call(api.getAdministrators)
    yield put({ type: GET_ADMINISTRATORS.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_ADMINISTRATORS.FAILURE, payload: error })
  }
}

function* getAdministratorByIdApiCall(action) {
  try {
    const response = yield call(api.getAdministratorById, action.payload)
    yield put({ type: GET_ADMINISTRATOR_BY_ID.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_ADMINISTRATOR_BY_ID.FAILURE, payload: error })
  }
}

//------------------------------------------------------

function* createAdministratorApiCall(action) {
  try {
    yield call(api.createAdministrator, action.payload)
    yield put({ type: CREATE_ADMINISTRATOR.SUCCESS })
  } catch (error) {
    console.error({ error })
    yield put({ type: CREATE_ADMINISTRATOR.FAILURE, payload: error })
  }
}

function* updateAdministratorApiCall(action) {
  try {
    yield call(api.updateAdministrator, action.payload)
    yield history.push('/user')
    yield put({ type: UPDATE_ADMINISTRATOR.SUCCESS })
    yield put({ type: GET_ADMINISTRATORS.REQUEST })
    yield put({ type: GET_ADMINISTRATOR_BY_ID.REQUEST, payload: action.payload._id })
    NotificationGenerator('AdminUpdated', intl, false)
  } catch (error) {
    WatchError(error.response?.data?.data?.message)
    // NotificationGenerator(error?.response?.data?.data?.message || "AnErrorHasOcurred", intl, true)
    yield put({ type: UPDATE_ADMINISTRATOR.FAILURE, payload: error })
  }
}

function* deleteAdministratorApiCall(action) {
  try {
    yield call(api.deleteAdminstrator, action.payload)
    yield put({ type: DELETE_ADMINISTRATOR_BY_ID.SUCCESS })
  } catch (error) {
    NotificationGenerator('adminNotDeleted', intl, true)
    yield put({ type: DELETE_ADMINISTRATOR_BY_ID.FAILURE, payload: error })
  }
}

function* clearError(action) {
  try {
    yield put({ type: CLEAR_ERROR.SUCCESS, payload: action.payload })
  } catch (error) {
    yield put({ type: CLEAR_ERROR.FAILURE, payload: error })
  }
}

function* administratorsIndexSaga() {
  yield takeEvery(GET_ADMINISTRATORS.REQUEST, getAdministratorsApiCall)
  yield takeEvery(GET_ADMINISTRATOR_BY_ID.REQUEST, getAdministratorByIdApiCall)
  yield takeEvery(CREATE_ADMINISTRATOR.REQUEST, createAdministratorApiCall)
  yield takeEvery(UPDATE_ADMINISTRATOR.REQUEST, updateAdministratorApiCall)
  yield takeEvery(DELETE_ADMINISTRATOR_BY_ID.REQUEST, deleteAdministratorApiCall)
  yield takeEvery(CLEAR_ERROR.REQUEST, clearError)
}

export default administratorsIndexSaga
