import { createRequestTypes } from '../../utils'

const namespace = 'inventory'

export const GET_INVENTORY = createRequestTypes(`${namespace}/GET_INVENTORY`)
export const CREATE_INVENTORY_ITEM = createRequestTypes(`${namespace}/CREATE_INVENTORY_ITEM`)
export const UPDATE_INVENTORY_ITEM = createRequestTypes(`${namespace}/UPDATE_INVENTORY_ITEM`)
export const DELETE_INVENTORY_ITEM = createRequestTypes(`${namespace}/DELETE_INVENTORY`)
export const FIND_INVENTORY_ITEM = createRequestTypes(`${namespace}/FIND_INVENTORY_ITEM`)

export const GET_INVENTORY_USER_LIST = createRequestTypes(`${namespace}/GET_INVENTORY_USER_LIST`)
export const CREATE_INVENTORY_USER = createRequestTypes(`${namespace}/CREATE_INVENTORY_USER`)
export const UPDATE_INVENTORY_USER = createRequestTypes(`${namespace}/UPDATE_INVENTORY_USER`)
export const DELETE_INVENTORY_USER = createRequestTypes(`${namespace}/DELETE_INVENTORY_USER`)
export const FIND_INVENTORY_USER = createRequestTypes(`${namespace}/FIND_INVENTORY_TYPE`)

export const GET_INVENTORY_TYPE_LIST = createRequestTypes(`${namespace}/GET_INVENTORY_TYPE_LIST`)
export const CREATE_INVENTORY_TYPE = createRequestTypes(`${namespace}/CREATE_INVENTORY_TYPE`)
export const UPDATE_INVENTORY_TYPE = createRequestTypes(`${namespace}/UPDATE_INVENTORY_TYPE`)
export const DELETE_INVENTORY_TYPE = createRequestTypes(`${namespace}/DELETE_INVENTORY_TYPE`)
export const FIND_INVENTORY_TYPE = createRequestTypes(`${namespace}/FIND_INVENTORY_TYPE`)
