import moment from 'moment'
import 'moment-duration-format'
import { replaceAll } from 'utils'

export default function parseHours(num, unit) {
  const value = moment.duration(num, unit).format('hh:mm', { trim: false })
  if (value.includes('nvalid')) return '00:00'
  return value
}

export const parseDuration = (number, unit = 'minutes') => {
  const parsed = moment.duration(replaceAll(number, ' ', ''), unit).format('hh:mm')
  if (number < 60) {
    if (number < 10) return `00:0${number}`
    return `00:${number}`
  }
  return parsed === '00' ? '00:00' : parsed
}

export const hoursMinutesToNumber = (string = '00:00') => {
  if (!string) string = '00:00:00'
  const [hours = 0, minutes = 0, seconds = 0] = string.split(':')
  return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds)
}
