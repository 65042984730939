/* eslint-disable import/no-named-as-default-member */
import store from 'store'
import { redirectLogin } from 'utils/auth'
import apiConnection, { post, get } from '../../api/api-connection'

const AppName = 'Dashboard'
export async function login(payload) {
  return post('/auth/v1/login', {}, payload)
  // .then(response => {
  //   if (response) {
  //     const { accessToken } = response.data
  //     const roles = ['admin']
  //     if (roles.includes('admin')) {
  //       if (accessToken) {
  //         store.set('accessToken', accessToken)
  //         store.set('email', email)
  //         return 'validated'
  //       }
  //       return 'noToken'
  //     }
  //     return 'notAdmin'
  //   }
  //   return false
  // })
  // .catch(err => console.error(err))
}

export async function register(email, password, firstName, lastName) {
  return apiConnection
    .post('/auth/register', {
      email,
      password,
      firstName,
      lastName,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.error(err))
}

export async function currentAccount() {
  const token = store.get('accessToken')
  if (token) {
    return apiConnection
      .get('/users/profile/get')
      .then(response => {
        if (response) {
          return response.data
        }
        return false
      })
      .catch(err => console.error(err))
  }
}

export async function manteinance() {
  return apiConnection
    .get('/auth/v1/maintenance')
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })
}

export async function logout() {
  store.remove('accessToken')
  const cachedDetailedColumns = store.get('detailedSelectedColumns')
  const cachedGeneralColumns = store.get('generalSelectedColumns')
  const remember = store.get('remember')
  const user = store.get('USER_DATA')
  store.clearAll()
  store.set('remember', remember)
  store.set('detailedSelectedColumns', cachedDetailedColumns)
  store.set('generalSelectedColumns', cachedGeneralColumns)
  store.set('USER_DATA', user)
  redirectLogin()
}

export async function resetPasswordEmail(email) {
  return apiConnection
    .post('/auth/reset-password', {
      email,
    })
    .then(response => {
      if (response) {
        return true
      }
      return false
    })
    .catch(err => console.error(err))
}

export async function changePassword(userId, password, resetToken) {
  store.set('accessToken', resetToken)

  return apiConnection
    .patch('/users/profile/change-password/', {
      password,
    })
    .then(response => {
      if (response) {
        store.remove('accessToken')
        return true
      }
      return false
    })
    .catch(err => console.error(err))
}

export async function allowChangePassword(userId, resetToken) {
  store.set('accessToken', resetToken)
  try {
    const response = await apiConnection.get(`/users/allowPasswordChange/${userId}`)
    if (response) {
      store.remove('accessToken')
      return response.data.data
    }
    return false
  } catch (err) {
    console.error(err)
  }
}

export async function rememberUsernameEmail(email) {
  return apiConnection
    .post('/auth/remember-username', {
      email,
    })
    .then(response => {
      if (response) {
        return true
      }
      return false
    })
    .catch(err => console.error(err))
}

export async function rolesView(app) {
  return apiConnection.get(`auth/v1/role/views/${app}`)
}
