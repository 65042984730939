export default class Arr {
  // Convierte un objeto multidimensional en un objeto de una sola dimensión en donde cada value es una cedana de texto separada por puntos.

  // Ejemplo:
  // let user = {
  //   address: {
  //     street: 'Av. Siempre Viva',
  //     number: 742
  //   },
  //   props: {
  //     weight: 108,
  //     height: 183,

  //   }
  // }

  // user = Arr.dot(user)
  // {
  //   'address.street': 'Av. Siempre Viva',
  //   'address.number': 742,
  //   'props.weight': 108,
  //   'props.height': 183,
  //   }
  // }

  static dot(nestedMessages, prefix = '') {
    return Object.keys(nestedMessages).reduce((messages, key) => {
      let value = nestedMessages[key]
      let prefixedKey = prefix ? `${prefix}.${key}` : key

      if (typeof value === 'string') {
        messages[prefixedKey] = value
      } else {
        Object.assign(messages, Arr.dot(value, prefixedKey))
      }

      return messages
    }, {})
  }

  static getData(target, path, fallback) {
    let segments = Array.isArray(path) ? path : path.split('.')
    let [segment] = segments

    let find = target

    if (segment !== '*' && segments.length > 0) {
      if (find[segment] === null || typeof find[segment] === 'undefined') {
        find = typeof fallback === 'function' ? fallback() : fallback
      } else {
        find = Arr.getData(find[segment], segments.slice(1), fallback)
      }
    } else if (segment === '*') {
      const partial = segments.slice(path.indexOf('*') + 1, path.length)

      if (typeof find === 'object') {
        find = Object.keys(find).reduce(
          (build, property) => ({
            ...build,
            [property]: Arr.getData(find[property], partial, fallback),
          }),
          {},
        )
      } else {
        find = Arr.getData(find, partial, fallback)
      }
    }

    if (typeof find === 'object') {
      if (Object.keys(find).length > 0) {
        const isArrayTransformable = Object.keys(find).every(index =>
          index.match(/^(0|[1-9][0-9]*)$/),
        )

        return isArrayTransformable ? Object.values(find) : find
      }
    } else {
      return find
    }
  }
}
