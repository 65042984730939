import React, { lazy, Suspense, useEffect, useMemo } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import Layout from 'components/fichap-admin/layout/layouts/index'
import {
  getRolesPermissions,
  getViewPermission,
  userIsAuthorized,
} from 'redux-fichap/user/selectors'
import { AuthProvider, useAuthContext } from 'contexts/auth/auth.context'
import NotificationGenerator from 'base/NotificationGenerator'
import { intl } from 'locales/IntlGlobalProvider'
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from 'react-dnd'
import { getAppPath } from './utils/paths'
import './styles/darkmode.css'

const routes = [
  // Dashboards
  {
    path: getAppPath('dashboard'),
    Component: lazy(() => import('fichap-pages/Dashboard')),
    exact: true,
    authorization: true,
    name: 'dashboard',
  },
  {
    path: getAppPath('team'),
    Component: lazy(() => import('fichap-pages/team')),
    exact: true,
    authorization: true,
    name: 'team',
  },
  {
    path: getAppPath('ReportsGenerate'),
    Component: lazy(() => import('fichap-pages/reportsGenerate')),
    exact: true,
    authorization: true,
    name: 'reportsGenerate',
  },
  {
    path: getAppPath('ViewReport'),
    Component: lazy(() => import('fichap-pages/reportsGenerate/report')),
    exact: true,
    authorization: true,
    name: 'ViewReport',
  },
  {
    path: getAppPath('team_casualtylist'),
    Component: lazy(() => import('fichap-pages/team/TeamMember/CasualtyList')),
    exact: true,
    authorization: true,
    name: 'teamCasualtylist',
  },
  {
    path: getAppPath('team_error_face_ID'),
    Component: lazy(() => import('fichap-pages/error-face/index')),
    exact: true,
    authorization: true,
    name: 'teamErrorFaceID',
  },
  {
    path: getAppPath('ReviewAndAapprovals'),
    Component: lazy(() => import('fichap-pages/Reviews-Approvals/index')),
    exact: true,
    authorization: true,
    name: 'ReviewAndAapprovals',
  },
  {
    path: getAppPath('team_heat_map'),
    Component: lazy(() => import('fichap-pages/heat-map/HeatMap.jsx')),
    exact: true,
    authorization: true,
    name: 'teamHeatMap',
  },
  {
    path: getAppPath('team_data_import'),
    Component: lazy(() => import('fichap-pages/team/TeamMember/TeamMemberImport/Data/Data.js')),
    exact: true,
    authorization: true,
    name: 'teamDataImport',
  },
  {
    path: getAppPath('companies'),
    Component: lazy(() => import('fichap-pages/companies/index')),
    exact: true,
    authorization: true,
    name: 'companies',
  },
  {
    path: getAppPath('employeeRecords'),
    Component: lazy(() => import('fichap-pages/employee-records/index')),
    exact: true,
    authorization: true,
    name: 'companies',
  },
  {
    path: getAppPath('companies_company_view'),
    Component: lazy(() => import('fichap-pages/companies/CompanyView/index.js')),
    exact: true,
    authorization: true,
    name: 'companiesCompanyView',
  },
  {
    path: getAppPath('user'),
    Component: lazy(() => import('fichap-pages/administrators/Admin')),
    exact: true,
    authorization: true,
    name: 'administrator',
  },
  {
    path: getAppPath('user_add'),
    Component: lazy(() => import('fichap-pages/administrators/NewAdmin')),
    exact: true,
    authorization: true,
    name: 'adminAdd',
  },
  {
    path: getAppPath('user_edit_id'),
    Component: lazy(() => import('fichap-pages/administrators/EditAdmin/EditAdministrator')),
    exact: true,
    authorization: true,
    name: 'adminEditId',
  },
  {
    path: getAppPath('team_shifts'),
    Component: lazy(() => import('fichap-pages/shifts/index')),
    exact: true,
    authorization: true,
    name: 'teamShifts',
  },
  {
    path: getAppPath('team_team'),
    Component: lazy(() =>
      import('fichap-pages/team/TeamMember/TeamMemberImport/Data/TeamList/Team.js'),
    ),
    authorization: true,
    exact: true,
    name: 'teamTeam',
  },
  {
    path: getAppPath('team_employee_record'),
    Component: lazy(() => import('fichap-pages/team/TeamMember/EmployeeRecord/Table/index.js')),
    exact: true,
    authorization: true,
    name: 'teamEmployeeRecord',
  },
  {
    path: getAppPath('team_daily_record'),
    Component: lazy(() =>
      import('fichap-pages/team/TeamMember/EmployeeRecord/DailyRecord/index.js'),
    ),
    exact: true,
    authorization: true,
    name: 'teamDailyRecord',
  },
  {
    path: getAppPath('team_pictures_import'),
    Component: lazy(() =>
      import('fichap-pages/team/TeamMember/TeamMemberImport/Pictures/PicTabs.js'),
    ),
    exact: true,
    authorization: true,
    name: 'teamPicturesImport',
  },
  {
    path: getAppPath('team_organization_chart'),
    Component: lazy(() => import('fichap-pages/organization-chart/OrganizationChart')),
    exact: true,
    authorization: true,
    name: 'teamOrganizationChart',
  },

  {
    path: getAppPath('profile'),
    Component: lazy(() => import('fichap-pages/profile/EditProfile')),
    exact: true,
    authorization: true,
    name: 'profile',
  },
  {
    path: getAppPath('profile_profileId'),
    Component: lazy(() => import('fichap-pages/team')),
    exact: true,
    authorization: true,
    name: 'profileProfileId',
  },
  {
    path: '/team/employee-record/:id',
    Component: lazy(() => import('fichap-pages/team/TeamMember/EmployeeRecord/Table/index.js')),
    exact: true,
    authorization: true,
    name: 'employeeRecordId',
  },
  {
    path: getAppPath('casualty_profileId'),
    Component: lazy(() => import('fichap-pages/team')),
    exact: true,
    authorization: true,
    name: 'profileProfileId',
  },
  {
    path: getAppPath('requests'),
    Component: lazy(() => import('fichap-pages/absences/AbsencesRequest')),
    exact: true,
    authorization: true,
    name: 'requests',
  },
  {
    path: getAppPath('reasons'),
    Component: lazy(() => import('fichap-pages/absences/AbsencesReasons')),
    exact: true,
    authorization: true,
    name: 'reasons',
  },
  {
    path: getAppPath('notifications'),
    Component: lazy(() => import('fichap-pages/notifications')),
    exact: true,
    authorization: true,
    name: 'notifications',
  },
  {
    path: getAppPath('levels'),
    Component: lazy(() => import('fichap-pages/levels')),
    exact: true,
    authorization: true,
    name: 'levels',
  },
  {
    path: getAppPath('settings'),
    Component: lazy(() => import('fichap-pages/settings/Settings')),
    exact: true,
    authorization: true,
    name: 'settings',
  },
  {
    path: getAppPath('settings/inventory'),
    Component: lazy(() => import('fichap-pages/settings/InventoryType')),
    exact: true,
    authorization: true,
    name: 'settings/inventory',
  },
  {
    path: getAppPath('company'),
    Component: lazy(() => import('fichap-pages/company')),
    exact: true,
    authorization: true,
    name: 'company',
  },
  {
    path: getAppPath('company_edit'),
    Component: lazy(() => import('fichap-pages/company/general-info/GeneralInfoEdit')),
    exact: true,
    authorization: true,
    name: 'companyEdit',
  },
  {
    path: getAppPath('payments'),
    Component: lazy(() => import('fichap-pages/company/payments/Payments')),
    exact: true,
    authorization: true,
    name: 'payments',
  },
  {
    path: getAppPath('payments_bill_id'),
    Component: lazy(() => import('fichap-pages/company/payments/Bill')),
    exact: true,
    name: 'paymentsBillId',
  },
  {
    path: getAppPath('specialdays'),
    Component: lazy(() => import('fichap-pages/company/special-days')),
    exact: true,
    authorization: true,
    name: 'specialdays',
  },
  {
    path: getAppPath('inventory'),
    Component: lazy(() => import('fichap-pages/company/inventory')),
    exact: true,
    authorization: true,
    name: 'inventory',
  },
  {
    path: getAppPath('shifts_reports'),
    Component: lazy(() => import('fichap-pages/reports/shifts-reports/ShiftsReports')),
    exact: true,
    authorization: true,
    name: 'shiftsReports',
  },
  {
    path: getAppPath('absences_reports'),
    Component: lazy(() => import('fichap-pages/reports/absences-reports/AbsencesReports')),
    exact: true,
    authorization: true,
    name: 'shiftsReports',
  },
  {
    path: getAppPath('nomine'),
    Component: lazy(() => import('fichap-pages/reports/nomine/Nomine')),
    exact: true,
    authorization: true,
    name: 'nomine',
  },
  {
    path: getAppPath('reports'),
    Component: lazy(() => import('fichap-pages/reports/Dashboard/index')),
    exact: true,
    authorization: true,
    name: 'reports',
  },
  {
    path: getAppPath('absences_reports'),
    Component: lazy(() => import('fichap-pages/reports/absences-reports/AbsencesReports')),
    exact: true,
    authorization: true,
    name: 'absencesReports',
  },
  {
    path: getAppPath('accidents_reports'),
    Component: lazy(() => import('fichap-pages/reports/InformesAccidentesLaborales')),
    exact: true,
    authorization: true,
    name: 'accidentsReports',
  },
  {
    path: getAppPath('error_reports'),
    Component: lazy(() => import('fichap-pages/reports/InformesErrorFichaje')),
    exact: true,
    authorization: true,
    name: 'errorReports',
  },
  {
    path: getAppPath('otsReport'),
    Component: lazy(() => import('fichap-pages/reports/ots-reports/Ots-Reports.jsx')),
    exact: true,
    authorization: true,
    name: 'otsReport',
  },
  {
    path: getAppPath('otsReportID'),
    Component: lazy(() => import('fichap-pages/reports/ots-reports/report/Report-OT.jsx')),
    exact: true,
    authorization: true,
    name: 'otsReportID',
  },
  // {
  // eliminar
  //   path: getAppPath('otsColabReport'),
  //   Component: lazy(() => import('fichap-pages/reports/ots-reports/Colab-Report/Colab-Report.jsx')),
  //   exact: true,
  //   authorization: true,
  //   name: 'otsColabReport',
  // },
  {
    // NUEVO PARA OTS
    path: getAppPath('colabDataOT'),
    Component: lazy(() =>
      import('fichap-pages/reports/ots-reports/Colabs/InfoColab/InfoColabOT.jsx'),
    ),
    exact: true,
    authorization: true,
    name: 'colabDataOT',
  },
  {
    // NUEVO PARA OTS
    path: getAppPath('workingInOT'),
    Component: lazy(() =>
      import('fichap-pages/reports/ots-reports/report/workOT/colabWorkingOT.jsx'),
    ),
    exact: true,
    authorization: true,
    name: 'workingInOT',
  },
  {
    path: getAppPath('colabsInfoOT'),
    Component: lazy(() =>
      import('fichap-pages/reports/ots-reports/Colabs/InfoColab/InfoColabOT.jsx'),
    ),
    exact: true,
    authorization: true,
    name: 'colabsInfoOT',
  },
  {
    path: getAppPath('news'),
    Component: lazy(() => import('fichap-pages/news/index')),
    exact: true,
    authorization: true,
    name: 'news',
  },
  {
    path: getAppPath('salary'),
    Component: lazy(() => import('fichap-pages/company/salary/index')),
    exact: true,
    authorization: true,
    name: 'billboard',
  },
  {
    path: getAppPath('billboard'),
    Component: lazy(() => import('fichap-pages/comunication/Billboard/Billboard')),
    exact: true,
  },
  {
    path: getAppPath('newPost'),
    Component: lazy(() => import('fichap-pages/comunication/posts/newPost/newPost')),
    exact: true,
  },
  {
    path: getAppPath('editPost'),
    Component: lazy(() => import('fichap-pages/comunication/posts/newPost/newPost')),
    exact: false,
  },
  {
    path: getAppPath('addFlyer'),
    Component: lazy(() => import('fichap-pages/comunication/flyers/newFlyer/newFlyer')),
    exact: true,
  },

  {
    path: getAppPath('editFlyer'),
    Component: lazy(() => import('fichap-pages/comunication/flyers/newFlyer/newFlyer')),
    exact: true,
  },
  {
    path: getAppPath('Flyer'),
    Component: lazy(() => import('fichap-pages/comunication/flyers/Flyer')),
    exact: true,
  },
  {
    path: getAppPath('Resources'),
    Component: lazy(() => import('fichap-pages/comunication/resources/index')),
    exact: true,
  },
  {
    path: getAppPath('AddResource'),
    Component: lazy(() => import('fichap-pages/comunication/resources/CreateResource')),
    exact: true,
  },
  {
    path: getAppPath('EditResource'),
    Component: lazy(() => import('fichap-pages/comunication/resources/CreateResource')),
    exact: false,
  },
  {
    path: getAppPath('categories'),
    Component: lazy(() => import('fichap-pages/comunication/categories/index')),
    exact: true,
  },
  {
    path: getAppPath('salaryBulkLoad'),
    Component: lazy(() => import('fichap-pages/company/salary/SettlementBulkLoad')),
    exact: true,
    authorization: true,
    name: 'billboard',
  },
  {
    path: getAppPath('salaryId'),
    Component: lazy(() => import('fichap-pages/company/salary/Settlement')),
    exact: true,
    authorization: true,
    name: 'salaryId',
  },
  {
    path: getAppPath('addHoliday'),
    Component: lazy(() => import('fichap-pages/absences/AbsencesRequest')),
    exact: true,
    authorization: true,
    name: 'salaryId',
  },
  {
    path: getAppPath('MyCalendar'),
    Component: lazy(() => import('fichap-pages/calendar/Calendar')),
    exact: true,
    authorization: true,
    name: 'salaryId',
  },
  // Auth Pages
  {
    path: getAppPath('SportLine'),
    Component: lazy(() => import('fichap-pages/auth/Login')),
    exact: true,
    authorization: false,
    name: 'SportLine',
  },
  {
    path: getAppPath('auth_login'),
    Component: lazy(() => import('fichap-pages/auth/Login')),
    exact: true,
    authorization: false,
    name: 'authLogin',
  },
  {
    path: getAppPath('auth_forgot_password'),
    Component: lazy(() => import('fichap-pages/auth/ForgotPassword')),
    exact: true,
    authorization: false,
    name: 'authForgotPassword',
  },
  {
    path: getAppPath('auth_change_password_userId_resetToken'),
    Component: lazy(() => import('fichap-pages/auth/ChangePassword')),
    exact: true,
    authorization: false,
    name: 'authChangePasswordUserIdResetToken',
  },
  {
    path: getAppPath('auth_forgot_username'),
    Component: lazy(() => import('fichap-pages/auth/ForgotUsername')),
    exact: true,
    authorization: false,
    name: 'authForgotUsername',
  },
  {
    path: getAppPath('auth_register'),
    Component: lazy(() => import('fichap-pages/auth/Register')),
    exact: true,
    authorization: false,
    name: 'authRegister',
  },
  {
    path: getAppPath('auth_lockscreen'),
    Component: lazy(() => import('fichap-pages/auth/Lockscreen')),
    exact: true,
    authorization: false,
    name: 'authLockscreen',
  },
  {
    path: getAppPath('auth_404'),
    Component: lazy(() => import('fichap-pages/auth/NotFound')),
    exact: true,
    authorization: false,
    name: 'auth_404',
  },
  {
    path: getAppPath('auth_500'),
    Component: lazy(() => import('fichap-pages/auth/InternalServerError')),
    exact: true,
    authorization: false,
    name: 'auth_500',
  },
  {
    path: getAppPath('redirect'),
    Component: <Redirect to="/team" />,
    exact: true,
    redirect: '/team',
  },
  //
]

const mapStateToProps = state => {
  const { settings } = state
  return {
    routerAnimation: settings.routerAnimation,
    roleViews: getViewPermission(state),
    authorization: userIsAuthorized(state),
    rolesPermission: getRolesPermissions(state),
  }
}

const Router = ({ history, routerAnimation, authorization, rolesPermission }) => {
  // provisoria
  const filterViews = [
    ...new Set(
      rolesPermission &&
        rolesPermission.data.map(p => p.views.Dashboard).flatMap(r => (r ? [...r] : [])),
    ),
  ]

  // Funcion de authorization y roles
  const route = routes.find(r => r.path === history)
  if (!route) history.push(getAppPath('auth_404'))
  if (history.location.pathname === '/' && !authorization) {
    history.push('/auth/login')
  } else {
    history.push('/team')
  }

  if (route?.authorization) {
    if (!authorization) {
      history.push(getAppPath('auth_login'))
      /* } else if (roleViews && !roleViews.includes(route.name)) { */
    } else if (filterViews && !filterViews.includes(route.name)) {
      history.goBack()
      NotificationGenerator(`loginErrorNotAdmin`, intl, true)
    }
  }

  const routesMap = useMemo(
    () =>
      routes.map(({ path, Component, exact, redirect = false }) => {
        if (redirect) {
          return (
            <Route path={path} key={path} exact={exact} render={() => <Redirect to={redirect} />} />
          )
        }

        return (
          <Route
            path={path}
            key={path}
            exact={exact}
            render={() => {
              return (
                <div className={routerAnimation}>
                  <Suspense fallback={null}>
                    <Component />
                  </Suspense>
                </div>
              )
            }}
          />
        )
      }),
    [],
  )

  const hostname = window.location.hostname
  useEffect(() => {
    if (hostname !== 'dashboard.fichap.com') {
      const bot = document.getElementById('hubspot-messages-iframe-container')
      if (bot) bot.remove()
    }
  }, [])

  const redirect = () => {
    const path = localStorage.getItem('path')
    if (path) {
      localStorage.removeItem('path')
      return <Redirect to={path} />
    }

    return <Redirect to="/dashboard" />
  }

  return (
    <ConnectedRouter history={history}>
      <>
        <AuthProvider>
          <Layout>
            <Route
              render={state => {
                const { location } = state

                return (
                  <SwitchTransition>
                    <CSSTransition
                      key={location.pathname}
                      appear
                      classNames={routerAnimation}
                      timeout={routerAnimation === 'none' ? 0 : 0}
                    >
                      <Switch location={location}>
                        <Route exact path="/" render={() => redirect()} />
                        {routesMap}
                        <Redirect to="/auth/404" />
                      </Switch>
                    </CSSTransition>
                  </SwitchTransition>
                )
              }}
            />
          </Layout>
        </AuthProvider>
      </>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(DragDropContext(HTML5Backend)(Router))
