import { RESTART_STATE } from 'redux-fichap/user/types'
import { parseActionType } from 'utils'
import {
  GET_ADMINISTRATORS,
  GET_ADMINISTRATOR_BY_ID,
  CREATE_ADMINISTRATOR,
  UPDATE_ADMINISTRATOR,
  DELETE_ADMINISTRATOR_BY_ID,
  CLEAR_ERROR,
} from './types'

const INITIAL_STATE = {
  error: null,
  loading: {},
}

export default (state = INITIAL_STATE, action) => {
  const actionType = parseActionType(action.type)

  switch (action.type) {
    case GET_ADMINISTRATORS.REQUEST:
    case GET_ADMINISTRATOR_BY_ID.REQUEST:
    case CREATE_ADMINISTRATOR.REQUEST:
    case UPDATE_ADMINISTRATOR.REQUEST:
    case DELETE_ADMINISTRATOR_BY_ID.REQUEST:
      return { ...state, loading: { [actionType]: true } }

    case GET_ADMINISTRATORS.SUCCESS:
      return { ...state, loading: { [actionType]: false }, administrators: action.payload }

    case GET_ADMINISTRATOR_BY_ID.SUCCESS:
      return { ...state, loading: { [actionType]: false }, administrator: action.payload }

    case CREATE_ADMINISTRATOR.SUCCESS:
    case UPDATE_ADMINISTRATOR.SUCCESS:
    case DELETE_ADMINISTRATOR_BY_ID.SUCCESS:
      return { ...state, loading: { [actionType]: false } }

    case GET_ADMINISTRATORS.FAILURE:
    case GET_ADMINISTRATOR_BY_ID.FAILURE:
    case CREATE_ADMINISTRATOR.FAILURE:
    case UPDATE_ADMINISTRATOR.FAILURE:
    case DELETE_ADMINISTRATOR_BY_ID.FAILURE:
      return { ...state, loading: { [actionType]: false }, error: action.payload || true }

    case CLEAR_ERROR.REQUEST:
      return { ...state, loading: { [actionType]: true } }
    case CLEAR_ERROR.SUCCESS:
      return { ...state, loading: { [actionType]: false }, error: null }
    case CLEAR_ERROR.FAILURE:
      return {
        ...state,
        loading: { [actionType]: false },
        error: action.payload,
      }

    case RESTART_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
