import { createRequestTypes } from '../../utils'

const namespace = 'brancoffices'

export const GET_BRANCH_OFFICES = createRequestTypes(`${namespace}/GET_BRANCH_OFFICES`)

export const GET_BRANCH_OFFICES_TREE = createRequestTypes(`${namespace}/GET_BRANCH_OFFICES_TREE`)

export const CREATE_BRANCH_OFFICE = createRequestTypes(`${namespace}/CREATE_BRANCH_OFFICE`)

export const GET_BRANCH_OFFICE_BY_ID = createRequestTypes(`${namespace}/GET_BRANCH_OFFICE_BY_ID`)

export const UPDATE_BRANCH_OFFICES = createRequestTypes(`${namespace}/UPDATE_BRANCH_OFFICES`)

export const DELETE_BRANCH_OFFICES = createRequestTypes(`${namespace}/DELETE_BRANCH_OFFICES`)
