import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useReFetch } from 'api/useFetch'
import { hasNewRoles } from 'utils/userRoles'
import { useLocation } from 'react-router-dom'

const AuthContext = createContext({
  setIsLoading: () => {},
  isLoading: false,
  hasPermission: () => {
    return true
  },
})

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { pathname } = useLocation()

  // TODO: Eliminar de sagas el mismo llamado
  const {
    data: { data: user },
    refetch: refetchUser,
  } = useReFetch({ url: '/users/profile/get', method: 'get', default: { data: null } }, [])

  const roles = user?.roles && encodeURIComponent(user.roles)

  const {
    data: { data: selfPermissions },
    refetch,
  } = useReFetch(
    {
      default: { data: null },
      verificate: () => roles && user?.companyId,
      url: `/role-permission-service/role/self/permissions?roles=${roles}&companyId=${user?.companyId}`,
      method: 'get',
      reset: true,
    },
    [user],
  )

  useEffect(() => {
    // HOTFIX, eliminar!! es para recargar los datos una vez
    if (pathname === '/') {
      refetch()
      refetchUser()
    }
  }, [pathname])

  const hasPermission = useCallback(
    (moduleKey, action = 'GET', oldValidation = false) => {
      // Esto es para no romper lo previo
      if (user?.roles?.includes('owner')) return true
      if (!hasNewRoles(user?.roles) && oldValidation) {
        return oldValidation(true) === true
      }
      if (oldValidation && oldValidation(true) === true) return true
      // Termina el codigo para no romper lo previo

      if (selfPermissions) {
        try {
          const hasPermission = selfPermissions.find(p => {
            return p.module.key === moduleKey && p.action === action
          })
          return hasPermission
        } catch (error) {
          console.error(`error[hasPermission]: `, error.message, error.stack)
        }
      }
      return false
    },
    [selfPermissions, user],
  )

  const context = {
    setIsLoading,
    isLoading,
    // selfPermissions,
    hasPermission,
  }

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => useContext(AuthContext)
