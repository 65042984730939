import { GET_TASKS } from './types'

const stateDefault = {
  data: null,
  error: null,
  loading: null,
}

export default (state = stateDefault, action) => {
  switch (action.type) {
    case GET_TASKS.SUCCESS:
      return {
        ...stateDefault,
        data: action.message.data,
      }

    case `${GET_TASKS.REQUEST}_START`:
      return {
        ...stateDefault,
        loading: true,
      }

    case `${GET_TASKS.FAILURE}_ERROR`:
      return {
        ...stateDefault,
        error: true,
      }

    default:
      break
  }

  return state
}
