import {
  GET_COMPANY_DATA,
  GET_COMPANY_PAYMENTS,
  GET_COMPANY_PAYMENT_BY_ID,
  GET_COMPANY_PLAN,
  GET_SPECIAL_DAYS,
  UPDATE_COMPANY_DATA,
  UPDATE_SPECIAL_DAYS,
  DELETE_SPECIAL_DAYS,
  CREATE_SPECIAL_DAYS,
} from './types'

export const getCompanyData = payload => ({
  type: GET_COMPANY_DATA.REQUEST,
  payload,
})

export const getCompanyPayments = payload => ({
  type: GET_COMPANY_PAYMENTS.REQUEST,
  payload,
})

export const getCompanyPaymentById = payload => ({
  type: GET_COMPANY_PAYMENT_BY_ID.REQUEST,
  payload,
})

export const getCompanyPlan = payload => ({
  type: GET_COMPANY_PLAN.REQUEST,
  payload,
})

export const getSpecialDays = payload => ({
  type: GET_SPECIAL_DAYS.REQUEST,
  payload,
})

export const updateCompanyData = payload => ({
  type: UPDATE_COMPANY_DATA.REQUEST,
  payload,
})

export const updateSpecialDays = payload => ({
  type: UPDATE_SPECIAL_DAYS.REQUEST,
  payload,
})

export const deleteSpecialDays = payload => ({
  type: DELETE_SPECIAL_DAYS.REQUEST,
  payload,
})

export const createSpecialDays = payload => ({
  type: CREATE_SPECIAL_DAYS.REQUEST,
  payload,
})
