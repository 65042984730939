import { createRequestTypes } from '../../utils'

const namespace = 'notifications'

export const GET_NOTIFICATIONS = createRequestTypes(`${namespace}/GET_NOTIFICATIONS`)

export const GET_NOTIFICATION_BY_ID = createRequestTypes(`${namespace}/GET_NOTIFICATION_BY_ID`)

export const UPDATE_NOTIFICATION = createRequestTypes(`${namespace}/UPDATE_NOTIFICATION`)

export const CREATE_NOTIFICATION = createRequestTypes(`${namespace}/CREATE_NOTIFICATION`)

export const DELETE_NOTIFICATION = createRequestTypes(`${namespace}/DELETE_NOTIFICATION`)
