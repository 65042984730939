export default {
  specialDays: {
    labels: {
      dateFrom: 'Date',
      dateTo: '', // TODO: sacar
      isRecurring: 'Repeat every year', // TODO: sacar
      name: 'Name',
      category: 'Category',
      countries: 'Countries',
      branches: 'Branches',
      description: 'Description',
      isDeductedFromVacation: 'Is deducted from vacations',
      impactedPayroll: 'Impact on payroll',
    },
    placeholders: {
      dateFrom: 'From',
      dateTo: 'To',
      isRecurring: 'Repeat every year', // TODO: sacar
      name: 'Special Day',
      category: 'Select category',
      countries: 'Select countries',
      branches: 'Select branches',
      description: 'Text here', // TODO: dejar por defecto usando Arr::getData()
      isDeductedFromVacation: 'Is deducted from vacations',
      impactedPayroll: 'Impact on payroll',
    },
  },
}
