import { RESTART_STATE } from 'redux-fichap/user/types'
import { parseActionType } from 'utils'
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_BY_ID,
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from './types'

const INITIAL_STATE = {
  error: null,
  loading: {},
}

export default (state = INITIAL_STATE, action) => {
  const actionType = parseActionType(action.type)

  switch (action.type) {
    case GET_NOTIFICATIONS.REQUEST:
    case DELETE_NOTIFICATION.REQUEST:
    case GET_NOTIFICATION_BY_ID.REQUEST:
    case CREATE_NOTIFICATION.REQUEST:
    case UPDATE_NOTIFICATION.REQUEST:
      return { ...state, loading: { [actionType]: true } }

    case GET_NOTIFICATIONS.SUCCESS:
      return { ...state, loading: { [actionType]: false }, notifications: action.payload }

    case GET_NOTIFICATION_BY_ID.SUCCESS:
      return { ...state, loading: { [actionType]: false }, notification: action.payload }

    case DELETE_NOTIFICATION.SUCCESS:
    case CREATE_NOTIFICATION.SUCCESS:
    case UPDATE_NOTIFICATION.SUCCESS:
      return { ...state, loading: { [actionType]: false } }

    case GET_NOTIFICATIONS.FAILURE:
    case GET_NOTIFICATION_BY_ID.FAILURE:
    case DELETE_NOTIFICATION.FAILURE:
    case CREATE_NOTIFICATION.FAILURE:
    case UPDATE_NOTIFICATION.FAILURE:
      return { ...state, loading: { [actionType]: false }, error: action.payload || true }
    case RESTART_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
