import React from 'react'
import store from 'store'

export const OWNER_ROLE = 'owner'
export const ADMIN_ROLE = 'administrator'
export const MANAGER_ROLE = 'manager'
export const VIEWER_ROLE = 'viewer'
export const FICHAP_ROLE = 'fichap'
export const USER_ROLE = 'user'

export const hasNewRoles = roles => {
  // Verifica los roles en el arreglo está incluido en DEFAULT_ROLES
  const DEFAULT_ROLES = [OWNER_ROLE, ADMIN_ROLE, MANAGER_ROLE, VIEWER_ROLE, FICHAP_ROLE, USER_ROLE]
  if (!Array.isArray(roles)) {
    return !DEFAULT_ROLES.includes(roles)
  }
  return roles.some(role => !DEFAULT_ROLES.includes(role))
}

export const onlyViewer = children => {
  const userRoles = store.get('allowedRoles')

  if (
    userRoles?.includes('viewer') ||
    userRoles?.includes('manager') ||
    userRoles?.includes('administrator') ||
    userRoles?.includes('owner') ||
    userRoles?.includes('fichap')
  ) {
    return children
  } else {
    return <></>
  }
}

export const onlyManager = children => {
  const userRoles = store.get('allowedRoles')

  if (
    userRoles?.includes('manager') ||
    userRoles?.includes('administrator') ||
    userRoles?.includes('owner') ||
    userRoles?.includes('fichap')
  ) {
    return children
  } else {
    return <></>
  }
}

export const onlyManagerAndOwner = children => {
  const userRoles = store.get('allowedRoles')

  if (userRoles?.includes('manager') || userRoles?.includes('owner')) {
    return children
  } else {
    return <></>
  }
}

export const onlyAdmin = children => {
  const userRoles = store.get('allowedRoles')

  if (
    userRoles?.includes('administrator') ||
    userRoles?.includes('owner') ||
    userRoles?.includes('fichap')
  ) {
    return children
  } else {
    return <></>
  }
}

export const onlyOwner = children => {
  const userRoles = store.get('allowedRoles')
  if (userRoles?.includes('owner') || userRoles?.includes('fichap')) {
    return children
  } else {
    return <></>
  }
}

export const isAdmin = onlyAdmin(true) === true

export const isOwner = onlyOwner(true) === true

export const isManager = onlyManager(true) === true
