/* eslint-disable no-undef */
import api from 'services-fichap/fileSystem'
import { takeEvery, put, call } from 'redux-saga/effects'
import {
  GET_MEDIA,
  GET_FOLDER,
  GET_FILESYSTEM,
  COPY,
  MOVE,
  DELETE,
  UPLOAD,
  CREATE_FOLDER,
  UPDATE_FOLDER,
  UPDATE_FILE,
} from './types'

function* getMediaApiCall(action) {
  try {
    const response = yield call(api.getMedia, action.payload)
    yield put({ type: GET_MEDIA.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_MEDIA.FAILURE, payload: error })
  }
}
function* getFolderApiCall(action) {
  try {
    const response = yield call(api.getFolder, action.payload)
    yield put({ type: GET_FOLDER.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_FOLDER.FAILURE, payload: error })
  }
}
function* getFilesystemApiCall(action) {
  try {
    const response = yield call(api.getFileSystem, action.payload)

    if (response) yield put({ type: GET_FILESYSTEM.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_FILESYSTEM.FAILURE, payload: error })
  }
}
function* copyApiCall(action) {
  try {
    const response = yield call(api.copy, action.payload)
    yield put({ type: COPY.SUCCESS, payload: response })
    yield put({
      type: GET_FILESYSTEM.REQUEST,
      payload: { companyId: action.payload.companyId, userId: action.payload.userId },
    })
  } catch (error) {
    yield put({ type: COPY.FAILURE, payload: error })
  }
}
function* moveApiCall(action) {
  try {
    const response = yield call(api.move, action.payload)
    yield put({ type: MOVE.SUCCESS, payload: response })
    yield put({
      type: GET_FILESYSTEM.REQUEST,
      payload: { companyId: action.payload.companyId, userId: action.payload.userId },
    })
  } catch (error) {
    yield put({ type: MOVE.FAILURE, payload: error })
  }
}
function* deleteApiCall(action) {
  try {
    const response = yield call(api.delete, action.payload)
    yield put({
      type: DELETE.SUCCESS,
      payload: { isFolder: action.payload.isFolder, id: action.payload.id },
    })
    yield put({
      type: GET_FILESYSTEM.REQUEST,
      payload: {
        companyId: action.payload.companyId,
        userId: action.payload.userId,
        folderId: action.payload.folderId,
      },
    })
  } catch (error) {
    yield put({ type: DELETE.FAILURE, payload: error })
  }
}
function* uploadApiCall(action) {
  try {
    const response = yield call(api.upload, action.payload)
    yield put({ type: UPLOAD.SUCCESS, payload: response })
    yield put({
      type: GET_FILESYSTEM.REQUEST,
      payload: { companyId: action.payload.get('companyId'), userId: action.payload.get('userId') },
    })
  } catch (error) {
    yield put({ type: UPLOAD.FAILURE, payload: error })
  }
}
function* createFolderApiCall(action) {
  try {
    const response = yield call(api.createFolder, action.payload)
    yield put({ type: CREATE_FOLDER.SUCCESS, payload: response })
    yield put({
      type: GET_FILESYSTEM.REQUEST,
      payload: {
        companyId: action.payload.companyId,
        userId: action.payload.userId,
        folderId: action.payload.parentId,
      },
    })
  } catch (error) {
    yield put({ type: CREATE_FOLDER.FAILURE, payload: error })
  }
}
function* updateFolderApiCall(action) {
  try {
    const response = yield call(api.updateFolder, action.payload)
    yield put({ type: UPDATE_FOLDER.SUCCESS, payload: response })
    yield put({
      type: GET_FILESYSTEM.REQUEST,
      payload: {
        companyId: action.payload.companyId,
        userId: action.payload.userId,
        folderId: action.payload.parentId,
      },
    })
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_FOLDER.FAILURE, payload: error })
  }
}
function* updateFileApiCall(action) {
  try {
    const response = yield call(api.updateFile, action.payload)
    yield put({ type: UPDATE_FILE.SUCCESS, payload: response })
    yield put({
      type: GET_FILESYSTEM.REQUEST,
      payload: {
        companyId: action.payload.companyId,
        userId: action.payload.userId,
        folderId: action.payload.parentId,
      },
    })
  } catch (error) {
    yield put({ type: UPDATE_FILE.FAILURE, payload: error })
  }
}

function* IndexSaga() {
  yield takeEvery(GET_MEDIA.REQUEST, getMediaApiCall)
  yield takeEvery(GET_FOLDER.REQUEST, getFolderApiCall)
  yield takeEvery(GET_FILESYSTEM.REQUEST, getFilesystemApiCall)
  yield takeEvery(COPY.REQUEST, copyApiCall)
  yield takeEvery(MOVE.REQUEST, moveApiCall)
  yield takeEvery(DELETE.REQUEST, deleteApiCall)
  yield takeEvery(UPLOAD.REQUEST, uploadApiCall)
  yield takeEvery(CREATE_FOLDER.REQUEST, createFolderApiCall)
  yield takeEvery(UPDATE_FOLDER.REQUEST, updateFolderApiCall)
  yield takeEvery(UPDATE_FILE.REQUEST, updateFileApiCall)
}

export default IndexSaga
