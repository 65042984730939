/* eslint-disable import/prefer-default-export */
import {
  CREATE_EMPLOYEE,
  ENABLED_DISABLED_EMPLOYEE,
  GET_EMPLOYEES_BY_COMPANY_ID,
  GET_EMPLOYEE_BY_ID,
  SELECTED_EMPLOYEE,
  UPDATE_EMPLOYEE,
  GET_CALENDAR_BY_ID,
  UPDATE_CALENDAR,
  PUSH_CALENDAR_UPDATE,
  CLEAR_ERROR_CODE,
} from './types'

export const getEmployeesByCompanyId = payload => ({
  type: GET_EMPLOYEES_BY_COMPANY_ID.REQUEST,
  payload,
})

export const createEmployee = payload => ({
  type: CREATE_EMPLOYEE.REQUEST,
  payload,
})

export const enabledDisabledEmployee = payload => ({
  type: ENABLED_DISABLED_EMPLOYEE.REQUEST,
  payload,
})

export const getEmployeeById = payload => ({
  type: GET_EMPLOYEE_BY_ID.REQUEST,
  payload,
})

export const updateEmployee = payload => ({
  type: UPDATE_EMPLOYEE.REQUEST,
  payload,
})

export const selectedEmployee = payload => ({
  type: SELECTED_EMPLOYEE, // .REQUEST (?
  payload,
})

export const getCalendarById = payload => ({
  type: GET_CALENDAR_BY_ID.REQUEST,
  payload,
})

export const pushCalendarUpdate = payload => ({
  type: PUSH_CALENDAR_UPDATE.REQUEST,
  payload,
})

export const updateCalendar = payload => ({
  type: UPDATE_CALENDAR.REQUEST,
  payload,
})

export const clearErrorCode = payload => ({
  type: CLEAR_ERROR_CODE.REQUEST,
  payload,
})
