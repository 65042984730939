import moment from 'moment'

const year = moment().year()

export const months = [
  { month: 'Enero', days: moment(`${year}-01-01`).daysInMonth() },
  { month: 'Febrero', days: moment(`${year}-02-01`).daysInMonth() },
  { month: 'Marzo', days: moment(`${year}-03-01`).daysInMonth() },
  { month: 'Abril', days: moment(`${year}-04-01`).daysInMonth() },
  { month: 'Mayo', days: moment(`${year}-05-01`).daysInMonth() },
  { month: 'Junio', days: moment(`${year}-06-01`).daysInMonth() },
  { month: 'Julio', days: moment(`${year}-07-01`).daysInMonth() },
  { month: 'Agosto', days: moment(`${year}-08-01`).daysInMonth() },
  { month: 'Septiembre', days: moment(`${year}-09-01`).daysInMonth() },
  { month: 'Octubre', days: moment(`${year}-10-01`).daysInMonth() },
  { month: 'Noviembre', days: moment(`${year}-11-01`).daysInMonth() },
  { month: 'Diciembre', days: moment(`${year}-12-01`).daysInMonth() },
]
