import { createRequestTypes } from '../../utils'

const namespace = 'config'

// GENERAL VIEW
const GET_GENERAL_CONFIG = createRequestTypes(`${namespace}/GET_GENERAL_CONFIG`)

const UPDATE_GENERAL_CONFIG = createRequestTypes(`${namespace}/UPDATE_GENERAL_CONFIG`)

// FOLDERS VIEW
const GET_FOLDER = createRequestTypes(`${namespace}/GET_FOLDER`)

const CREATE_FOLDER = createRequestTypes(`${namespace}/CREATE_FOLDER`)

const UPDATE_FOLDER_BY_ID = createRequestTypes(`${namespace}/UPDATE_FOLDER_BY_ID`)

const DELETE_FOLDER_BY_ID = createRequestTypes(`${namespace}/DELETE_FOLDER_BY_ID`)

// CUTOMIZABLE DATA

const GET_CUSTOM_DATA = createRequestTypes(`${namespace}/GET_CUSTOM_DATA`)
const UPDATE_CUSTOM_DATA = createRequestTypes(`${namespace}/UPDATE_CUSTOM_DATA`)
const GET_CUSTOM_DATA_BY_NAME = createRequestTypes(`${namespace}/GET_CUSTOM_DATA_BY_NAME`)

// WORKDAY

const GET_WORKDAY = createRequestTypes(`${namespace}/GET_WORKDAY`)

const CREATE_WORKDAY = createRequestTypes(`${namespace}/CREATE_WORKDAY`)

const UPDATE_WORKDAY_BY_ID = createRequestTypes(`${namespace}/UPDATE_WORKDAY_BY_ID`)

const DELETE_WORKDAY_BY_ID = createRequestTypes(`${namespace}/DELETE_WORKDAY_BY_ID`)

// NOTIFICATIONS

const GET_NOTIFICATIONS = createRequestTypes(`${namespace}/GET_NOTIFICATIONS`)

const UPDATE_NOTIFICATIONS = createRequestTypes(`${namespace}/UPDATE_NOTIFICATIONS`)

// COVID
const GET_COVID = createRequestTypes(`${namespace}/GET_COVID`)

const UPDATE_COVID = createRequestTypes(`${namespace}/UPDATE_COVID`)

export {
  GET_COVID,
  GET_GENERAL_CONFIG,
  GET_CUSTOM_DATA,
  GET_CUSTOM_DATA_BY_NAME,
  GET_FOLDER,
  GET_WORKDAY,
  GET_NOTIFICATIONS,
  UPDATE_COVID,
  UPDATE_FOLDER_BY_ID,
  UPDATE_GENERAL_CONFIG,
  UPDATE_NOTIFICATIONS,
  UPDATE_WORKDAY_BY_ID,
  DELETE_FOLDER_BY_ID,
  DELETE_WORKDAY_BY_ID,
  UPDATE_CUSTOM_DATA,
  CREATE_WORKDAY,
  CREATE_FOLDER,
}
