import {
  GET_BRANCH_OFFICES,
  DELETE_BRANCH_OFFICES,
  GET_BRANCH_OFFICE_BY_ID,
  UPDATE_BRANCH_OFFICES,
  CREATE_BRANCH_OFFICE,
  GET_BRANCH_OFFICES_TREE,
} from './types'

export const getBranchOffices = payload => ({
  type: GET_BRANCH_OFFICES.REQUEST,
  payload,
})

export const getBranchOfficesTree = payload => ({
  type: GET_BRANCH_OFFICES_TREE.REQUEST,
  payload,
})

export const getBranchOfficeById = payload => ({
  type: GET_BRANCH_OFFICE_BY_ID.REQUEST,
  payload,
})

export const deleteBranchOffices = payload => ({
  type: DELETE_BRANCH_OFFICES.REQUEST,
  payload,
})

export const updateBranchOffice = payload => ({
  type: UPDATE_BRANCH_OFFICES.REQUEST,
  payload,
})

export const createBranchOffice = payload => ({
  type: CREATE_BRANCH_OFFICE.REQUEST,
  payload,
})
