/* eslint-disable no-case-declarations */
import { parseActionType } from 'utils'
import store from 'store'
import { RESTART_STATE } from 'redux-fichap/user/types'
import getOr from 'lodash/fp/getOr'
import {
  CREATE_EMPLOYEE,
  ENABLED_DISABLED_EMPLOYEE,
  GET_EMPLOYEES_BY_COMPANY_ID,
  SELECTED_EMPLOYEE,
  UPDATE_EMPLOYEE,
  GET_EMPLOYEE_BY_ID,
  GET_CALENDAR_BY_ID,
  PUSH_CALENDAR_UPDATE,
  UPDATE_CALENDAR,
  CLEAR_ERROR_CODE,
} from './types'

const initialState = {
  employeeProfileId: store.get('employeeIdSelected') || null, // default is null
  employeeProfileData: [],
  employee: null,
  loading: {},
  count: 0,
  error: null,
  restart: false,
  errorCode: null,
}

export default function teamReducer(state = initialState, action) {
  const actionType = parseActionType(action.type)
  switch (action.type) {
    case SELECTED_EMPLOYEE:
      store.set('employeeIdSelected', action.payload)
      return { ...state, employeeProfileId: action.payload, error: null }

    // REQUESTS ----------------------
    case GET_EMPLOYEES_BY_COMPANY_ID.REQUEST:
    case CREATE_EMPLOYEE.REQUEST:
    case ENABLED_DISABLED_EMPLOYEE.REQUEST:
    case UPDATE_EMPLOYEE.REQUEST:
    case GET_EMPLOYEE_BY_ID.REQUEST:
    case GET_CALENDAR_BY_ID.REQUEST:
    case UPDATE_CALENDAR.REQUEST:
    case PUSH_CALENDAR_UPDATE.REQUEST:
      return { ...state, loading: { [actionType]: true } }
    // SUCCESS ------------------------
    case GET_EMPLOYEES_BY_COMPANY_ID.SUCCESS:
      const res = {
        ...state,
        loading: { [actionType]: false },
        count: action.payload.count,
      }
      if (action.payload.restart) {
        res.employeeProfileData = action.payload.users
      } else {
        res.employeeProfileData = [...state.employeeProfileData, ...action.payload.users]
      }
      return res
    case GET_EMPLOYEE_BY_ID.SUCCESS:
      return { ...state, loading: { [actionType]: false }, employee: action.payload }
    case CREATE_EMPLOYEE.SUCCESS:
      return { ...state, loading: { [actionType]: false } }
    case ENABLED_DISABLED_EMPLOYEE.SUCCESS:
    case UPDATE_EMPLOYEE.SUCCESS:
    case PUSH_CALENDAR_UPDATE.SUCCESS:
    case UPDATE_CALENDAR.SUCCESS:
      return { ...state, loading: { [actionType]: false } }

    case GET_CALENDAR_BY_ID.SUCCESS:
      return { ...state, loading: { [actionType]: false }, calendar: action.payload }
    // FAILURE ------------------------
    case GET_EMPLOYEES_BY_COMPANY_ID.FAILURE:
    case GET_EMPLOYEE_BY_ID.FAILURE:
    case ENABLED_DISABLED_EMPLOYEE.FAILURE:
    case UPDATE_CALENDAR.FAILURE:
    case UPDATE_EMPLOYEE.FAILURE:
    case GET_CALENDAR_BY_ID.FAILURE:
      return {
        ...state,
        loading: { [actionType]: false },
        error: action.payload,
        errorCode: getOr('Not errorCode', 'payload.response.data.data.message', action),
      }
    case CREATE_EMPLOYEE.FAILURE:
      return {
        ...state,
        loading: { [actionType]: false },
        error: action.payload,
        errorCode: action.payload.response.data.data.message,
      }
    case CLEAR_ERROR_CODE.REQUEST:
      return { ...state, loading: { [actionType]: true } }
    case CLEAR_ERROR_CODE.SUCCESS:
      return { ...state, loading: { [actionType]: false }, errorCode: null }
    case CLEAR_ERROR_CODE.FAILURE:
      return {
        ...state,
        loading: { [actionType]: false },
        error: action.payload,
      }
    case RESTART_STATE:
      return initialState
    default:
      return state
  }
}
