/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { useReFetch } from 'api/useFetch'
import { currentUser } from 'entities/user'

const LookerStudioButton = () => {
  const { data: userChildren } = useReFetch(
    {
      url: `/companies/positions/tree/children/${currentUser._id}`,
      method: 'get',
    },
    [],
  )

  if (
    !userChildren?.length ||
    currentUser?.contractData?.company?.toLocaleLowerCase()?.includes('southbay')
  )
    return null

  return (
    <a
      style={{
        display: 'flex',
      }}
      className="LookerStudioButton NavbarButton"
      href="https://lookerstudio.google.com/reporting/7d798d82-861f-42b3-8a65-8697b875573d"
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        width="18"
        height="18"
        style={{ marginRight: 9 }}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_30839_18236)">
          <path
            d="M1.25 16.875H7.1875C7.99099 16.875 8.77643 16.6367 9.4445 16.1903C10.1126 15.744 10.6333 15.1095 10.9408 14.3672C11.2482 13.6248 11.3287 12.808 11.1719 12.0199C11.0152 11.2319 10.6283 10.508 10.0601 9.93988C9.49197 9.37173 8.7681 8.98481 7.98005 8.82806C7.19201 8.67131 6.37517 8.75176 5.63285 9.05924C4.89052 9.36672 4.25605 9.88742 3.80965 10.5555C3.36326 11.2236 3.125 12.009 3.125 12.8125C3.125 15.625 1.25 16.875 1.25 16.875Z"
            stroke="#FF5630"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.78198 9.075C10.2812 7.05391 14.0203 2.5 17.5 2.5C17.5 5.97969 12.946 9.71875 10.925 11.218"
            stroke="#FF5630"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.3906 7.08124C11.4521 7.67549 12.3284 8.55183 12.9227 9.61327"
            stroke="#FF5630"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_30839_18236">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <span style={{ color: '#FF5630' }}> Color de talento </span>
    </a>
  )
}

export default LookerStudioButton
