/* eslint-disable jsx-a11y/alt-text */
import { Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import './style.scss'

const ImageProgressiveLoading = ({ url, width, height, className, alt, user, borderSize }) => {
  const [loaded, setLoaded] = useState(false)
  // const [source, setSource] = useState(url)
  const imgRef = useRef()
  useEffect(() => {
    try {
      if (imgRef.current && imgRef.current.complete) {
        setLoaded(true)
      }
    } catch (err) {
      console.error(err)
    }
  }, [])

  return (
    <div className={`relative ${className}`}>
      <div className="loading">{!loaded ? <Spin /> : ''}</div>
      <img
        src={url}
        loading="lazy"
        ref={imgRef}
        height={height}
        width={width}
        alt={alt}
        onLoad={() => setLoaded(true)}
        style={{
          opacity: `${!loaded ? 0 : 1}`,
          borderRadius: '3px',
          backgroundColor: user ? (user.status ? '#39E489' : '#FE3D2E') : 'none',
          border: user
            ? user.status
              ? `${borderSize} solid #39E489`
              : `${borderSize} solid #FE3D2E`
            : 'none',
        }}
        className="lazy-loading"
      />
    </div>
  )
}

export default ImageProgressiveLoading
