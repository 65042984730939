import api, { patch, post, uploadFile } from 'api/api-connection'
import cleanDeep from 'clean-deep'
import store from 'store'
import NotificationGenerator from 'base/NotificationGenerator'
import { intl } from '../../locales/IntlGlobalProvider'

const fetchDayOffTypes = () =>
  new Promise((resolve, reject) => {
    const company = store.get('COMPANY_DATA', null)
    if (company) {
      resolve(company?.parameters?.typesOfDayoff)
    } else {
      api
        .get('/companies/byAuthUserCompany')
        .then(response => {
          store.set('COMPANY_DATA', response.data?.data)
          resolve(response.data?.data?.parameters?.typesOfDayoff)
        })
        .catch(err => reject(new Error(err)))
    }
  })

const fetchDayOffByIdTypes = id =>
  api
    .get(`/dayoffs/dayoffsTypes/${id}`)
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const postDayOffTypes = dayoff => {
  api
    .post('/companies/typesOfDayoff', dayoff)
    .then(response => {
      return response
    })
    .catch(err => {
      NotificationGenerator(`${err?.response?.data?.message}`, intl, true)
      console.error({ err })
      // throw new Error(err)
    })
}

const updateDayOffTypes = dayoff =>
  api
    .patch(`/companies/typesOfDayoff/${dayoff._id}`, dayoff)
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const deleteDayOffTypes = id =>
  api
    .delete(`/companies/typesOfDayoff/${id}`)
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const fetchDayOff = () =>
  api
    .post('/dayoffs/getFiltered')
    .then(response => {
      return response.data.data.dayoffs
    })
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const fetchDayOffById = id =>
  api
    .get(`/dayoffs/${id}`)
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const postDayOff = dayoff => {
  const filteredInput = cleanDeep(dayoff)
  try {
    if (filteredInput.attachment) {
      const file = filteredInput.attachment

      const formData = new FormData()
      file.forEach(f => {
        formData.append(
          'attachment',
          new Blob([new Uint8Array(f.arrayBuffer)], { type: f.type, originalname: f.originalname }),
        )
      })

      for (const key in dayoff) {
        if (key !== 'attachment') {
          formData.append(key, JSON.stringify(filteredInput[key]))
        }
      }

      return uploadFile('post', `/dayoffs`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
    }
    return post(`/dayoffs`, {}, filteredInput)
  } catch (e) {
    throw new Error(e)
  }
}

const updateDayOff = async dayoff => {
  const filteredInput = cleanDeep(dayoff)
  try {
    if (filteredInput.attachment) {
      const file = filteredInput.attachment

      const formData = new FormData()
      file.forEach(f => {
        formData.append('attachment', new Blob([new Uint8Array(f.arrayBuffer)], { type: f.type }))
      })
      await uploadFile('patch', `/dayoffs/${dayoff._id}`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
    }
    return patch(`/dayoffs/${dayoff._id}`, {}, { ...filteredInput, attachment: undefined })
  } catch (err) {
    console.error(err)
    throw new Error(err)
  }
}

const updateDayOffReview = dayoff =>
  api
    .post('/dayoffs/addreview', dayoff)
    .then(response => response.data)
    .catch(err => {
      console.error({ err })
      NotificationGenerator(
        'Un administrador no puede aprobar ni rechazar su propia licencia',
        undefined,
        true,
      )
      throw new Error(err)
    })

const deleteDayOff = id =>
  api
    .delete(`/dayoffs/${id}`)
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const fetchRequestTable = () =>
  api
    .get('/dayoffs')
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

export default {
  fetchDayOffTypes,
  fetchDayOffByIdTypes,
  updateDayOffTypes,
  deleteDayOffTypes,
  postDayOffTypes,
  fetchDayOff,
  fetchDayOffById,
  updateDayOff,
  deleteDayOff,
  postDayOff,
  fetchRequestTable,
  updateDayOffReview,
}
