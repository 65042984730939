import api from 'services-fichap/dayoff/index'
import NotificationGenerator from 'base/NotificationGenerator'
import { takeEvery, put, call } from 'redux-saga/effects'
import {
  CREATE_ABSENCE,
  GET_REASONS_ABSENCES,
  UPDATE_REASON_ABSENCE,
  CREATE_REASON_ABSENCE,
  DELETE_REASON_ABSENCE,
  GET_DAYOFFS,
  GET_DAYOFF_BY_ID,
  UPDATE_DAYOFF,
  UPDATE_REVIEW,
  CLEAR_ERROR,
} from './types'
import { intl } from '../../locales/IntlGlobalProvider'

function* getReasonsAbsencesApiCall() {
  try {
    const response = yield call(api.fetchDayOffTypes)
    yield put({ type: GET_REASONS_ABSENCES.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_REASONS_ABSENCES.FAILURE, payload: error })
  }
}

function* updateReasonAbsenceApiCall(action) {
  try {
    const response = yield call(api.updateDayOffTypes, action.payload)
    yield put({ type: UPDATE_REASON_ABSENCE.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: UPDATE_REASON_ABSENCE.FAILURE, payload: error })
  }
}

function* createReasonAbsenceApiCall(action) {
  try {
    const response = yield call(api.postDayOffTypes, action.payload)
    yield put({ type: CREATE_REASON_ABSENCE.SUCCESS, payload: response })
    console.log({ response }, 'testingggg')
    if (response) NotificationGenerator('TypeOfDayoffCreated', intl, false)
  } catch (error) {
    yield put({ type: CREATE_REASON_ABSENCE.FAILURE, payload: error })
  }
}

function* deleteReasonAbsenceApiCall(action) {
  try {
    const response = yield call(api.deleteDayOffTypes, action.payload)
    yield put({ type: DELETE_REASON_ABSENCE.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: DELETE_REASON_ABSENCE.FAILURE, payload: error })
  }
}

function* createAbsenceApiCall(action) {
  try {
    const response = yield call(api.postDayOff, action.payload)
    yield put({ type: CREATE_ABSENCE.SUCCESS, payload: response })
    NotificationGenerator('DayOffCreated', intl, false)
  } catch (error) {
    yield put({ type: CREATE_ABSENCE.FAILURE, payload: error })
    if (
      error.response.data.message !== 'DAYOFF_COLLIDES' &&
      error.response.data.message !== 'EXCEEDS_DAYOFF_LIMIT'
    ) {
      NotificationGenerator(
        `Hemos tenido un problema al procesar su solicitud. Código de error: ${error.response.data.message}`,
        intl,
        true,
      )
    }
  }
}

function* fetchDayOffApiCall() {
  try {
    const response = yield call(api.fetchDayOff)
    yield put({ type: GET_DAYOFFS.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_DAYOFFS.FAILURE, payload: error })
  }
}

function* fetchDayOffByIdApiCall() {
  try {
    const response = yield call(api.fetchDayOffById)
    yield put({ type: GET_DAYOFF_BY_ID.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_DAYOFF_BY_ID.FAILURE, payload: error })
  }
}

function* updateDayOffApiCall(action) {
  try {
    const response = yield call(api.updateDayOff, action.payload)
    yield put({ type: UPDATE_DAYOFF.SUCCESS, payload: response })
    NotificationGenerator('DayOffUpdated', intl, false)
  } catch (error) {
    yield put({ type: UPDATE_DAYOFF.FAILURE, payload: error })
    NotificationGenerator('DayOffNotCreated', intl, true)
  }
}

function* updateDayOffReviewApiCall(action) {
  try {
    const response = yield call(api.updateDayOffReview, action.payload)
    yield put({ type: UPDATE_DAYOFF.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: UPDATE_DAYOFF.FAILURE, payload: error })
  }
}

function* clearError(action) {
  try {
    yield put({ type: CLEAR_ERROR.SUCCESS, payload: action.payload })
  } catch (error) {
    yield put({ type: CLEAR_ERROR.FAILURE, payload: error })
  }
}

function* absenceIndexSaga() {
  yield takeEvery(GET_REASONS_ABSENCES.REQUEST, getReasonsAbsencesApiCall)
  yield takeEvery(UPDATE_REASON_ABSENCE.REQUEST, updateReasonAbsenceApiCall)
  yield takeEvery(CREATE_REASON_ABSENCE.REQUEST, createReasonAbsenceApiCall)
  yield takeEvery(DELETE_REASON_ABSENCE.REQUEST, deleteReasonAbsenceApiCall)
  yield takeEvery(CREATE_ABSENCE.REQUEST, createAbsenceApiCall)
  yield takeEvery(GET_DAYOFFS.REQUEST, fetchDayOffApiCall)
  yield takeEvery(GET_DAYOFF_BY_ID.REQUEST, fetchDayOffByIdApiCall)
  yield takeEvery(UPDATE_DAYOFF.REQUEST, updateDayOffApiCall)
  yield takeEvery(UPDATE_REVIEW.REQUEST, updateDayOffReviewApiCall)
  yield takeEvery(CLEAR_ERROR.REQUEST, clearError)
}

export default absenceIndexSaga
