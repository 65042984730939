import 'antd/lib/style/index.less' // antd core styles
import './design/kit/vendors/antd/themes/default.less' // default theme antd components
import './design/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles
import './styles/skeleton.sass'

import React from 'react'
import ReactDOM from 'react-dom'
import { createHashHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
// import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import reducers from 'redux-fichap/reducers'
import sagas from 'redux-fichap/sagas'
import Localization from 'localization'
import Router from 'router'
import * as serviceWorker from 'serviceWorker'
import { clarity } from 'react-microsoft-clarity'

// mocking api
import 'api/fakeApi'

if (process.env.NODE_ENV === 'production') clarity.init('mh11b59x2e')
// middlewared
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }

// chrome DEV tools redux
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: `Redux`,
        realtime: true,
        trace: true,
        traceLimit: 25,
      })
    : compose

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares),
  // other store enhancers if any
)

const store = createStore(reducers(history), enhancer)
sagaMiddleware.run(sagas)

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }
