import { all } from 'redux-saga/effects'
import user from './user/sagas'
import settings from './settings/sagas'
import administrators from './administrators/sagas'
import { WorkdaySaga } from './workday'
import { TeamSaga } from './team'
import config from './config/sagas'
import company from './company/sagas'
import absences from './absences/sagas'
import branchOffice from './branchoffices/sagas'
import hierarchy from './hierarchy/sagas'
import dismissedTable from './dismissedTable/sagas'
import notifications from './notifications/sagas'
import workshift from './workshift/sagas'
import dashboard from './dashboard/sagas'
import profile from './profile/sagas'
import reports from './reports/sagas'
import fileSystem from './fileSystem/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    settings(),
    WorkdaySaga(),
    administrators(),
    config(),
    dismissedTable(),
    company(),
    absences(),
    branchOffice(),
    TeamSaga(),
    hierarchy(),
    notifications(),
    workshift(),
    dashboard(),
    profile(),
    reports(),
    fileSystem(),
  ])
}
