import { RESTART_STATE } from 'redux-fichap/user/types'
import { parseActionType } from 'utils'
import {
  GET_BRANCH_OFFICES,
  DELETE_BRANCH_OFFICES,
  GET_BRANCH_OFFICE_BY_ID,
  UPDATE_BRANCH_OFFICES,
  CREATE_BRANCH_OFFICE,
  GET_BRANCH_OFFICES_TREE,
} from './types'

const INITIAL_STATE = {
  error: null,
  loading: {},
  branchOfficesTree: [],
}

export default (state = INITIAL_STATE, action) => {
  const actionType = parseActionType(action.type)

  switch (action.type) {
    case GET_BRANCH_OFFICES.REQUEST:
    case GET_BRANCH_OFFICES_TREE.REQUEST:
    case GET_BRANCH_OFFICE_BY_ID.REQUEST:
    case DELETE_BRANCH_OFFICES.REQUEST:
    case UPDATE_BRANCH_OFFICES.REQUEST:
    case CREATE_BRANCH_OFFICE.REQUEST:
      return { ...state, loading: { [actionType]: true } }

    case GET_BRANCH_OFFICES.SUCCESS:
      return { ...state, loading: { [actionType]: false }, branchOffices: action.payload }
    case GET_BRANCH_OFFICES_TREE.SUCCESS:
      return { ...state, loading: { [actionType]: false }, branchOfficesTree: action.payload }

    case GET_BRANCH_OFFICE_BY_ID.SUCCESS:
      return { ...state, loading: { [actionType]: false }, branchOffice: action.payload }

    case UPDATE_BRANCH_OFFICES.SUCCESS:
      return { ...state, loading: { [actionType]: false }, branchOfficeUpdated: true }
    case DELETE_BRANCH_OFFICES.SUCCESS:
      return { ...state, loading: { [actionType]: false }, branchOfficeDeleted: true }

    case GET_BRANCH_OFFICES.FAILURE:
    case GET_BRANCH_OFFICES_TREE.FAILURE:
    case GET_BRANCH_OFFICE_BY_ID.FAILURE:
    case DELETE_BRANCH_OFFICES.FAILURE:
    case UPDATE_BRANCH_OFFICES.FAILURE:
    case CREATE_BRANCH_OFFICE.FAILURE:
      return { loading: { [actionType]: false }, error: { [actionType]: action.payload } }
    case RESTART_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
