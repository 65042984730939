import api, { post, patch, uploadFile } from 'api/api-connection'
import NotificationGenerator from 'base/NotificationGenerator'
import cleanDeep from 'clean-deep'

const fetchBranchOffices = payload =>
  api
    .post('/branchoffices/getFiltered', payload)
    .then(response => response.data.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const fetchBranchOfficesTree = () =>
  api
    .get('/branchoffices/getBranchTree')
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const fetchBranchOfficesById = id =>
  api
    .get(`/branchoffices/${id}`)
    .then(response => response.data.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

// const createBranchOffice = branchoffice =>
//   api
//     .post('/branchoffices', branchoffice)
//     .then(response => response.data)
//     .catch(err => {
//       console.error(err)
//       throw new Error(err)
//     })

const createBranchOffice = branchoffice => {
  const filteredInput = cleanDeep(branchoffice)
  try {
    if (filteredInput.image) {
      const file = filteredInput.image

      const formData = new FormData()
      formData.append('image', new Blob([new Uint8Array(file.arrayBuffer)], { type: file.type }))
      for (const key in filteredInput) {
        if (key !== 'image') {
          formData.append(key, JSON.stringify(filteredInput[key]))
        }
      }

      return uploadFile('post', '/branchoffices', formData)
    }
    return post('/branchoffices', {}, filteredInput)
  } catch (e) {
    console.error(e)
    throw new Error(e)
  }
}

const updateBranchOffices = branch => {
  const filteredInput = cleanDeep(branch)
  try {
    if (filteredInput.image) {
      const file = filteredInput.image

      const formData = new FormData()
      formData.append('image', new Blob([new Uint8Array(file.arrayBuffer)], { type: file.type }))
      for (const key in filteredInput) {
        if (key !== 'image') {
          formData.append(key, JSON.stringify(filteredInput[key]))
        }
      }

      return uploadFile('patch', `/branchoffices/${branch._id}`, formData)
    }
    return patch(`/branchoffices/${branch._id}`, {}, filteredInput)
  } catch (e) {
    console.error(e)
    throw new Error(e)
  }
}

const deleteBranchOffices = (payload, intl) =>
  api
    .patch(`/branchoffices/delete/${payload._id}`, payload.parentOrganization)
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.error({ err })
      console.error('Error al enviar la solicitud de eliminacion:', err)
      if (err?.response?.data?.code === 100001) {
        console.log('Error: ', err?.response?.data?.message)
        NotificationGenerator(
          'Esta sucursal no puede ser eliminada porque tiene usuarios asociados',
          intl,
          true,
        )
      }
      if (err?.response?.data?.code === 100002) {
        console.log('Error: ', err?.response?.data?.message)
        NotificationGenerator(
          'Esta excepción ocurre cuando la sucursal que desea eliminar tiene algunas sucursales secundarias.',
          intl,
          true,
        )
      }
      throw new Error(err)
    })

export default {
  fetchBranchOffices,
  fetchBranchOfficesById,
  updateBranchOffices,
  deleteBranchOffices,
  createBranchOffice,
  fetchBranchOfficesTree,
}
