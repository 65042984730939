import { RESTART_STATE } from 'redux-fichap/user/types'
import { parseActionType } from 'utils'
import {
  GET_ABSENCES,
  GET_CALENDAR,
  GET_GRAPHIC,
  GET_LATE_ARRIVALS,
  GET_PRECENSES,
  GET_MAP,
} from './types'

const INITIAL_STATE = {
  loading: {},
  error: null,
}

export default (state = INITIAL_STATE, action) => {
  const actionType = parseActionType(action.type)

  switch (action.type) {
    case GET_ABSENCES.REQUEST:
    case GET_CALENDAR.REQUEST:
    case GET_GRAPHIC.REQUEST:
    case GET_LATE_ARRIVALS.REQUEST:
    case GET_PRECENSES.REQUEST:
    case GET_MAP.REQUEST:
      return { ...state, loading: { [actionType]: true } }

    case GET_ABSENCES.SUCCESS:
      return { ...state, loading: { [actionType]: false }, dashboardAbsences: action.payload }
    case GET_CALENDAR.SUCCESS:
      return { ...state, loading: { [actionType]: false }, dashboardCalendar: action.payload }
    case GET_GRAPHIC.SUCCESS:
      return { ...state, loading: { [actionType]: false }, dashboardgraphic: action.payload }
    case GET_LATE_ARRIVALS.SUCCESS:
      return { ...state, loading: { [actionType]: false }, dashboardLateArrivals: action.payload }
    case GET_PRECENSES.SUCCESS:
      return { ...state, loading: { [actionType]: false }, dashboardPrecenses: action.payload }
    case GET_MAP.SUCCESS:
      return { ...state, loading: { [actionType]: false }, dashboardMap: action.payload }

    case GET_ABSENCES.FAILURE:
    case GET_CALENDAR.FAILURE:
    case GET_GRAPHIC.FAILURE:
    case GET_LATE_ARRIVALS.FAILURE:
    case GET_PRECENSES.FAILURE:
    case GET_MAP.FAILURE:
      return { ...state, loading: { [actionType]: false }, error: action.payload }
    case RESTART_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
