import { RESTART_STATE } from 'redux-fichap/user/types'
import { parseActionType } from 'utils'

import {
  GET_COVID,
  GET_CUSTOM_DATA,
  GET_CUSTOM_DATA_BY_NAME,
  GET_FOLDER,
  GET_GENERAL_CONFIG,
  GET_NOTIFICATIONS,
  GET_WORKDAY,
  UPDATE_COVID,
  UPDATE_FOLDER_BY_ID,
  UPDATE_GENERAL_CONFIG,
  UPDATE_NOTIFICATIONS,
  UPDATE_WORKDAY_BY_ID,
  DELETE_FOLDER_BY_ID,
  DELETE_WORKDAY_BY_ID,
  UPDATE_CUSTOM_DATA,
  CREATE_WORKDAY,
  CREATE_FOLDER,
} from './types'

const INITIAL_STATE = {
  loading: {},
  error: null,
}

export default (state = INITIAL_STATE, action) => {
  const actionType = parseActionType(action.type)
  switch (action.type) {
    case GET_COVID.REQUEST:
    case GET_CUSTOM_DATA.REQUEST:
    case GET_FOLDER.REQUEST:
    case GET_GENERAL_CONFIG.REQUEST:
    case GET_NOTIFICATIONS.REQUEST:
    case GET_WORKDAY.REQUEST:
    case GET_CUSTOM_DATA_BY_NAME.REQUEST:
    case UPDATE_COVID.REQUEST:
    case UPDATE_FOLDER_BY_ID.REQUEST:
    case UPDATE_GENERAL_CONFIG.REQUEST:
    case UPDATE_NOTIFICATIONS.REQUEST:
    case UPDATE_WORKDAY_BY_ID.REQUEST:
    case DELETE_FOLDER_BY_ID.REQUEST:
    case DELETE_WORKDAY_BY_ID.REQUEST:
    case UPDATE_CUSTOM_DATA.REQUEST:
    case CREATE_FOLDER.REQUEST:
    case CREATE_WORKDAY.REQUEST:
      return { ...state, loading: { [actionType]: true } }

    case GET_COVID.SUCCESS:
      return { ...state, loading: { [actionType]: false }, covid: action.payload }
    case GET_FOLDER.SUCCESS:
      return { ...state, loading: { [actionType]: false }, folders: action.payload }

    case GET_CUSTOM_DATA.SUCCESS:
      return { ...state, loading: { [actionType]: false }, companyData: action.payload }

    case GET_CUSTOM_DATA_BY_NAME.SUCCESS:
      return { ...state, loading: { [actionType]: false }, customField: action.payload }

    case GET_GENERAL_CONFIG.SUCCESS:
      return { ...state, loading: { [actionType]: false }, config: action.payload }

    case GET_NOTIFICATIONS.SUCCESS:
      return { ...state, loading: { [actionType]: false }, notifications: action.payload }

    case GET_WORKDAY.SUCCESS:
      return { ...state, loading: { [actionType]: false }, workday: action.payload }

    case UPDATE_COVID.SUCCESS:
    case UPDATE_FOLDER_BY_ID.SUCCESS:
    case UPDATE_GENERAL_CONFIG.SUCCESS:
    case UPDATE_NOTIFICATIONS.SUCCESS:
    case UPDATE_WORKDAY_BY_ID.SUCCESS:
    case UPDATE_CUSTOM_DATA.SUCCESS:
    case CREATE_FOLDER.SUCCESS:
    case CREATE_WORKDAY.SUCCESS:
      return { ...state, loading: { [actionType]: false } }

    case DELETE_FOLDER_BY_ID.SUCCESS:
    case DELETE_WORKDAY_BY_ID.SUCCESS:
      return { ...state, loading: { [actionType]: false } }

    case GET_COVID.FAILURE:
    case GET_CUSTOM_DATA.FAILURE:
    case GET_CUSTOM_DATA_BY_NAME.FAILURE:
    case GET_FOLDER.FAILURE:
    case GET_GENERAL_CONFIG.FAILURE:
    case GET_NOTIFICATIONS.FAILURE:
    case GET_WORKDAY.FAILURE:
    case UPDATE_COVID.FAILURE:
    case UPDATE_FOLDER_BY_ID.FAILURE:
    case UPDATE_GENERAL_CONFIG.FAILURE:
    case UPDATE_NOTIFICATIONS.FAILURE:
    case UPDATE_WORKDAY_BY_ID.FAILURE:
    case DELETE_FOLDER_BY_ID.FAILURE:
    case DELETE_WORKDAY_BY_ID.FAILURE:
    case UPDATE_CUSTOM_DATA.FAILURE:
    case CREATE_FOLDER.FAILURE:
    case CREATE_WORKDAY.FAILURE:
      return { ...state, loading: { [actionType]: false }, error: action.payload || true }
    case RESTART_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
