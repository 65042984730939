import moment from 'moment'
import xlsx from 'xlsx'

export default (arr = [], title, width = 19, includeDate = true) => {
  const limitedTitle = title.substring(0, 31).trim()
  const colWidth = []
  const data = []
  data.push(Object.keys(arr[0]))
  arr.forEach(item => data.push(Object.values(item)))
  for (let i = 0; i < data[0].length; i++) {
    colWidth.push({ width })
  }

  const wb = xlsx.utils.book_new()
  wb.Props = {
    Title: limitedTitle,
    Author: 'Fichap',
  }
  wb.SheetNames.push(limitedTitle)
  const ws = xlsx.utils.aoa_to_sheet(data)
  wb.Sheets[limitedTitle] = ws

  ws['!cols'] = colWidth

  const fileName = includeDate
    ? `${limitedTitle} - ${moment().format('DD-MM-YYYY')}.xlsx`
    : `${limitedTitle}.xlsx`;

  xlsx.writeFile(wb, fileName)
}
