import { createRequestTypes } from '../../utils'

const namespace = 'administrators'

export const GET_ADMINISTRATORS = createRequestTypes(`${namespace}/GET_ADMINISTRATORS`)

export const GET_ADMINISTRATOR_BY_ID = createRequestTypes(`${namespace}/GET_ADMINISTRATOR_BY_ID`)

export const CREATE_ADMINISTRATOR = createRequestTypes(`${namespace}/CREATE_ADMINISTRATOR`)

export const UPDATE_ADMINISTRATOR = createRequestTypes(`${namespace}/UPDATE_ADMINISTRATOR`)

export const DELETE_ADMINISTRATOR_BY_ID = createRequestTypes(
  `${namespace}/DELETE_ADMINISTRATOR_BY_ID`,
)

export const CLEAR_ERROR = createRequestTypes(`${namespace}/CLEAR_ERROR`)
