import { createRequestTypes } from '../../utils'

const namespace = 'profile'

export const GET_PROFILE_DATA = createRequestTypes(`${namespace}/GET_PROFILE_DATA`)

export const UPDATE_PROFILE_DATA = createRequestTypes(`${namespace}/UPDATE_PROFILE_DATA`)

export const UPDATE_PROFILE_DATA_FINISHED = createRequestTypes(
  `${namespace}/UPDATE_PROFILE_DATA_FINISHED`,
)

export const CLEAR_ERROR = createRequestTypes(`${namespace}/CLEAR_ERROR`)

export const CHANGE_PASSWORD = createRequestTypes(`${namespace}/CHANGE_PASSWORD`)
