import { createRequestTypes } from '../../utils'

const namespace = 'auth'
export const LOGIN = createRequestTypes(`${namespace}/LOGIN`)
export const GET_ROLES_BY_VIEWS = createRequestTypes(`${namespace}/GET_ROLES_BY_VIEWS`)
export const LOAD_CURRENT_ACCOUNT = createRequestTypes(`${namespace}/LOAD_CURRENT_ACCOUNT`)

export const SET_STATE = `${namespace}/SET_STATE`
export const REGISTER = `${namespace}/REGISTER`
export const LOGOUT = `${namespace}/LOGOUT`
export const RESTART_STATE = `RESTART_STATE`

export const MANTEINANCE = createRequestTypes(`${namespace}/MANTEINANCE`)

export const UPDATE_PROFILE_IMAGE = createRequestTypes(`${namespace}/UPDATE_PROFILE_IMAGE`)
