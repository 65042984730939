import api from 'services-fichap/branch-offices/index'
import { takeEvery, put, call } from 'redux-saga/effects'
import NotificationGenerator from 'base/NotificationGenerator'
import {
  GET_BRANCH_OFFICES,
  DELETE_BRANCH_OFFICES,
  GET_BRANCH_OFFICE_BY_ID,
  UPDATE_BRANCH_OFFICES,
  CREATE_BRANCH_OFFICE,
  GET_BRANCH_OFFICES_TREE,
} from './types'
import { intl } from '../../locales/IntlGlobalProvider'

function* getBranchOfficesApiCall(action) {
  try {
    const response = yield call(api.fetchBranchOffices, action.payload)
    yield put({ type: GET_BRANCH_OFFICES.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_BRANCH_OFFICES.FAILURE, payload: error })
  }
}

function* getBranchOfficesTreeApiCall() {
  try {
    const response = yield call(api.fetchBranchOfficesTree)
    yield put({ type: GET_BRANCH_OFFICES_TREE.SUCCESS, payload: response.data })
  } catch (error) {
    yield put({ type: GET_BRANCH_OFFICES_TREE.FAILURE, payload: error })
  }
}

function* getBranchOfficeByIdApiCall(action) {
  try {
    const response = yield call(api.fetchBranchOfficesById, action.payload)
    yield put({ type: GET_BRANCH_OFFICE_BY_ID.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_BRANCH_OFFICE_BY_ID.FAILURE, payload: error })
  }
}

function* createBranchOfficeApiCall(action) {
  try {
    const response = yield call(api.createBranchOffice, action.payload)
    yield put({ type: CREATE_BRANCH_OFFICE.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: CREATE_BRANCH_OFFICE.FAILURE, payload: error })
  }
}

function* deleteBranchOfficesApiCall(action) {
  try {
    const response = yield call(api.deleteBranchOffices, action.payload)
    yield put({ type: DELETE_BRANCH_OFFICES.SUCCESS, payload: response })
    NotificationGenerator('BranchDeleted', intl, false)
  } catch (error) {
    yield put({ type: DELETE_BRANCH_OFFICES.FAILURE, payload: error })
    NotificationGenerator('AnErrorHasOcurred', intl, true)
  }
}

function* updateBranchOfficeApiCall(action) {
  try {
    const response = yield call(api.updateBranchOffices, action.payload)
    yield put({ type: UPDATE_BRANCH_OFFICES.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: UPDATE_BRANCH_OFFICES.FAILURE, payload: error })
  }
}

function* IndexSaga() {
  yield takeEvery(GET_BRANCH_OFFICES.REQUEST, getBranchOfficesApiCall)
  yield takeEvery(GET_BRANCH_OFFICE_BY_ID.REQUEST, getBranchOfficeByIdApiCall)
  yield takeEvery(CREATE_BRANCH_OFFICE.REQUEST, createBranchOfficeApiCall)
  yield takeEvery(UPDATE_BRANCH_OFFICES.REQUEST, updateBranchOfficeApiCall)
  yield takeEvery(DELETE_BRANCH_OFFICES.REQUEST, deleteBranchOfficesApiCall)
  yield takeEvery(GET_BRANCH_OFFICES_TREE.REQUEST, getBranchOfficesTreeApiCall)
}

export default IndexSaga
