import api, { patch, uploadFile } from 'api/api-connection'
import cleanDeep from 'clean-deep'

const getProfile = () =>
  api
    .get('/users/profile/get')
    .then(response => response.data.data)
    .catch(err => {
      throw new Error(err)
    })

// const updateProfile = payload =>
//   api
//     .patch('/users/profile/update', payload, {
//       headers: { 'content-type': 'multipart/form-data' },
//     })
//     .then(response => response.data || '')
//     .catch(err => {
//       throw new Error(err)
//     })

const updateProfile = payload => {
  const filteredInput = cleanDeep(payload)
  try {
    if (filteredInput.image) {
      const file = filteredInput.image

      const formData = new FormData()
      formData.append('image', new Blob([new Uint8Array(file.arrayBuffer)], { type: file.type }))
      for (const key in filteredInput) {
        if (key !== 'image') {
          formData.append(key, JSON.stringify(filteredInput[key]))
        }
      }

      return uploadFile('patch', '/users/profile/update', formData)
    }
    return patch('/users/profile/update', {}, filteredInput)
  } catch (e) {
    console.error(e)
    throw new Error(e)
  }
}

const changePassword = password =>
  api
    // .patch(`/users/change-password/${user._id}`, password)
    .patch('/users/profile/change-password', password)
    .then(response => response)
    .catch(err => {
      throw new Error(err)
    })

export default {
  getProfile,
  updateProfile,
  changePassword,
}

// export async function changePassword(userId, password, resetToken) {
//   store.set('accessToken', resetToken)

//   return apiConnection
//     .patch('/users/change-password/' + userId, {
//       password: password,
//     })
//     .then(response => {
//       if (response) {
//         store.remove('accessToken')
//         return true
//       }
//       return false
//     })
//     .catch(err => console.error(err))
// }
