import localeAntd from 'antd/es/locale/pt_BR'
import moment from 'moment'
import Arr from '../helpers/Arr'
import fichapFormMessages from './pt-BR/fichapForm'

let messages = {
  Dashboard: 'Dashboard',
  Team: 'Equipe',
  notImageError: 'La imagen es requerida',
  TeamMembers: 'Miembros',
  Collaborators: 'Colaboradores',
  absencesDashboard: 'Tablero do ausencias',
  Shift: 'Turno',
  Shifts: 'Turnos',
  shifts: 'turnos',
  shift: 'turno',
  Contact: 'Contacto',
  NewWorkshift: 'Nuevo turno',
  BulkLoadItemDeleted: 'Fila eliminada con éxito',
  ImagesLoaded: 'Imágenes cargadas',
  SalarySlip: 'Recibos',
  CreatePosition: 'criar posição',
  SettlementDay: 'Fecha de liquidación',
  LastMonth: 'Último mes',
  BulKLoadFile: 'carga-masiva-fichap',
  SimpleWorkshift: 'Jornada simple',
  NoTINFound: 'No hay coincidencia, verifique los datos',
  _Document: 'Documento',
  BulkLoad: 'Carga masiva',
  EditWorkshift: 'Editar turno',
  Settlement: 'Liquidación',
  SelectADay: 'Seleccione un día para elegir una hora',
  ApplyShiftWeek: 'Aplicar para esta semana',
  ShiftTemplateAssigned: 'Plantilla de turno asignada',
  ApplyShiftEveryWeek: 'Aplicar para cada semana',
  AdvancedWorkshiftOptions: 'Edición do jornadas avanzadas',
  UpdatePosition: 'Actualizar puesto',
  AdvancedWorkshift: 'Jornadas avanzadas',
  BackToSimpleShift: 'Volver a jornadas simples',
  Heatmap: 'Mapa do calor',
  heatmap: 'Mapa do calor',
  Workshift: 'Vez',
  Posicion: 'Posição',
  'Puesto / Cargo': 'Posição',
  Posição: 'Posição',
  workshift: 'Turno de trabalho',
  'workshift-this-week': 'Turno de trabalho',
  DeleteShift: 'Excluir turno',
  DeleteTodayShifts: `Os turnos de hoje serão eliminados ${moment().format(
    'MM-DD-YYYY',
  )}. Você quer confirmar esta ação?`,
  DeleteWeekShifts: `Muda de ${moment()
    .startOf('week')
    .format('MM-DD-YYYY')} para ${moment()
    .endOf('week')
    .format('MM-DD-YYYY')} será eliminado. Você quer confirmar esta ação?`,
  DeleteMonthShifts: `Os turnos do mês atual serão eliminados. Você quer confirmar esta ação?`,
  DeleteAllShifts: `Todas as mudanças futuras serão eliminadas. Você quer confirmar esta ação?`,
  DeleteSelectedShifts: `Os turnos selecionados serão eliminados. Você quer confirmar esta ação?`,
  Today: 'Hoje',
  ThisWeek: 'Essa semana',
  All: 'Todo',
  Selected: 'Seleccionados',
  DailyShifts: 'Mudança',
  Timeline: 'Linea do tiempo',
  day1: 'S',
  day2: 'T',
  day3: 'Q',
  day4: 'Q',
  day5: 'S',
  day6: 'S',
  day0: 'D',
  _day1: 'Seg',
  _day2: 'Ter',
  _day3: 'Qua',
  _day4: 'Qui',
  _day5: 'Sex',
  _day6: 'Sab',
  _day0: 'Dom',
  Organigram: 'Organigrama',
  organigram: 'organigrama',
  CasualtyList: 'Listado do bajas',
  casualtylist: 'Listado de bajas',
  CreateWorkshift: 'Novo turno',
  Delete: 'Livrar-se de',
  Confirm: 'Confirme',
  Cancel: 'Cancelar',
  RequestRejected: 'Pedido rejeitado com sucesso',
  RequestRejectedError: 'erro ao rejeitar pedido',
  RequestApproved: 'inscrição aprovada com sucesso',
  ApprovedRequestError: 'Falha ao aprovar pedido',
  Pendient: 'Pendente',
  Group: 'Grupo',
  Hirearchy: 'Jerarquía',
  Hirearchies: 'Jerarquías',
  HirearchiesMap: 'Mapa do jerarquías',
  InsertHierarchy: 'Añadir jerarquía',
  EditProfile: 'Editar perfil',
  AddHirearchy: 'Añadir jerarquía',
  UpdateHierarchy: 'Modificar jerarquía',
  SuperiorHirearchy: 'Jerarquía superior',
  IsACollab: 'Es un colaborador',
  Main: 'Principal',
  Requests: 'Solicitudes',
  month1: 'JAN',
  month2: 'FEV',
  month3: 'MAR',
  month4: 'ABR',
  month5: 'MAI',
  month6: 'JUN',
  month7: 'JUL',
  month8: 'AGO',
  month9: 'SET',
  month10: 'OUT',
  month11: 'NOV',
  month12: 'DEZ',
  requests: 'solicitudes',
  RequestsIssued: 'Solicitudes emitidas',
  CurrentPeriodRequests: 'Total solicitudes período actual',
  LastYearRequests: 'Total solicitudes período actual año anterior',
  NoPendingRequests: '¡Bien! No tienes solicitudes pendientes.',
  Profile: 'Perfil',
  Reports: 'Informes',
  ShiftsReport: 'Reporte do jornadas',
  Help: 'Socorro',
  Branch: 'Sucursal',
  BranchOffices: 'Filial',
  BranchID: 'ID do Fichap point',
  FichapPointID: 'ID do Fichap point',
  Manage: 'Gerir',
  LegacyNumber: 'N° do legajo',
  IdentificationCard: 'Cédula',
  Actions: 'Acciones',
  Progress: 'Progreso',
  SpecialDays: 'Dias especiais',
  DaysWorked: 'Days Worked',
  SpecialWorkedDays: 'Días especiales trabajados',
  ApprovedAbsences: 'Ausencias aprobadas',
  notApprovedAbsences: 'Ausencias no aprobadas',
  LateArrivalTime: 'Tiempo en llegadas tarde',
  HoursWorked: 'Horas trabajadas',
  Employeestate: 'Estado',
  LocationState: 'Provincia',
  Settings: 'Configuração',
  Average: 'Promedio',
  Absenteeism: 'Ausentismo',
  Presences: 'Presencias',
  Presenteeism: 'Presentismo',
  AbsenteeismRate: 'Tasa do ausentismo',
  CurrentAbsenteeismRate: 'Tasa de ausentismo actual',
  AbsenteeismType: 'Tipos do ausencias',
  DetailedAbsence: 'Ausencia detallada',
  CheckIn: 'Check in',
  CheckOut: 'Check out',
  RestCheckIn: 'Check in descanso',
  RestCheckOut: 'Check out descanso',
  InAgreement: 'En convenio',
  OutOfAgreement: 'Fuera de convenio',
  WorkingDayDuration: 'Duración de jornada',
  LateEntryTime: 'Tiempo de llegada tarde',
  Soon: 'Próximamente',
  RestDurationExceed: 'Tiempo extra del descanso',
  EarlyHours: 'Tiempo de entrada temprano',
  RegularHours: 'Horas normales',
  SpecialDayHours: 'Horas feriadas',
  RestDayWorkedHours: 'Horas en día de descanso',
  Duration: 'Duración',
  ComingSoon: 'Próximamente...',
  RestDuration: 'Duración del descanso',
  NightHours: 'Horas nocturnas',
  DistanceTraveled: 'Distancia recorrida',
  AppliedtoChartsandTables: 'Aplicado a gráficos y tablas',
  Filter: 'Filtrado',
  Download: 'Descargar',
  Absences: 'Ausências',
  absences: 'ausências',
  Subscription: 'Subscripção',
  Billing: 'Facturación',
  Admin: 'Administração',
  Companies: 'Organização',
  organizationChart: 'Organigrama',
  Managers: 'Gerentes',
  team: 'Equipe',
  Add: 'Adicionar',
  import: 'Importar',
  Sickness: 'Enfermedad',
  showingResultsShowing: 'Mostrando registros do ',
  showingResultsTo: 'a ',
  showingResultsOf: 'de um total do ',
  showingResultsRecords: 'registros',
  Image: 'Imagen',
  Lastname: 'Sobrenome',
  SecondLastname: 'Segundo sobrenome',
  ProfilePicture: 'Foto do perfil',
  New: 'Nova',
  CreateNotification: 'Criar notificação',
  DocumentExpirationDate: 'Vencimiento do documento',
  Send: 'Enviar',
  Name: 'Nome',
  SecondName: 'Segundo Nome',
  DateOfBirth: 'Data do nascimento',
  Address: 'Direção',
  SecondaryAddress: 'Direção secundario',
  TypeOfPayment: 'Tipo do pago',
  NameAndSurname: 'Nombre y apellido',
  Review: 'Revisado por',
  GeneralData: 'Datos generales',
  WeeklyHours: 'Horas semanales',
  Disenroll: 'Dar do baja',
  Meeting: 'Reunión',
  Vacations: 'Vacaciones',
  TextHere: 'Texto aquí',
  Ticket: 'Boleto',
  FamilyMember: 'Familiar',
  City: 'Cidade',
  State: 'Província',
  ZipCode: 'Código Postal',
  Gender: 'Gênero',
  Phone: 'Telefone',
  Document: 'N° do Documento',
  Email: 'Email',
  Password: 'Senha',
  NewPassword: 'Nova Senha',
  RepeatPassword: 'Repita sua nova senha',
  EditPassword: 'Editar senha',
  TypeDocument: 'Tipo do documento',
  Nationality: 'Nacionalidade',
  CountryOfBirth: 'País do nascimento',
  CivilStatus: 'Estado Civil',
  Nick: 'Nick',
  PersonalData: 'Dados pessoais',
  Personal: 'Personal',
  People: 'Personas',
  Street: 'Rua',
  StreetNumber: 'Número',
  FloorOptional: 'Chão',
  Save: 'Salve',
  FileNumber: 'Número do arquivo',
  Category: 'Categoria',
  QuoteGroup: 'Grupo do Cotação',
  DateOfAdmission: 'Data do admissão',
  SSN: 'Número da Segurança Social',
  SocialSecurityNumber: 'Número da Segurança Social',
  Position: 'Posição',
  position: 'Posição',
  Role: 'Título do posição',
  LaborUnion: 'Sindicato',
  Union: 'Sindicato',
  Bank: 'Banco',
  CBU: 'CBU',
  Alias: 'Alias',
  MainBranch: 'Filial Principal',
  username: 'Nombre do usuario',
  Action: 'Acción',
  EndEmploymentContract: 'Fim do contrato do trabalho',
  InternalPhone: 'Telefone interno',
  WorkPhone: 'Telefone laboral',
  AdminRoles: 'Roles do administrador',
  Seniority: 'Seniority',
  EndTrialPeriod: 'Fim do período experimental',
  ReportsTo: 'Reporte para',
  YearsWorked: 'Antiguidade',
  less1year: 'Menos de 1 año',
  _1to5years: '1 a 5 años',
  _6to10years: '6 a 10 años',
  _11to20years: '11 a 20 años',
  _20years: '20+',
  WorkData: 'Dados do trabalho',
  OrganizationModel: 'Modelo do organización',
  quoteNumber: 'Número do cotización',
  WorkEmail: 'Email laboral',
  Mark: 'Fecha / Marca',
  MedicalCoverageChosen: 'Obra social elegida',
  SocialWorkbyLaw: 'Obra social por ley',
  AreYouAdministrator: '¿Eres administrador?',
  BillingInformation: 'Dados do faturamento',
  EmergencyContact: 'Contato do emergência',
  Link: 'Ligação',
  Hourly: 'De hora em hora',
  Daily: 'Diariamente',
  Monthly: 'Por mês',
  Annual: 'Anual',
  Biannual: 'Semestral',
  Weekly: 'Semanal',
  Quarterly: 'Trimestral',
  AccountNumber: 'N° do cuenta',
  TypeOfRemuneration: 'Tipo do Remuneración',
  PaymentFrequency: 'Frecuencia do pago',
  SelectOne: 'Selecçãoar',
  AddDocument: 'Agregar archivo',
  Work: 'Laboral',
  Documents: 'Documentos',
  AddDocuments: 'Añadir documento',
  GeneralDocumentsChart: 'Gráfico do documentos generales',
  LoadedBy: 'Subido por',
  UploadDate: 'Fecha do carga',
  CollaboratorId: 'ID del colaborador',
  TaxIdentification: 'Numero fiscal',
  MedicalCoverage: 'Cobertura medica',
  DepartureDate: 'Fecha do finalização',
  UnionAgreement: 'Sindicato acordado',
  ContactData: 'Informação do contacto',
  MaternityPaternityLeave: 'Ausencia por maternidad/paternidad',
  SickLeave: 'Ausencia por enfermedad',
  UnexcusedAbsence: 'Ausencia sin justificar',
  VacationAbsence: 'Ausencia por Vacaciones',
  StudyAbsence: 'Ausencia por estudio',
  Others: 'Otras',
  Status: 'Estado',
  Approved: ' Aprobado',
  Rejected: ' Rechazado',
  inactive: 'INACTIVO',
  active: 'ACTIVO',
  SinceLastMonth: 'desde el mes anterior',
  SinceLastWeek: 'desde la semana anterior',
  LateEntry: 'Llegadas tarde',
  MoreThanAllowed: 'Mayor al permitido',
  MoreThan: 'Descanso mayor al',
  Allowed: 'permitido',
  Language: 'Idioma',
  Languages: 'Idiomas',
  'footer.title': 'Fichap - Gestão do equipe inteligente',
  'footer.subtitle': '© 2020 / Todos os derechos reservados',
  'login.title': 'Entrar com seus dados',
  'login.welcome': 'Bem vindo a Fichap',
  'login.rememberme': 'Lembre do mim',
  'login.forgotpassword': 'Esqueceu sua senha?',
  'login.login': 'Entrar',
  'login.accesswith': 'acesso com',
  'login.createaccount': 'Não tem uma conta?',
  'login.starttoday': 'Começa hoje',
  supportchat: 'Suporte',
  'topBar.issuesHistory': 'História do Problemas',
  'topBar.projectManagement': 'Gerenciamento do Projetos',
  'topBar.typeToSearch': 'Procurar...',
  'topBar.findPages': 'Encontrar páginas...',
  'topBar.actions': 'Ações',
  'topBar.status': 'Estado',
  'topBar.profileMenu.hello': 'Olá',
  'topBar.profileMenu.billingPlan': 'Plano do Faturamento',
  'topBar.profileMenu.role': 'Posição',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Telefone',
  'topBar.profileMenu.editProfile': 'Editar Perfil',
  'topBar.profileMenu.logout': 'Sair',
  actionAttach: 'Anexar',
  downloadAction: 'Descargar',
  actionSend: 'Enviar',
  actionHistory: 'Registro',
  actionView: 'Ver',
  actionDelete: 'Remover',
  actionCancel: 'Cancelar',
  actionEdit: 'Editar',
  createEmployee: 'Criar empregado',
  saveEmployee: ' Salvar empregado',
  Passport: 'Passaporte',
  invalidEmail: 'Email inválido',
  requiredField: 'Campo obrigatório',
  deleteEmployeeQuestion: 'Você com certeza deseja deletar?',
  deleteEmployeeConfirmed: 'O empregado foi removido com sucesso',
  deleteEmployeeError: 'Ocorreu um erro. Tente novamente',
  employeeCreationSuccess: 'O empregado foi criado com sucesso',
  employeeCreationError: 'Ocorreu um erro. Tente novamente',
  employeeCreationEmailFail: 'O e-mail está em uso',
  emailOrUsername: 'Email ou Usuário',
  loginRequiredError: 'Por favor introduza o seu e-mail ou nome do usuário',
  loginPasswordRequiredError: 'Por favor introduza o seu contraseña',
  invalidNumber: 'O valor deve ser um número',
  forgotPasswordTitle: 'Recupere sua senha',
  forgotPasswordButtonSend: 'Recuperar',
  forgotPasswordButtonBack: 'Voltar ao início',
  forgotPasswordEmailSent: 'Enviamos uma mensagem para sua caixa do correio.',
  forgotPasswordExpired: 'Este link expirou.',
  forgotPasswordSendAgain: 'Enviar novamente.',
  loginSuccess: 'Você fez login com sucesso!',
  loginErrorNotAdmin:
    'Você não está autorizado a acessar o painel do administrador. Por favor entre em contato com o serviço do suporte.',
  NOT_ADMIN:
    'Você não está autorizado a acessar o painel do administrador. Por favor entre em contato com o serviço do suporte.',
  loginErrorNoToken: 'Ocorreu um erro. Por favor entre em contato com o serviço do suporte.',
  resetPasswordEmailError: 'Não foi possível encontrar uma conta associada a esse email.',
  changePasswordTitle: 'Mude sua senha',
  changePasswordRequiredError: 'Por favor introduza o seu nova contraseña',
  changePasswordCompare: 'As senhas não correspondem.',
  changePasswordSuccess: 'Senha alterada com sucesso.',
  changePasswordError: 'Sua solicitação expirou. Solicite uma alteração do senha novamente.',
  ExtraHours: 'Horas extras',
  RestHours: 'Horas do descanso',
  employeeUpdateFail: 'Ocorreu um erro. Reveja os detalhes e tente novamente.',
  employeeUpdateSuccess: 'Alterações salvas com sucesso.',
  generalFiles: 'Arquivos Gerais',
  paycheck: 'Recibos do pagamento',
  certificates: 'Atestados',
  medicalCertificates: 'Atestados Médicos',
  contracts: 'Contratos',
  files: 'registros',
  NewFile: 'Nuevo documento',
  EditFile: 'Editar documento',
  recover: 'Recuperar',
  recoverPassword: 'Recuperar contraseña',
  backLogin: 'Volver al Login',
  enterEmail: 'Por favor insira um endereço do e-mail.',
  AbsencesRequests: 'Solicitudes do Ausencias',
  AbsencesReport: 'Informe de ausencias',
  AbsenceRequestReport: 'Informe de solicitudes de ausencias',
  Employee: 'Empleado',
  Responsible: 'Responsable',
  dateStart: 'Fecha inicio',
  dateEnd: 'Fecha fin',
  StartDate: 'Fecha inicio',
  EndDate: 'Fecha fin',
  addRequest: 'Agregar solicitud',
  newRequest: 'Nueva solicitud',
  Reason: 'Motivo',
  Reasons: 'Motivos',
  reason: 'motivo',
  reasons: 'motivos',
  NewReason: 'Añadir motivo',
  absenceReason: 'Motivo do ausencia',
  NewAbsence: 'Nova ausência',
  COVIDAlert: 'Alerta COVID',
  Notification: 'Notificação',
  Notifications: 'Notificaçãos',
  notification: 'notificação',
  notifications: 'notificaçãos',
  Totaltime: 'Tempo total',
  Date: 'Data',
  Start: 'Começa',
  End: 'Termina',
  Summary: 'Resumo',
  Administrators: 'Administradores',
  administrators: 'administradores',
  Type: 'Tipo',
  SuperiorLevel: 'Cargo superior',
  AddLevel: 'adicionar nivel',
  Level: 'Nivel',
  Levels: 'Niveles',
  level: 'nivel',
  levels: 'niveles',
  SearchImage: 'Arraste seus documentos aqui ou clique para localizá-los em seu computador.',
  Edit: 'Editar',
  NewAdmin: 'Novo Administrador',
  EditAdmin: 'Editar Administrador',
  Approve: 'Aprovar',
  Reject: 'Recusar',
  Holidays: 'Feriados',
  SummaryNoData: '¡ai! Não há dados',
  confirmShiftDelete: 'Um turno será excluído. Quer confirmar esta ação?',
  EditHoliday: 'Editar feriados',
  AddHoliday: 'Añadir feriados',
  addNewShift: 'Adicionar novo turno',
  Lu: 'Se',
  Ma: 'Te',
  Mi: 'Qu',
  Ju: 'Qi',
  Vi: 'Se',
  Sa: 'Sa',
  Do: 'Do',
  '01d': 'Claro',
  '01n': 'Claro',
  '02d': 'Parcialmente nublado',
  '02n': 'Parcialmente nublado',
  '03d': 'Nuvens dispersas',
  '03n': 'Nuvens dispersas',
  '04d': 'Parcialmente nublado',
  '04n': 'Parcialmente nublado',
  '09d': 'Aguaciero',
  '09n': 'Aguaciero',
  '10d': 'Chuva',
  '10n': 'Chuva',
  '11d': 'Tempestade',
  '11n': 'Tempestade',
  '13d': 'Neve',
  '13n': 'Neve',
  '50d': 'Névoa',
  '50n': 'Névoa',
  min: 'Mínimo',
  errorFaceIdEmpty: 'Bom! Você não tem revisões pendentes.',
  SeeMore: 'Ver mais',
  max: 'Máximo',
  wind: 'Vento',
  healthcareSystem: 'Sistema de saúde',
  irpfDeduction: 'Dedução IRPF',
  additionalSolidarityFund: 'Fundo do solidariedade adicional',
  solidarityFund: 'Fundo do solidariedade',
  retirementContributions: 'Contribuições do aposentadoria',
  unionAgreement: 'Acordo sindical',
  laborUnion: 'Sindicato',
  Liquidity: 'liquidação',
  typeOfLiquidation: 'Tipo do liquidação',
  typeOfLeave: 'Tipo do retirada',
  typeOfContract: 'Tipo do contrato',
  currentSituation: 'Situação atual',
  holidayGroup: 'Tipo do jerarquías',
  hierarchies: 'Hierarquias',
  CustomizableData: 'Dados personalizáveis',
  quoteGroup: 'Grupo do cotação',
  typeOfInventory: 'Tipo do estoque',
  Colour: 'Cor',
  CreateWorkday: 'Criar dia',
  Description: 'Descrição',
  Title: 'Qualificação',
  Familiar: 'família',
  Contract: 'Contrato',
  WorkedHours: 'Horas trabalhadas',
  CurrentMonth: 'Mês atual',
  CurrentTrimester: 'Trimestre atual',
  CurrentYear: 'Ano atual',
  Last3months: 'Últimos 3 meses',
  AllinOrder: 'Tudo em ordem',
  NoCovidLine1: 'No se han detectado potenciales casos',
  NoCovidLine2: 'de COVID-19 en la jornada.',
  Sunny: 'Ensolarado',
  Humidity: 'Umidade',
  Weather_description: 'Espera-se um céu parcialmente claro. A temperatura máxima será do ',
  Calendar: 'Calendário',
  PendingTasks: 'Tarefas pendentes',
  PendingRequests: 'Solicitudes pendientes',
  PendentTask_description: 'você tem um pedido do ausência com revisão pendente.',
  organization_chart_title_card1:
    '¿Sabías que con Fichap podés visualizar el organigrama do tu empresa?',
  organization_chart_description_card1:
    'Fichap te permite organizar las jerarquías y niveles do tu organización muy fácilmente.',
  organization_chart_title_card2:
    '¿Sabías que con Fichap podés enviar notificaciones a todo tu equipo?',
  organization_chart_description_card2:
    'Fichap te permite organizar las jerarquías y niveles do tu organización muy fácilmente.',
  organization_chart_title_card3:
    '¿Sabías que con Fichap podés administrar todos los niveles do tu organización?',
  organization_chart_description_card3:
    'Fichap te permite organizar las jerarquías y niveles do tu organización muy fácilmente.',
  organization_chart_title_card4:
    '¿Sabías que podés recibir un informe semanal en tu bandeja de entrada?',
  organization_chart_description_card4:
    'Fichap te permite organizar las jerarquías y niveles de tu organización muy fácilmente.',
  know_more_button: 'Saber más',
  see_rofile_button: 'Ver perfil',
  DragItemTo: 'Arrastra un elemento',
  addItToCalendar: 'para agregarlo al calendario',
  BreakLenght: 'Duração do descanso',
  GeneralUpdated: 'Datos guardados con éxito',
  NotificationsUpdated: 'Notificación guardada con éxito',
  CalendarUpdated: 'Datos guardados con éxito',
  ErrorCalendarUpdated: 'Ha ocurrido un error. Intenta nuevamente',
  ReasonCreated: 'Motivo creado exitosamente',
  ReasonUpdated: 'Motivo guardado exitosamente',
  ReasonDeleted: 'Motivo eliminado con éxito',
  adminDeleted: 'Administrador eliminado con éxito',
  AdminCreated: 'Administrador creado con éxito',
  HierarchyAdded: 'Jerarquía creada con éxito',
  HierarchyDeleted: 'Jerarquía eliminada con éxito',
  WorkshiftDeleted: 'Jornada eliminada con éxito',
  WorkshiftCreated: 'Jornada creada con éxito',
  WorkshiftUpdated: 'Jornada guardada con éxito',
  IDAssignedShift: 'ID y turno asignado',
  CovidUpdated: 'Datos guardados con éxito',
  WorkdayCreated: 'Jornada creada con éxito',
  SpecialDayCreated: 'Feriado creado con éxito',
  SpecialDayaErrorCreate: 'Ha ocurrido un error. Intenta nuevamente',
  SpecialDayDeleted: 'Feriado borrado exitosamente',
  CustomDataUpdated: 'Datos guardados con éxito',
  WorkdayDeleted: 'Jornada eliminada con éxito',
  WorkdayUpdated: 'Datos guardados con éxito',
  DayOffCreated: 'Solicitud creada, pendiente de revisión.',
  DayOffNotCreated: 'Ha ocurrido un error. Revise los datos y vuelva a intentarlo por favor.',
  SearchFailed: 'Ha ocurrido un error. Revise los datos y vuelva a intentarlo por favor.',
  Discount_: '¿Descuento?',
  MinutesShort: 'min',
  Minutes: 'Minutos',
  ManageAndCustomizeGeneralSettings: 'Gerir e personalizar configurações gerais',
  ServiceEnablement: 'Habilitação de serviços  ',
  AllowBreaks: 'Permitir pausas',
  AllowClocking: 'Permitir fichar',
  EnableAutomaticDayClosure: 'Activar o fim automático do dia',
  Breaks: 'Pausas',
  Permissions: 'Permissões',
  AllowOffLocationClockingIn: 'Permitir o relógio fora do local',
  AllowClockingInWithoutAnAssignedShift: 'Permitir o relógio sem um turno atribuído',
  AllowClockingInInCaseOfSuspensions: 'Permitir o relógio em caso de ausência ',
  AllowClockingInWithApprovedAbsence: 'Permitir o relógio com ausência aprovada',
  ToleranceEarlyEntry: 'Tolerância de entrada antecipada (em minutos)',
  Tolerance: 'Tolerância',
  ExampleUse: 'Exemplos de utilizações',
  Developers: 'Programadores',
  ApiEnabledFunctions: 'As funções habilitadas para API podem ser encontradas em:',
  viewDocuments: 'Ver documentação',
  apiExplanation:
    'A Fichap API utiliza chaves API para autenticar pedidos.  Pode ver, actualizar e copiar as suas chaves no Painel de Controle. ',
  apiUnderlineMessage: 'Ao gerar uma nova ficha, o código antigo não pode ser utilizado.',
  allM: 'Todos', // siguiendo el patron del html entity &ordm; y &ordf;
  allF: 'Todas',
  'holiday.history.availableDays': 'Férias acumuladas',
  'holiday.history.tooltip': 'Número de dias adicionados pelo carregamento em massa cumulativo',
  typeOfSpecialDay: 'Tipos de dias especiais',
}

let append = Arr.dot(fichapFormMessages, 'FichapForm')
messages = { ...messages, ...append }

export default {
  locale: 'pt-BR',
  localeAntd,
  messages,
}
