import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, Row, Tooltip } from 'antd'
import avatar from 'assets/svg/avatar-default.svg'
import activepoint from 'assets/svg/header-active.svg'
import inactivepoint from 'assets/svg/header-inactive.svg'
import { Link, useHistory } from 'react-router-dom'
import ImageProgressiveLoading from 'components/common/ImageProgressiveLoading'
import style from './style.module.scss'
import backArrow from './assets/backArrow.svg'
import backArrowh from './assets/green_hover.svg'

const Header = ({ user, HandleLink }) => {
  const history = useHistory()

  return (
    <div>
      <div className={style.row}>
        {user ? (
          <div>
            <div className={style.card}>
              <div
                className="card-body p-1 header-Histori-info"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    style={{ marginRight: '15px' }}
                    className={style.btnArrow}
                    onClick={HandleLink}
                    type="link"
                  >
                    <img src={backArrowh} alt="" className={style.imgHover} />
                    <img src={backArrow} alt="backarrow" className={style.imgDefault} />
                  </Button>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={style.kit__utils__avatar}>
                      <ImageProgressiveLoading
                        width="40px"
                        height="40px"
                        style={{ borderRadius: '3px', display: 'flex' }}
                        justify-content="center"
                        user={user}
                        borderSize="2px"
                        url={
                          user?.personalData
                            ? user?.personalData?.imageURL
                              ? user?.personalData?.imageURL
                              : avatar
                            : avatar
                        }
                        predet={avatar}
                        alt={
                          user.personalData
                            ? `${user?.personalData?.firstName} ${user?.personalData?.lastName}`
                            : ''
                        }
                      />
                    </div>
                    <div>
                      <Row style={{ justifyContent: 'center' }}>
                        <div className="text-dark font-weight-bold font-size-18">
                          {user?.personalData && user?.personalData?.firstName} {user?.personalData && user?.personalData?.lastName}
                        </div>
                        <div className={`${style.headerStatus}`} style={{ margin: '3px 0 0 10px' }}>
                          <div style={{ marginRight: 25 }}>
                            <div>
                              {user.status ? (
                                <>
                                  <img style={{ marginBottom: 3 }} src={activepoint} alt="" />
                                  <span style={{ marginLeft: 5 }}>
                                    <FormattedMessage id="ACTIVE" />
                                  </span>
                                </>
                              ) : (
                                <>
                                  <img style={{ marginBottom: 3 }} src={inactivepoint} alt="" />
                                  <span style={{ marginLeft: 5 }}>
                                    <FormattedMessage id="INACTIVE" />
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </Row>
                      <div className={style.headertitle}>
                        <b>{user.organizationData ? user.organizationData.position : null} </b>|{' '}
                        <FormattedMessage id="LegacyNumber" />:{' '}
                        {user.labourData ? user.labourData.fileNumber : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
export default Header
