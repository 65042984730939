import api, { del, get, patch, post } from 'api/api-connection'

const base = `workdays`

export const updateReports = report =>
  api
    .patch(`/workdays/reportErrorRekognition/addreview/${report._id}`, report)
    .then(response => response.data.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

export const getClocksBetweenDates = async ({ start, end, userId }) => {
  try {
    const response = await workdayApi.getWorkdaybyEmployeeId({
      userId,
      query: { limit: 1000, filter: { date: { $gte: start, $lt: end } } },
    })
    const { workdays, clocks } = response.values
    return workdays.map(workday => workday.clocks?.map(clock => clocks[clock])).flat(100)
  } catch (error) {
    console.error({ error })
    return []
  }
}

export const workdayApi = {
  getWorkdaybyEmployeeId: payload => {
    return post(`${base}/history/${payload.userId}`, '', payload.query)
  },
  deleteWorkdaybyEmployeeId: payload => {
    return del(`${base}/${payload}`)
  },
  getReportsByCompanyId: () => {
    return ''
    /* return post(`/workdays/reportErrorRekognition/getFiltered`) */
  },
  /*   updateReports: payload => {
    return post(`/workdays/report`, {}, payload)
  }, */
  modifiedWorkday: payload => {
    return patch(`/workdays/update`, {}, payload)
  },
}

export const deleteWorkdaybyId = workdayId => {
  return api.delete(`/workdays/${workdayId}`)
}

/* export default workdayApi
 */
