import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'
// import Loader from 'components/cleanui/layout/Loader'
import { useReFetch } from 'api/useFetch'
import { getUser, userIsAuthorized, userLoadings } from 'redux-fichap/user/selectors'
import { getUserLogin } from 'redux-fichap/user/actions'
import { LOAD_CURRENT_ACCOUNT } from 'redux-fichap/user/types'
import { getCompanyData } from 'redux-fichap/company/action'
import { getBranchOfficesTree } from 'redux-fichap/branchoffices/actions'
import { redirectLogin } from 'utils/auth'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
}

let previousPath = ''

/* eslint-disable */

const Layout = props => {
  // NProgress & ScrollTop Management
  const {
    user,
    getUserLogin,
    getCompanyData,
    /* getBranchOfficesTree, */
    loadings,
    authorized,
    children,
    location: { pathname, search },
  } = props
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    // NProgress.start()
  }
  setTimeout(() => {
    // NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/') {
      return 'public'
    }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = authorized
  const isUserLoading = loadings ? loadings[LOAD_CURRENT_ACCOUNT.CUSTOM] : false
  const isAuthLayout = getLayout() === 'auth'

  if (user && Object.keys(user).length === 0 && !isUserLoading && !isAuthLayout) {
    getUserLogin()
    getCompanyData()
    /* getBranchOfficesTree() */
  }

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && !isUserAuthorized) {
      return redirectLogin()
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="Fichap Dashboard | %s" title="Fichap Dashboard" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

const mapStateToProps = state => ({
  user: getUser(state),
  loadings: userLoadings(state),
  authorized: userIsAuthorized(state),
})

const mapActionsToProps = dispatch => ({
  getUserLogin: bindActionCreators(getUserLogin, dispatch),
  getCompanyData: bindActionCreators(getCompanyData, dispatch),
  getBranchOfficesTree: bindActionCreators(getBranchOfficesTree, dispatch),
})
export default withRouter(connect(mapStateToProps, mapActionsToProps)(Layout))
