import { RESTART_STATE } from 'redux-fichap/user/types'
import { parseActionType } from 'utils'
import {
  CREATE_ABSENCE,
  GET_REASONS_ABSENCES,
  UPDATE_REASON_ABSENCE,
  CREATE_REASON_ABSENCE,
  DELETE_REASON_ABSENCE,
  GET_DAYOFFS,
  GET_DAYOFF_BY_ID,
  UPDATE_DAYOFF,
  UPDATE_REVIEW,
  CLEAR_ERROR,
} from './types'

const INITIAL_STATE = {
  error: null,
  loading: {},
  absenceCreated: null,
  absenceUpdated: true,
}

export default (state = INITIAL_STATE, action) => {
  const actionType = parseActionType(action.type)

  switch (action.type) {
    case GET_DAYOFF_BY_ID.REQUEST:
    case GET_DAYOFFS.REQUEST:
    case UPDATE_DAYOFF.REQUEST:
    case CREATE_ABSENCE.REQUEST:
    case GET_REASONS_ABSENCES.REQUEST:
    case CREATE_REASON_ABSENCE.REQUEST:
    case UPDATE_REASON_ABSENCE.REQUEST:
    case UPDATE_REVIEW.REQUEST:
    case DELETE_REASON_ABSENCE.REQUEST:
      return { ...state, loading: { [actionType]: true } }

    case GET_REASONS_ABSENCES.SUCCESS:
      return { ...state, loading: { [actionType]: false }, absences: action.payload }

    case UPDATE_REASON_ABSENCE.SUCCESS:
      return { ...state, loading: { [actionType]: false } }

    case CREATE_REASON_ABSENCE.SUCCESS:
      return { ...state, loading: { [actionType]: false } }

    case DELETE_REASON_ABSENCE.SUCCESS:
      return { ...state, loading: { [actionType]: false } }

    case GET_DAYOFFS.SUCCESS:
      return { ...state, loading: { [actionType]: false }, dayoffs: action.payload }

    case GET_DAYOFF_BY_ID.SUCCESS:
      return { ...state, loading: { [actionType]: false } }

    case UPDATE_DAYOFF.SUCCESS:
      return { ...state, loading: { [actionType]: false }, absenceUpdated: true }

    case UPDATE_REVIEW.SUCCESS:
      return { ...state, loading: { [actionType]: false } }

    case CREATE_ABSENCE.SUCCESS:
      return { ...state, loading: { [actionType]: false }, absenceCreated: false }

    case GET_DAYOFF_BY_ID.FAILURE:
    case GET_DAYOFFS.FAILURE:
    case CREATE_REASON_ABSENCE.FAILURE:
    case DELETE_REASON_ABSENCE.FAILURE:
    case UPDATE_REVIEW.FAILURE:
    case UPDATE_REASON_ABSENCE.FAILURE:
      return { ...state, loading: { [actionType]: false }, error: action.payload || true }

    case CREATE_ABSENCE.FAILURE:
      return {
        ...state,
        loading: { [actionType]: false },
        error: action.payload || true,
        absenceCreated: true,
      }

    case UPDATE_DAYOFF.FAILURE:
      return {
        ...state,
        loading: { [actionType]: false },
        error: action.payload || true,
        absenceUpdated: false,
      }

    case CLEAR_ERROR.REQUEST:
      return { ...state, loading: { [actionType]: true } }
    case CLEAR_ERROR.SUCCESS:
      return { ...state, loading: { [actionType]: false }, error: null }
    case CLEAR_ERROR.FAILURE:
      return {
        ...state,
        loading: { [actionType]: false },
        error: action.payload,
      }

    case RESTART_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
