import { RESTART_STATE } from 'redux-fichap/user/types'
import { parseActionType } from 'utils'
import {
  GET_COMPANY_DATA,
  GET_COMPANY_PAYMENTS,
  GET_COMPANY_PAYMENT_BY_ID,
  GET_COMPANY_PLAN,
  GET_SPECIAL_DAYS,
  UPDATE_COMPANY_DATA,
  UPDATE_SPECIAL_DAYS,
  DELETE_SPECIAL_DAYS,
  CREATE_SPECIAL_DAYS,
} from './types'

const INITIAL_STATE = {
  error: null,
  loading: {},
  companyData: {
    parameters: {
      specialDays: [],
      typesOfDayoff: [],
      typesOfFolder: [],
      typesOfWorkshift: [],
    },
    configuration: {
      covid: {
        symptoms: true,
        tips: true,
        enableMobile: false,
        enablePoint: false,
        temperatureThreshold: 37.5,
        requestTemperature: false,
      },
      notifications: {
        possibleSymptoms: {
          sms: false,
          email: false,
          dashboard: false,
        },
        employeeAbsences: {
          sms: false,
          email: false,
          dashboard: false,
        },
        employeePayroll: {
          sms: false,
          email: false,
          dashboard: false,
        },
        documentSignature: {
          sms: false,
          email: false,
          dashboard: false,
        },
        birthday: {
          sms: false,
          email: false,
          dashboard: false,
        },
      },
      general: {
        restrictLocalization: false,
        employeePortal: false,
        allowBreak: false,
        allowMobile: false,
        allowMobileMap: false,
        allowPoint: false,
        rangeOfBreak: [0, 8],
        toleranceEarlyDeparture: -1,
        toleranceLateArrival: -1,
        overtimeAllowed: -1,
        allowOvertime: false,
        allowRekognition: true,
      },
    },
    customFields: {
      unionAgreement: ['default'],
      laborUnion: ['default'],
      typeOfLeave: ['Despido', 'Incapacidad', 'Renuncia'],
      typeOfContract: ['default'],
      currentSituation: ['default'],
      holidayGroup: ['default'],
      quoteGroup: ['default'],
      hierarchies: ['position', 'Sector', 'Departamento'],
    },
  },
}

export default (state = INITIAL_STATE, action) => {
  const actionType = parseActionType(action.type)

  switch (action.type) {
    case GET_COMPANY_DATA.REQUEST:
    case GET_COMPANY_PAYMENTS.REQUEST:
    case GET_COMPANY_PAYMENT_BY_ID.REQUEST:
    case GET_COMPANY_PLAN.REQUEST:
    case GET_SPECIAL_DAYS.REQUEST:
    case UPDATE_COMPANY_DATA.REQUEST:
    case UPDATE_SPECIAL_DAYS.REQUEST:
    case DELETE_SPECIAL_DAYS.REQUEST:
    case CREATE_SPECIAL_DAYS.REQUEST:
      return { ...state, loading: { [actionType]: true } }

    case GET_COMPANY_DATA.SUCCESS:
      return {
        ...state,
        loading: { [actionType]: false },
        companyData: {
          ...action.payload,
          businesses: action.payload.businesses instanceof String ? [] : action.payload.businesses,
        },
      }

    case GET_COMPANY_PAYMENTS.SUCCESS:
      return { ...state, loading: { [actionType]: false }, companyPayments: action.payload }

    case GET_COMPANY_PAYMENT_BY_ID.SUCCESS:
      return { ...state, loading: { [actionType]: false }, payment: action.payload }

    case GET_COMPANY_PLAN.SUCCESS:
      return { ...state, loading: { [actionType]: false }, companyPlan: action.payload }

    case GET_SPECIAL_DAYS.SUCCESS:
      return { ...state, loading: { [actionType]: false }, specialDays: action.payload }

    case CREATE_SPECIAL_DAYS.SUCCESS:
    case UPDATE_COMPANY_DATA.SUCCESS:
    case UPDATE_SPECIAL_DAYS.SUCCESS:
    case DELETE_SPECIAL_DAYS.SUCCESS:
      return { ...state, loading: { [actionType]: false } }

    case CREATE_SPECIAL_DAYS.FAILURE:
    case GET_COMPANY_DATA.FAILURE:
    case GET_COMPANY_PAYMENTS.FAILURE:
    case GET_COMPANY_PAYMENT_BY_ID.FAILURE:
    case GET_COMPANY_PLAN.FAILURE:
    case GET_SPECIAL_DAYS.FAILURE:
    case UPDATE_COMPANY_DATA.FAILURE:
    case UPDATE_SPECIAL_DAYS.FAILURE:
    case DELETE_SPECIAL_DAYS.FAILURE:
      return { ...state, loading: { [actionType]: false }, error: action.payload || true }
    case RESTART_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
