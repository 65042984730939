import api, { patch, uploadFile } from 'api/api-connection'
import cleanDeep from 'clean-deep'
import store from 'store'

const fetchCompanyData = () =>
  new Promise((resolve, reject) => {
    const company = store.get('COMPANY_DATA', null)
    if (company) {
      resolve(company)
    } else {
      api
        .get('/companies/byAuthUserCompany')
        .then(response => {
          store.set('COMPANY_DATA', response.data.data)
          resolve(response.data.data)
        })
        .catch(err => {
          reject(new Error(err))
        })
    }
  })

const createCompany = company =>
  api
    .post('/companies', company)
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const updateCompany = company => {
  try {
    // const filteredInput = cleanDeep(company)
    const filteredInput = company

    const formData = new FormData()

    for (const key in filteredInput) {
      if (!['image', 'hugeLogo', 'carrousel1', 'carrousel2', 'carrousel3'].includes(key)) {
        formData.append(key, JSON.stringify(filteredInput[key]))
      } else {
        if (filteredInput[key]) {
          formData.append(
            key,
            new Blob([new Uint8Array(filteredInput[key].arrayBuffer)], {
              type: filteredInput[key].type,
            }),
          )
        }
      }
    }

    return uploadFile('patch', `/companies`, formData)
    // }
    // return patch(`/companies`, {}, filteredInput).then(response => {
    //   store.set('COMPANY_DATA', response.data)
    //   return response
    // })
  } catch (e) {
    console.error(e)
  }
}

const fetchCompanyById = id =>
  api
    .get(`/companies/${id}`)
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const fetchSpecialDays = () =>
  new Promise((resolve, reject) => {
    const specialDay = store.get('SPECIAL_DAY', null)

    if (specialDay) {
      resolve(specialDay)
    } else {
      api
        .get('/companies/specialDays')
        .then(response => {
          store.set('SPECIAL_DAY', response.data.data)
          resolve(response.data.data)
        })
        .catch(err => reject(new Error(err)))
    }
  })

const updateSpecialDays = specialDay =>
  api
    .patch(`/companies/specialDays/${specialDay._id}`, specialDay)
    .then(response => {
      store.set('SPECIAL_DAY', null)
      return response.data
    })
    .catch(err => {
      throw new Error(err)
    })

const deleteSpecialDays = id =>
  api
    .delete(`/companies/specialDays/${id}`)
    .then(response => {
      store.set('SPECIAL_DAY', null)
      return response.data
    })
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const createSpecialDays = specialDay =>
  api
    .post('/companies/specialDays', specialDay)
    .then(response => {
      store.set('SPECIAL_DAY', null)
      return response.data
    })
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

export {
  fetchCompanyData,
  createCompany,
  updateCompany,
  fetchCompanyById,
  fetchSpecialDays,
  updateSpecialDays,
  deleteSpecialDays,
  createSpecialDays,
}
