import { RESTART_STATE } from 'redux-fichap/user/types'
import { parseActionType } from 'utils'
import { GET_WORKSHIFTS, CREATE_WORKSHIFT, UPDATE_WORKSHIFT, DELETE_WORKSHIFT } from './types'

const INITIAL_STATE = {
  loading: {},
  error: null,
  workshifts: [],
}

export default (state = INITIAL_STATE, action) => {
  const actionType = parseActionType(action.type)

  switch (action.type) {
    case GET_WORKSHIFTS.REQUEST:
    case CREATE_WORKSHIFT.REQUEST:
    case UPDATE_WORKSHIFT.REQUEST:
    case DELETE_WORKSHIFT.REQUEST:
      return { ...state, loading: { [actionType]: true } }

    case GET_WORKSHIFTS.SUCCESS:
      return { ...state, loading: { [actionType]: false }, workshifts: action.payload }

    case CREATE_WORKSHIFT.SUCCESS:
    case UPDATE_WORKSHIFT.SUCCESS:
    case DELETE_WORKSHIFT.SUCCESS:
      return { ...state, loading: { [actionType]: false } }

    case GET_WORKSHIFTS.FAILURE:
    case CREATE_WORKSHIFT.FAILURE:
    case UPDATE_WORKSHIFT.FAILURE:
    case DELETE_WORKSHIFT.FAILURE:
      return { ...state, loading: { [actionType]: false }, error: action.payload }
    case RESTART_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
