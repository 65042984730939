import store from 'store'
import { parseActionType } from 'utils'
import {
  LOGIN,
  LOAD_CURRENT_ACCOUNT,
  LOGOUT,
  RESTART_STATE,
  MANTEINANCE,
  UPDATE_PROFILE_IMAGE,
  GET_ROLES_BY_VIEWS,
} from './types'

const initialState = {
  user: {},
  authorized: store.get('authorized') || false, // false is default value
  loading: {},
  errors: {},
  manteinance: false,
  company: store.get('company') || '',
  rolesPermissions: store.get('roles') || null,
}

// id: '',
//   name: '',
//   email: '',
//   avatar: '',
//   phone: '',
//   role: '',

export default function userReducer(state = initialState, action) {
  const actionType = parseActionType(action.type)
  switch (action.type) {
    case MANTEINANCE.REQUEST:
    case UPDATE_PROFILE_IMAGE:
    case LOGIN.REQUEST:
    case LOAD_CURRENT_ACCOUNT.REQUEST:
    case GET_ROLES_BY_VIEWS.REQUEST:
      return { ...state, loading: { [actionType]: true } }
    case LOAD_CURRENT_ACCOUNT.SUCCESS:
      return {
        ...state,
        loading: { [actionType]: false },
        errors: { [actionType]: null },
        user: action.payload,
      }
    case GET_ROLES_BY_VIEWS.SUCCESS:
      store.set('roles', action.payload)
      return {
        ...state,
        loading: { [actionType]: false },
        errors: { [actionType]: null },
        rolesPermissions: action.payload,
      }
    case LOGIN.SUCCESS:
      store.set('authorized', true)
      return {
        ...state,
        loading: { [actionType]: false },
        errors: { [actionType]: null },
        authorized: true,
      }

    case MANTEINANCE.SUCCESS:
      return {
        ...state,
        loading: { [actionType]: false },
        manteinance: action.payload.data,
      }

    case UPDATE_PROFILE_IMAGE.SUCCESS:
      return {
        ...state,
        loading: { [actionType]: false },
      }

    case LOAD_CURRENT_ACCOUNT.FAILURE:
    case LOGIN.FAILURE:
      return {
        ...state,
        loading: { [actionType]: false },
        errors: { [actionType]: action.payload },
        authorized: false,
      }

    case GET_ROLES_BY_VIEWS.FAILURE:
    case UPDATE_PROFILE_IMAGE.FAILURE:
    case MANTEINANCE.FAILURE:
      return {
        ...state,
        loading: { [actionType]: false },
        error: action.payload || true,
      }

    case RESTART_STATE:
      return { initialState }

    case LOGOUT:
      return initialState

    default:
      return state
  }
}
