export const companyData = state => state?.company?.companyData
export const companyLoadings = state => state?.company?.loading
export const getFolder = state => state?.company?.companyData?.customFields?.typesOfFolder
export const getNotifications = state => state?.company?.companyData?.configuration?.notifications
export const getGeneralConfig = state => state?.company?.companyData?.configuration?.general
export const getWorkday = state => state?.company?.companyData?.customFields?.typesOfWorkshift
export const getSpecialDays = state => state?.company?.companyData?.customFields?.specialDays
export const getCovid = state => state?.company?.companyData?.configuration?.covid
export const getCustomData = state => state?.company?.companyData?.customFields
export const getTypeOfLeave = state => state?.company?.companyData?.customFields?.typeOfLeave
export const getTypeOfFolder = state => state?.company?.companyData?.customFields?.typesOfFolder
export const getHierarchies = state => state?.company?.companyData?.customFields?.hierarchies
export const getTypesOfDayoff = state => state?.company?.companyData?.customFields?.typesOfDayoff
