import {
  CLEAR_WORKDAYS,
  DELETE_WORKDAY_BY_EMPLOYEE_ID,
  GET_REPORTS_COMPANY,
  GET_WORKDAYS_BY_EMPLOYEE_ID,
  MODIFIE_WORKDAY,
  SELECTED_WORKDAY_DETAILE,
  UPDATE_REPORT,
  GET_WORKDAYS_BY_COMPANY_ID,
} from './types'

export const getWorkdaysByEmployeeId = payload => ({
  type: GET_WORKDAYS_BY_EMPLOYEE_ID.REQUEST,
  payload,
})

export const clearWorkdays = () => ({
  type: CLEAR_WORKDAYS,
})

export const selectWorkday = payload => ({
  type: SELECTED_WORKDAY_DETAILE,
  payload,
})

export const getWorkdaysByCompanyId = payload => ({
  type: GET_WORKDAYS_BY_COMPANY_ID.REQUEST,
  payload,
})

export const deleteWorkdayByEmployeeId = payload => ({
  type: DELETE_WORKDAY_BY_EMPLOYEE_ID.REQUEST,
  payload,
})

export const getReportByCompanyId = payload => ({
  type: GET_REPORTS_COMPANY.REQUEST,
  payload,
})
export const updateReport = payload => ({
  type: UPDATE_REPORT.REQUEST,
  payload,
})

export const modifiedWorkday = payload => ({
  type: MODIFIE_WORKDAY.REQUEST,
  payload,
})
