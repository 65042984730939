import { createRequestTypes } from '../../utils'

const namespace = 'reports'

export const GET_DETAILED_REPORT = createRequestTypes(`${namespace}/GET_DETAILED_REPORT`)

export const GET_GENERAL_REPORT = createRequestTypes(`${namespace}/GET_GENERAL_REPORT`)

export const GET_PAYROLL_REPORT = createRequestTypes(`${namespace}/GET_PAYROLL_REPORT`)

export const CLEAN_PAYROLL_REPORT = createRequestTypes(`${namespace}/CLEAN_PAYROLL_REPORT`)
