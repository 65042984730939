// Por el momento estos están hardcodeados aunque lo ideal sería crear esto desde el backend
export const MODULES_FICHAP = {
  USERS: 'users',
  SHIFTS: 'shifts',
  DAYOFFS: 'dayoffs',
  DOCUMENTS: 'document',
  INVENTORY: 'inventory',
  NEWS: 'news',
  WORKDAYS: 'workdays',
  WORKDAYS_REVIEWS: 'workdays.review',
  UNACTIVE_USERS: 'users.offboarded',
  DAYOFFS_REVIEWS: 'dayoffs.manage',
  DAYOFFS_CONFIG: 'dayoffs.config',
  VACATION_CONFIG: 'vacation.matrices',
  COMPANY_PROFILE: 'company.profile',
  REVIEWS_APPROVALS: 'ReviewAndApprovals',
  BRANCHES: 'branches',
  HIERARCHIES: 'structures',
  POSITIONS: 'structures', // TODO: SEPARARLO
  SPECIAL_DAYS: 'specialdays',
  ORGANIZATION_CHART: 'organizationChart',
  SALARY: 'salary',
  COMUNICATION: 'comunication',
  COMPANY: 'company',
  CUSTOM_FIELDS: 'customFields',
  SELF: 'self',
  WORK_ORDERS: 'TASKS',
}

export const ACTIONS = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DEL: 'DEL',
  DOWNLOAD: 'DOWNLOAD',
}
