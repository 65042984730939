import React from 'react'
import { ConfigProvider } from 'antd'
import { RawIntlProvider } from 'react-intl'
import { connect } from 'react-redux'

import { generateIntl } from 'locales/IntlGlobalProvider'
import portuguese from './locales/pt-BR'
import spanish from './locales/es-ES'
import english from './locales/en-US'

const locales = {
  'pt-BR': portuguese,
  'es-ES': spanish,
  'en-US': english,
}

const mapStateToProps = ({ settings }) => ({ settings })

const Localization = ({ children, settings: { locale } }) => {
  const currentLocale = locales[locale]
  // const Localization = ({ children}) => {
  //   const currentLocale = locales["en-US"]
  const intlValue = generateIntl({onError: ()=>{}, locale: currentLocale.locale, messages: currentLocale.messages })
  return (
    <ConfigProvider locale={currentLocale.localeAntd}>
      <RawIntlProvider value={intlValue}>{children}</RawIntlProvider>
    </ConfigProvider>
  )
}

export default connect(mapStateToProps)(Localization)
