/* eslint-disable class-methods-use-this */
import store from 'store'
import apiConnection, { post, get } from 'api/api-connection'

export const genUserInfo = data => ({
  id: data._id,
  userId: data._id,
  firstName: data.personalData?.firstName,
  lastName: data.personalData?.lastName,
  position: data.organizationData.position,
  document: data.personalData?.numberOfDocument,
  branchOffice: {
    id: data.organizationData?.mainBranch?.branchId,
    name: data.organizationData?.mainBranch?.branchName,
  },
  companyId: data.companyId,
  image: data.personalData.imageURL,
})

export const getCurrentUser = () => {
  if (store.get('USER_DATA')) return store.get('USER_DATA')
}

export const currentUser = getCurrentUser()

export const allowedRoles = store.get('allowedRoles')

export const currentAccount = () => {
  const token = store.get('accessToken')
  return new Promise((resolve, reject) => {
    if (token) {
      apiConnection
        .get('/users/profile/get')
        .then(response => {
          if (response) {
            resolve(response.data.data)
          }
          reject()
        })
        .catch(err => reject(err))
    }
  })
}
class UserDefault {
  static _id

  currentAccount = currentAccount

  async getById(id) {
    return get(`/users/${id}`)
  }

  async generateUserInfomationById(userId) {
    return new Promise((resolve, reject) => {
      try {
        get(`/users/${userId}`).then(({ data }) => {
          genUserInfo(data)
        })
      } catch (error) {
        console.error(error)
        resolve(null)
      }
    })
  }

  generateUserInfomation(user) {
    return genUserInfo(user)
  }
}

const User = new UserDefault()

export { User }
export default UserDefault
