import React from 'react'
import moment from 'moment'
import { translate } from 'locales/IntlGlobalProvider'
import { parseDuration } from 'utils/parseHours'
import { months } from '../../components/months'

export const columns = month => {
  const numberOfColumns = months[month]?.days
  const totalColumns = []
  totalColumns.push({
    title: 'OT',
    dataIndex: 'otTitle',
    key: 'otTitle',
    fixed: 'left',
    width: 160,
    download: (text, record) => record?.otTitle,
    render: (text, record) => <span>{record?.otTitle}</span>,
  })
  for (let index = 0; index < numberOfColumns; index++) {
    totalColumns.push({
      title: `${moment(`${moment().year()}-${month + 1}-${index + 1}`).format('DD')} ${translate(
        moment(`${moment().year()}-${month + 1}-${index + 1}`).format('ddd'),
      )}`,
      className: 'title',
      key: `${index + 1}`,
      dataIndex: `${index + 1}`,
      width: 100,
      render: v => (v ? `${parseDuration(v, 'minutes')} hs` : '-'),
    })
  }
  totalColumns.push({
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    fixed: 'right',
    width: 120,
    render: (text, record) => (
      <span>{record.total ? parseDuration(record.total, 'minutes') : null} hs</span>
    ),
    download: (text, record) =>
      record.total ? `${parseDuration(record.total, 'minutes')} hs` : null,
  })
  return totalColumns
}
