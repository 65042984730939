import { createRequestTypes } from '../../utils'

const namespace = 'workday'
export const GET_WORKDAYS_BY_EMPLOYEE_ID = createRequestTypes(
  `${namespace}/GET_WORKDAYS_BY_EMPLOYEE_ID`,
)
export const GET_WORKDAYS_BY_COMPANY_ID = createRequestTypes(
  `${namespace}/GET_WORKDAYS_BY_COMPANY_ID`,
)

export const DELETE_WORKDAY_BY_EMPLOYEE_ID = createRequestTypes(
  `${namespace}/DELETE_WORKDAY_BY_EMPLOYEE_ID`,
)

export const SELECTED_WORKDAY_DETAILE = `${namespace}/SELECTED_WORKDAY_DETAILE`
export const CLEAR_WORKDAYS = `${namespace}/CLEAR_WORKDAYS`
export const GET_REPORTS_COMPANY = createRequestTypes(`${namespace}/GET_REPORTS_COMPANY`)
export const UPDATE_REPORT = createRequestTypes(`${namespace}/UPDATE_REPORT`)
export const MODIFIE_WORKDAY = createRequestTypes(`${namespace}/MODIFIE_WORKDAY`)
