/* eslint-disable import/no-named-as-default-member */
import { getDismissedTable } from 'redux-fichap/dismissedTable/actions'
import api from 'api/api-connection'
import { get, patch, uploadFile, post, del } from '../../api/api-connection'

const cleanDeep = require('clean-deep')

export async function getEmployees(payload) {
  return post('/users/getFiltered', {}, payload)
}

export async function createEmployee(values) {
  const input = {
    username: values.username,
    contactData: {
      primaryEmail: values.email,
    },
    personalData: {
      firstName: values.firstName,
      lastName: values.lastName,
      typeOfDocument: values.typedocument,
      numberOfDocument: values.document,
    },
    organizationData: {
      mainBranch: values.branch,
    },
  }

  const res = await post('/users', {}, input)
  if (res) {
    return true
  }
}

export async function enabledDisabledEmployee(id, payload, dispatch) {
  return patch(`/users/employee-status/${id}`, {}, payload)
    .then(response => {
      if (response.statusCode === 200) {
        getDismissedTable(dispatch)
      } else {
        console.error({ response })
      }
    })
    .catch(err => {
      console.error(err)
    })
}

export async function deleteEmployee(id, dispatch) {
  return del(`/users/${id}`)
    .then(response => {
      if (response.statusCode === 200) {
        getDismissedTable(dispatch)
      } else {
        console.error({ response })
      }
    })
    .catch(err => {
      console.error(err)
    })
}

export async function getEmployeeById(id) {
  return get(`/users/${id}`)
}

export async function updateEmployee(id, payload, file) {
  // si la propiedad "notCleanDeep" está activada, no limpia los datos nulls o undefined
  const filteredInput = payload.notCleanDeep
    ? payload
    : cleanDeep(payload, { emptyStrings: false, nullValues: false })
  const se = `/users/${id}`
  return api.patch(se, filteredInput)
}

export async function updateProfileImage(id, file) {
  const formData = new FormData()
  formData.append('image', new Blob([new Uint8Array(file.arrayBuffer)], { type: file.type }))
  const se = `/users/image/${id}`
  return api.patch(se, formData)
}

export const getCalendarById = id =>
  post('/shifts/getFiltered', { filters: { userId: id } })
    .then(response => response.data.data)
    .catch(err => {
      throw new Error(err)
    })

export const updateCalendar = events =>
  post('/workshift/calendarToUserShift', events)
    .then(() => 'ok')
    .catch(err => {
      throw new Error(err)
    })
