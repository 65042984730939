import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import { getUser } from 'redux-fichap/user/selectors'
import avatarDefault from 'assets/svg/avatar-default.svg'
import { bindActionCreators } from 'redux'
import { logout } from 'redux-fichap/user/actions'
import { Link } from 'react-router-dom'
import rocket from 'assets/svg/rocket.svg'
import styles from './style.module.scss'

class ProfileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 0,
    }
    this.menu = this.menu.bind(this)
    this.setCount = this.setCount.bind(this)
  }

  setCount() {
    /* eslint-disable */
    this.setState({ count: this.state.count++ })
  }

  menu() {
    const { user, logout } = this.props
    const {
      contactData: { primaryAreaCode, primaryCountryCode, primaryPhone },
    } = user
    return (
      <Menu style={{ padding: 0 }} className={styles.dropdown_usermenu} selectable={false}>
        <Menu.Item
          style={{ padding: '12px 12px', marginTop: 0, fontSize: 15, background: '#F0F3F4' }}
          className={styles.dropdown_usermenu_item}
        >
          <strong className={styles.dropdown_usermenu_name}>
            <FormattedMessage id="topBar.profileMenu.hello" />,{' '}
            {user.personalData ? user.personalData.firstName : ''} <img src={rocket} alt="" />
          </strong>
          <div style={{ fontSize: 13 }}>
            <strong>
              <FormattedMessage id="topBar.profileMenu.role" />:{' '}
            </strong>
            {user.organizationData ? user.organizationData.position : '—'}
          </div>
        </Menu.Item>
        <Menu.Item className={styles.dropdown_usermenu_item}>
          <Link to="/profile" className={styles.dropdown_usermenu_item}>
            <i className="fe fe-user mr-2" />
            <FormattedMessage id="topBar.profileMenu.editProfile" />
          </Link>
        </Menu.Item>
        <Menu.Item className={styles.dropdown_usermenu_item}>
          <a href="#" onClick={logout} className={styles.dropdown_usermenu_item}>
            <i className="fe fe-log-out mr-2" />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
  }

  render() {
    const { count } = this.state
    const { user } = this.props

    return (
      <React.Fragment>
        {user && Object.keys(user).length ? (
          <Dropdown
            className={styles.dropdown_container}
            overlay={this.menu()}
            trigger={['click']}
            onVisibleChange={this.setCount}
          >
            <div className={styles.dropdown}>
              <Badge count={count}>
                <Avatar
                  className={styles.avatar}
                  shape="square"
                  size="large"
                  src={
                    user.personalData.imageURL !== undefined
                      ? user.personalData.imageURL
                      : avatarDefault
                  }
                />
              </Badge>
            </div>
          </Dropdown>
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: getUser(state),
})
const mapActionsProps = dispatch => ({
  logout: bindActionCreators(logout, dispatch),
})

export default connect(mapStateToProps, mapActionsProps)(ProfileMenu)
