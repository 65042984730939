import { createRequestTypes } from '../../utils'

const namespace = 'absences'

export const GET_REASONS_ABSENCES = createRequestTypes(`${namespace}/GET_REASONS_ABSENCES`)

export const CREATE_REASON_ABSENCE = createRequestTypes(`${namespace}/CREATE_REASON_ABSENCE`)

export const UPDATE_REASON_ABSENCE = createRequestTypes(`${namespace}/UPDATE_REASON_ABSENCE`)

export const DELETE_REASON_ABSENCE = createRequestTypes(`${namespace}/DELETE_REASON_ABSENCE`)

export const CREATE_ABSENCE = createRequestTypes(`${namespace}/CREATE_ABSENCE`)

export const GET_DAYOFFS = createRequestTypes(`${namespace}/GET_DAYOFFS`)

export const GET_DAYOFF_BY_ID = createRequestTypes(`${namespace}/GET_DAYOFF_BY_ID`)

export const UPDATE_DAYOFF = createRequestTypes(`${namespace}/UPDATE_DAYOFF`)

export const UPDATE_REVIEW = createRequestTypes(`${namespace}/UPDATE_REVIEW`)

export const CLEAR_ERROR = createRequestTypes(`${namespace}/CLEAR_ERROR`)
