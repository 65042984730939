export default {
  specialDays: {
    labels: {
      dateFrom: 'Fecha',
      dateTo: '', // TODO: sacar
      isRecurring: 'Repetir todos los años', // TODO: sacar
      name: 'Nombre',
      category: 'Categoría',
      countries: 'Países',
      branches: 'Sucursales',
      description: 'Descripción',
      isDeductedFromVacation: 'Descuenta de vacaciones',
      impactedPayroll: 'Impacta en nómina',
    },
    placeholders: {
      dateFrom: 'Desde',
      dateTo: 'Hasta',
      isRecurring: 'Repetir todos los años', // TODO: sacar
      name: 'Día Especial',
      category: 'Seleccionar categoría',
      countries: 'Seleccionar países',
      branches: 'Seleccionar sucursales',
      description: 'Texto aquí', // TODO: dejar por defecto usando Arr::getData()
      isDeductedFromVacation: 'Descuenta de vacaciones',
      impactedPayroll: 'Impacta en nómina',
    },
  },
}
