import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Carousel } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { useLocation } from 'react-router'
import { useReFetch } from 'api/useFetch'
import { COMPANIES } from 'entities/company'
import classNames from 'classnames'
import LanguageSwitcher from 'components/common/LanguageSwitcher'
import { FormattedMessage } from 'react-intl'
import useWindowWidth from 'base/useWindowWidth'
// import whiteLogo from 'assets/svg/white-logo.svg' sportlineimg
// import sportline from 'assets/svg/sportlineimg.png'
// import sport2 from 'assets/svg/sportline2.png'
import logoPurple from 'assets/svg/fichap-logo.svg'
import whiteLogo from 'assets/svg/FichapLogo.svg'
import logoDotBlack from 'assets/svg/logoDotBlack.svg'
// import sport from 'assets/svg/sportline.png'
// import logo from 'assets/svg/sportlineLOGO.png'
import style from './style.module.scss'
import imgOne from '../../../../../assets/svg/login_1.svg'
import imgTwo from '../../../../../assets/svg/login_2.svg'
import imgThree from '../../../../../assets/svg/login_3.svg'
import imgFour from '../../../../../assets/svg/login_4.svg'
import imgFive from '../../../../../assets/svg/login_5.svg'
import './auth.sass'

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
})

const AuthLayout = ({
  children,
  logo,
  isGrayTopbar,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
}) => {
  const windowWidth = useWindowWidth()

  const [current, setCurrent] = useState(0)

  const location = useLocation()

  // SportLineID: 653b3894a17e7800126a8041
  const { data } = useReFetch(
    {
      url: '/companies/loginData/653b3894a17e7800126a8041',
      default: [],
      method: 'get',
      //  reset: true,
    },
    [],
  )

  const CarouselImgs = data?.data?.loginCarrousel

  return (
    <Layout>
      <Layout.Content>
        {location.pathname === '/auth/login/4people' ? (
          <>
            <div
              className={classNames(`${style.container} authContainer `, {
                cui__layout__squaredBorders: isSquaredBorders,
                cui__layout__cardsShadow: isCardShadow,
                cui__layout__borderless: isBorderless,
                [style.white]: authPagesColor === 'white',
                [style.gray]: authPagesColor === 'gray',
              })}
              style={{
                backgroundImage:
                  authPagesColor === 'image' ? 'url(resources/images/content/photos/7.jpg)' : '',
              }}
            >
              <div className={style.authMainContainer}>
                <div className={style.leftSide}>
                  <Carousel
                    afterChange={current => setCurrent(current)}
                    className={style.carousel}
                    effect="fade"
                    autoplay
                    autoplaySpeed={6000}
                  >
                    {CarouselImgs &&
                      CarouselImgs.map((url, index) =>
                        url ? (
                          <div className={style.leftContent} key={index}>
                            <img className={style.imgLeftSide} src={url} alt="CarouselLogin" />
                          </div>
                        ) : null,
                      )}
                  </Carousel>
                </div>
                <div className={style.rightContainer}>
                  <div className={style.containerInner}>{children}</div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={style.useractions}>
              {/* <SideBar /> oculto por ahora */}
              {/* <SupportChat /> */}
            </div>
            <div
              className={classNames(`${style.container} authContainer `, {
                cui__layout__squaredBorders: isSquaredBorders,
                cui__layout__cardsShadow: isCardShadow,
                cui__layout__borderless: isBorderless,
                [style.white]: authPagesColor === 'white',
                [style.gray]: authPagesColor === 'gray',
              })}
              style={{
                backgroundImage:
                  authPagesColor === 'image' ? 'url(resources/images/content/photos/7.jpg)' : '',
              }}
            >
              <div
                className={classNames(`${style.topbar} topLogoLinksContainer`, {
                  [style.topbarGray]: isGrayTopbar,
                })}
              >
                <div className={style.logoContainer + ' logoContainer'}>
                  <div className={style.logo}>
                    {windowWidth < 900 ? (
                      <img
                        style={{ zIndex: 2 }}
                        width="160px"
                        src={logoPurple}
                        className="mr-6 ml-2"
                        alt="Fichap"
                      />
                    ) : (
                      <div className={style.logo}>
                        {current === 0 && (
                          <img
                            style={{ zIndex: 2 }}
                            width="160px"
                            src={whiteLogo}
                            className="mr-2"
                            alt="Fichap"
                          />
                        )}
                        {current === 1 && (
                          <img
                            style={{ zIndex: 2 }}
                            width="160px"
                            src={whiteLogo}
                            className="mr-2"
                            alt="Fichap"
                          />
                        )}
                        {current === 2 && (
                          <img
                            style={{ zIndex: 2 }}
                            width="160px"
                            src={logoDotBlack}
                            className="mr-2"
                            alt="Fichap"
                          />
                        )}
                        {current === 3 && (
                          <img
                            style={{ zIndex: 2 }}
                            width="160px"
                            src={whiteLogo}
                            className="mr-2"
                            alt="Fichap"
                          />
                        )}
                      </div>
                    )}
                    <div className={style.name}>{logo}</div>
                    {logo === 'Fichap'}
                  </div>
                </div>

                <div className={style.topBar + ' topBarLinks'}>
                  <div className={style.createaccount}>
                    <LanguageSwitcher />
                  </div>
                  <a className={style.createaccount}>
                    <FormattedMessage id="login.createaccount" />
                  </a>
                  <a className="font-size-16 kit__utils__link-main ml-2" href="https://fichap.com/">
                    <FormattedMessage id="login.starttoday" />
                  </a>
                </div>
              </div>
              <div className={style.authMainContainer}>
                <div className={style.leftSide}>
                  <Carousel
                    afterChange={current => setCurrent(current)}
                    className={style.carousel}
                    effect="fade"
                    autoplay
                    autoplaySpeed={6000}
                  >
                    <div className={`${style.imgLeftSide} ${style['bg-violet']}`}>
                      <div className={style.imgContainer}>
                        <img
                          style={{ height: 170, marginBottom: 25 }}
                          className="img_bottom"
                          src={imgTwo}
                          alt=""
                        />
                        <img style={{ height: 230 }} className="img_bottom" src={imgOne} alt="" />
                      </div>
                    </div>

                    <div className={`${style.imgLeftSide} ${style['bg-blue']}`}>
                      <div className={style.imgContainer}>
                        <img style={{ height: 420 }} className="img_bottom" src={imgThree} alt="" />
                      </div>
                    </div>

                    <div className={`${style.imgLeftSide} ${style['bg-green']}`}>
                      <div className={style.imgContainer}>
                        <img
                          // style={{ height: 170, marginBottom: 25 }}
                          style={{ height: 420 }}
                          className="img_bottom"
                          src={imgFour}
                          alt=""
                        />
                      </div>
                    </div>

                    <div className={`${style.imgLeftSide} ${style['bg-dark']}`}>
                      <div className={style.imgContainer}>
                        <img
                          // style={{ height: 170, marginBottom: 25 }}
                          style={{ height: 420 }}
                          className="img_bottom"
                          src={imgFive}
                          alt=""
                        />
                      </div>
                    </div>
                  </Carousel>
                </div>
                <div className={style.rightContainer}>
                  <div className={style.containerInner}>{children}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
