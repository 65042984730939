import api from 'api/api-connection'

const getNotifications = () =>
  api
    .get('/notifications/getNotificationsToTable/get')
    .then(response => response.data.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const getNotificationById = id =>
  api
    .get(`/notifications/${id}`)
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const createNotification = notification =>
  api
    .post(`/notifications`, notification)
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const updateNotification = notification =>
  api
    .patch(`/notifications`, notification)
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

const deleteNotification = id =>
  api
    .delete(`/notifications/${id}`)
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })

export default {
  getNotificationById,
  getNotifications,
  createNotification,
  deleteNotification,
  updateNotification,
}
