import InfoColabOT from 'fichap-pages/reports/ots-reports/Colabs/InfoColab/InfoColabOT'

export const paths = {
  absences_reports: '/absences-reports',
  absences: '/absences',
  accidents_reports: '/accidents-reports',
  user_add: '/user/add',
  user_edit_id: '/user/edit/:id',
  user: '/user',
  auth_404: '/auth/404',
  auth_500: '/auth/500',
  auth_change_password_userId_resetToken: '/auth/change-password/:userId/:resetToken',
  auth_forgot_password: '/auth/forgot-password',
  auth_forgot_username: '/auth/forgot-username',
  auth_lockscreen: '/auth/lockscreen',
  auth_login: '/auth/login',
  auth_register: '/auth/register',
  companies_company_view: '/companies/company-view',
  companies: '/companies',
  company_edit: '/company/edit',
  company: '/company',
  dashboard: '/dashboard',
  demographic_reports: '/demographic-reports',
  error_reports: '/error-reports',
  help: '/help',
  inventory: '/inventory',
  levels: '/levels',
  nomine: '/nomine',
  notifications: '/notifications',
  payments_bill_id: '/payments/bill/:id',
  payments: '/payments',
  profile_profileId: '/team/profile/:profileId',
  casualty_profileId: '/casualty/profile/:profileId',
  profile: '/profile',
  reasons: '/reasons',
  reports: '/reports',
  news: '/news',
  reports_error: '',
  requests: '/requests',
  settings: '/settings',
  shifts_reports: '/shifts-reports',
  specialdays: '/specialdays',
  subscription: '/subscription',
  team_casualtylist: '/team/casualtylist',
  team_daily_record: '/team/daily-record/:id',
  team_data_import: '/team/data-import',
  team_employee_record: '/team/employee-record',
  team_error_face_ID: '/team/marks-review',
  ReviewAndAapprovals: '/reviews-approvals',
  team_heat_map: '/team/heat-map',
  team_organization_chart: '/team/organization-chart',
  team_pictures_import: '/team/pictures-import',
  team_shifts: '/team/shifts',
  team_team: '/team/team',
  team_timeline: '/team/timeline',
  team: '/team',
  redirect: '/team/redirect',
  salary: '/company/salary',
  billboard: '/communication/billboard/',
  newPost: '/communication/billboard/new',
  editPost: '/communication/billboard/edit/:id',
  addFlyer: '/comunication/billboard/addFlyer',
  editFlyer: '/comunication/billboard/flyer/edit/:id',
  Flyer: '/communication/billboard/Flyer',
  Resources: '/communication/billboard/Resources',
  AddResource: '/communication/billboard/AddResource',
  EditResource: '/comunication/billboard/resource/edit/:id',
  salaryId: '/company/salary/:id',
  salaryBulkLoad: '/company/salary-bulk-load',
  categories: '/communication/categories',
  MyCalendar: '/calendar',
  GoogleCalendarConnection: '/calendar/connection',
  addHoliday: '/absences/vacation/add',
  employeeRecords: '/employee-records',
  ReportsGenerate: '/integrations',
  ViewReport: '/integrations-transactions/:id',
  otsReport: '/ots-reports', // firstVIEW
  otsReportID: '/otsReport/:id', // agregar :id
  otsColabReport: '/otsReport/colab', // agregar :ids
  colabsInfoOT: '/otsReport/InfoColabOT',
  colabDataOT: '/infoColaboratorOT/:id', // nuevo para OTS
  workingInOT: '/workingInOT/:orderId/u/:userId',
  SportLine: '/auth/login/4people',
}

export const getAppPath = key => (paths[key] ? paths[key] : paths.auth_500)

export const useAppPath = (key, paramsArr = []) => {
  let path = getAppPath(key).split(':')[0]
  paramsArr.forEach(param => {
    path += `${param}/`
  })
  return path
}
