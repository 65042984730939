import { RESTART_STATE } from 'redux-fichap/user/types'
import { parseActionType } from 'utils'

import { GET_DISMISSED_TABLE } from './types'

const INITIAL_STATE = {
  loading: {},
  error: null,
  dismissedtable: [],
}

export default (state = INITIAL_STATE, action) => {
  const actionType = parseActionType(action.type)
  switch (action.type) {
    case GET_DISMISSED_TABLE.REQUEST:
      return { ...state, loading: { [actionType]: true } }

    case GET_DISMISSED_TABLE.SUCCESS:
      return { ...state, loading: { [actionType]: false }, dismissedtable: action.payload }

    case GET_DISMISSED_TABLE.FAILURE:
      return { ...state, loading: { [actionType]: false }, error: action.payload || true }
    case RESTART_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
