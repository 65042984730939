import api from 'services-fichap/workshift/index'
import { takeEvery, put, call } from 'redux-saga/effects'
import NotificationGenerator from 'base/NotificationGenerator'
import { GET_WORKSHIFTS, DELETE_WORKSHIFT, CREATE_WORKSHIFT, UPDATE_WORKSHIFT } from './types'
import { intl } from '../../locales/IntlGlobalProvider'

function* getWorkshiftsApiCall(action) {
  try {
    const response = yield call(api.getWorkshift, action.payload)

    yield put({ type: GET_WORKSHIFTS.SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_WORKSHIFTS.FAILURE, payload: error })
  }
}

function* updateWorkshiftApiCall(action) {
  try {
    const response = yield call(api.updateWorkshift, action.payload)
    yield put({ type: UPDATE_WORKSHIFT.SUCCESS, payload: response })
    NotificationGenerator('TemplateUpdated', intl, false)
  } catch (error) {
    yield put({ type: UPDATE_WORKSHIFT.FAILURE, payload: error })
    NotificationGenerator('AnErrorHasOcurred', intl, true)
  }
}

function* createWorkshiftApiCall(action) {
  try {
    const response = yield call(api.createWorkshift, action.payload)
    yield put({ type: CREATE_WORKSHIFT.SUCCESS, payload: response })
    NotificationGenerator('TemplateCreated', intl, false)
  } catch (error) {
    yield put({ type: CREATE_WORKSHIFT.FAILURE, payload: error })
    NotificationGenerator('AnErrorHasOcurred', intl, true)
  }
}

function* deleteWorkshiftApiCall(action) {
  try {
    const response = yield call(api.deleteWorkshift, action.payload)
    yield put({ type: DELETE_WORKSHIFT.SUCCESS, payload: response })
    NotificationGenerator('TemplateDeleted', intl, false)
  } catch (error) {
    yield put({ type: DELETE_WORKSHIFT.FAILURE, payload: error })
    NotificationGenerator('AnErrorHasOcurred', intl, true)
  }
}

function* IndexSaga() {
  yield takeEvery(GET_WORKSHIFTS.REQUEST, getWorkshiftsApiCall)
  yield takeEvery(UPDATE_WORKSHIFT.REQUEST, updateWorkshiftApiCall)
  yield takeEvery(CREATE_WORKSHIFT.REQUEST, createWorkshiftApiCall)
  yield takeEvery(DELETE_WORKSHIFT.REQUEST, deleteWorkshiftApiCall)
}

export default IndexSaga
