import { Menu } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import style from '../styles/MenuLeft/style.module.scss'

export const generateMenuItems = ({ isMenuCollapsed, menuData, role }) => {
  const generateItem = (item, props) => {
    if (!item?.key) return null
    const { key, title, url, icon, disabled, count } = item
    if (item.category) {
      return (
        <Menu.ItemGroup
          {...props}
          key={Math.random()}
          title={<FormattedMessage id={title || ' '} />}
        />
      )
    }
    if (item.url) {
      return (
        <Menu.Item {...props} key={key} disabled={disabled}>
          {/* {item.external && } */}
          {item.target && (
            <a style={{ marginLeft: 25 }} href={url} target={item.target} rel="noopener noreferrer">
              {icon && (
                <img
                  style={item.iconStyles}
                  className={`${style.icon} ${style.iconcollapsed_hover} icon-collapsed-hidden mr-2`}
                  src={icon}
                  alt="icon"
                />
              )}
              <span className={style.title}>
                <FormattedMessage id={title || ' '} />
              </span>
              {count && <span className="badge badge-success ">{count}</span>}
            </a>
          )}
          {!item.target && (
            <Link to={url} style={{ marginLeft: 24 }}>
              {icon && (
                <img
                  style={item.iconStyles}
                  className={`${style.icon} ${style.iconcollapsed_hover} icon-collapsed-hidden mr-2`}
                  src={icon}
                  alt="icon"
                />
              )}
              <span className={style.title}>
                <FormattedMessage id={title || ' '} />
              </span>
              {count && <span className="badge badge-success ">{count}</span>}
            </Link>
          )}
        </Menu.Item>
      )
    }

    return (
      <Menu.Item {...props} key={key} disabled={disabled}>
        <span className={style.title}>
          <FormattedMessage id={title || ' '} />
        </span>
        {count && <span className="badge badge-success ml-2">{count}</span>}
        {icon && <img className={`${style.icon} icon-collapsed-hidden`} src={icon} alt="icon" />}
      </Menu.Item>
    )
  }

  const generateSubmenu = items =>
    items.map(menuItem => {
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={style.title}>
              <FormattedMessage id={menuItem.title || ' '} />
            </span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            {menuItem.icon && <img className={`${style.icon}`} src={menuItem.icon} alt="icon" />}
          </span>
        )
        if (isMenuCollapsed && menuItem.notCollapsable) {
          const mappedItems = []
          mappedItems.push(generateItem(menuItem, { className: menuItem.className }))
          menuItem.children.forEach(el => {
            mappedItems.push(generateItem(el, { className: menuItem.className }))
          })
          return mappedItems
        }
        let props = {
          className: `${menuItem.className} collapsedMenu`,
          title: subMenuTitle,
          key: menuItem.key,
        }
        if (menuItem.collapsed) props.collapsed = true
        return <Menu.SubMenu {...props}>{generateSubmenu(menuItem.children)}</Menu.SubMenu>
      }
      return generateItem(menuItem)
    })

  return menuData.map(menuItem => {
    if (menuItem.roles && !menuItem.roles.includes(role)) {
      return null
    }
    if (menuItem.children) {
      const subMenuTitle = (
        <>
          <span key={menuItem.key}>
            <span style={{ marginLeft: 24 }} className={`${style.title} title`}>
              <FormattedMessage id={menuItem.title || ' '} />
            </span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
          </span>
          {menuItem.icon && (
            <img
              className={style.iconcollapsed_hover}
              style={{ opacity: '.4', left: '1rem', position: ' absolute', top: '9px' }}
              src={menuItem.icon}
              alt="icon"
            />
          )}
        </>
      )

      return (
        <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
          {generateSubmenu(menuItem.children)}
        </Menu.SubMenu>
      )
    }
    return generateItem(menuItem)
  })
}
