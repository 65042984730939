import { takeEvery, put, call } from 'redux-saga/effects'
import api from 'services-fichap/dismissedTable/index'
import { GET_DISMISSED_TABLE } from './types'

// covid
function* getDismissedApiCall(action) {
  try {
    const response = yield call(api.getDismissedTable, action.payload)
    yield put({ type: GET_DISMISSED_TABLE.SUCCESS, payload: response })
  } catch (err) {
    console.error(err)
    yield put({ type: GET_DISMISSED_TABLE.FAILURE, payload: err })
  }
}
// function* updateWorkdaysByIdApiCall(action) {
//   try {
//     yield call(updateConfigData, '/', action.payload)
//     yield put({ type: UPDATE_WORKDAY_BY_ID.SUCCESS })
//   } catch (error) {
//     console.error(err)
//     yield put({ type: UPDATE_WORKDAY_BY_ID.SUCCESS, payload: err })
//   }
// }

// function* deleteWorkdayByIdApiCall(action) {
//   try {
//     yield call(deleteWorkdayById, action.payload)
//     yield put({ type: DELETE_WORKDAY_BY_ID.SUCCESS })
//   } catch (error) {
//     console.error(err)
//     yield put({ type: DELETE_WORKDAY_BY_ID.FAILURE, payload: err })
//   }
// }

function* dismissedIndexSaga() {
  // GETS
  yield takeEvery(GET_DISMISSED_TABLE.REQUEST, getDismissedApiCall)
}

export default dismissedIndexSaga
