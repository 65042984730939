/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import apiConnection from 'api/api-connection'
import React from 'react'

const LapzoButton = () => {
  const moveToLapzo = () => {
    apiConnection
      .get('/auth/token/LAPZO/generate')
      .then(({ data }) => {
        window.open(data, '_blank').focus()
      })
      .catch(console.error)
  }
  return (
    <a
      style={{
        display: 'flex',
      }}
      className="LapzoButton"
      onClick={moveToLapzo}
    >
      <img style={{ marginRight: 9 }} src="svg/star.svg" alt="" />
      <span style={{ color: '#007aff' }}> Performance </span>
    </a>
  )
}

export default LapzoButton
